import React from 'react';
import '@total-typescript/ts-reset';
export type Nullable<T> = T | null;
type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;
export type PartialBy<T, K extends keyof T> = DistributiveOmit<T, K> & Partial<Pick<T, K>>;
export type NullableBy<T, K extends keyof T> = Omit<T, K> & { [k in K]: T[k] | null };
type RequiredPick<T, K extends keyof T> = Required<Pick<T, K>>;
export const typedMemo: <T>(c: T) => T = React.memo;
export const typedForwardRef: <T, P = unknown>(render: (props: P, ref: React.Ref<T>) => React.ReactElement | null) => (props: P & React.RefAttributes<T>) => React.ReactElement | null = React.forwardRef;
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};
