import { styled } from '@mui/material';
import { SearchInput } from '@tymely/components';
import React from 'react';

import { useTemplateEditor } from './TemplateEditorProvider';

const _SearchInput = styled(SearchInput, { shouldForwardProp: (prop: string) => !['hasTicket'].includes(prop) })<{
    hasTicket: boolean;
}>`
    flex: 1;
    ${({ hasTicket, theme }) => hasTicket && `flex: 4; margin-right: ${theme.spacing(1)};`};
`;

const SEARCH_INPUT_PROPS = {
    outlined: true,
    fullWidth: true,
    placeholder: 'Search Ticket…',
    sx: { '& input:focus': { minWidth: 90 } },
};

const TicketSearch = () => {
    const { ticketId, setTicketId } = useTemplateEditor();
    const onTicketSearch = (ticketId: string) => setTicketId(Number(ticketId));

    return (
        <_SearchInput
            hasTicket={!!ticketId}
            input={SEARCH_INPUT_PROPS}
            clearOnSubmit={false}
            onSubmit={onTicketSearch}
        />
    );
};

export default TicketSearch;
