import { atom } from 'recoil';

import { IWfPolicy } from './policy.atoms';
import { localStorageEffect } from './utils';

interface IIngestionConfig {
    collapse_last_arg_updated?: boolean;
}

interface IConsumptionChannelConfig {
    name: string;
    queue_name: string;
    queue_url: string;
    sns_topic: string;
}

export interface IConsumptionChannel extends IConsumptionChannelConfig {
    uiId: string;
    name: string;
    organization: IOrganization;
}

export interface IOrganizationConfig {
    demo: boolean;
    chat?: {
        enabled: boolean;
    };
    ingestion?: IIngestionConfig;
    allow_merging_tickets: boolean;
    consumption?: {
        channels: IConsumptionChannelConfig[];
    };
}

export interface IOrganization {
    id: number;
    name: string;
    org_policy_set_id: number;
    disabled: boolean;
    is_live_client: boolean;
    ty_policy: Record<string, Omit<IWfPolicy, 'intentId' | 'orgId'>>;
    config: IOrganizationConfig;
}

export interface IOrganizationCreate {
    name: string;
    is_live_client: boolean;
}

export interface IOrganizationUpdate {
    id: number;
    config?: IOrganizationConfig;
}

export const selectedChannelsAtom = atom<IConsumptionChannel[]>({
    key: 'selectedChannels',
    default: [],
    effects: [localStorageEffect('selected-channels')],
});
