import { ITicketTrailCrumb, ITicketTrailEvent } from '@tymely/atoms';
import axios from 'axios';

export const postTicketCrumb = (crumb: ITicketTrailCrumb) =>
    axios
        .post<ITicketTrailCrumb>('ticket-trail', {
            ...crumb,
            triggered_by: 'USER',
        })
        .then((resp) => resp.data);

export const getTicketEvents = (ticketId: number, skip?: number) =>
    axios.get<ITicketTrailEvent[]>(`/ticket/${ticketId}/ticket-trail`, { params: { skip } }).then((res) => res.data);
