import { useEventSubscription } from '@tymely/services';

import useGetTemplateChannel from './useGetTemplateChannel';

const OBJECT_TYPES = ['TemplateUpdate'];

type TemplateUpdateEventInfo = { date: string; updated_by: string };

export type TemplateUpdateInfo = { date: Date; updatedBy: string };

const useTemplateUpdateListener = (callback: (info: TemplateUpdateInfo) => void) => {
    const channel = useGetTemplateChannel();
    useEventSubscription<TemplateUpdateEventInfo>({ channel, objectTypes: OBJECT_TYPES }, ({ data }) => {
        callback({ date: new Date(data.date), updatedBy: data.updated_by });
    });
};

export default useTemplateUpdateListener;
