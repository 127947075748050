import React from 'react';
import { Box, styled } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import useDnDEvents from './useDnDEvents';

const _Root = styled(Box, { shouldForwardProp: (prop: string) => !['isDragging'].includes(prop) })<{
    isDragging: boolean;
}>`
    display: flex;
    align-items: center;
    height: 100%;
    cursor: grab;
    overflow: hidden;
    margin-right: ${({ theme }) => theme.spacing(1)};
    background-color: ${({ isDragging }) => (isDragging ? 'inherit' : 'transparent')};
`;

const _DragIndicatorIcon = styled(DragIndicatorIcon)`
    width: ${({ theme }) => theme.spacing(3)};
`;

const _Title = styled(Box)`
    position: absolute;
    display: none;
    top: -10000;
    padding: ${({ theme }) => theme.spacing(0, 1)};
    background-color: white;
    z-index: 1000;
`;

const DraggableCell = ({
    title,
    disabled,
    idx,
    type,
}: {
    title?: React.ReactNode;
    disabled?: boolean;
    idx: number;
    type: string;
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { isDragging, onDragStart, onDragEnd } = useDnDEvents({ type, dragInd: idx });

    const _onDragStart = React.useCallback<React.DragEventHandler<HTMLDivElement>>(
        (e) => {
            onDragStart(e);
            if (ref.current) {
                ref.current.style.display = 'block';
                e.dataTransfer.setDragImage(ref.current, 0, 0);
            }
        },
        [onDragStart],
    );

    return (
        <_Root isDragging={isDragging} draggable={!disabled} onDragStart={_onDragStart} onDragEnd={onDragEnd}>
            <_DragIndicatorIcon color={disabled ? 'disabled' : 'action'} />
            <_Title ref={ref}>{title}</_Title>
        </_Root>
    );
};

export default DraggableCell;
