import { FormControl, InputLabel, styled } from '@mui/material';
import React from 'react';
import { MultiSelect } from '@tymely/components';

import { useTemplateEditor } from './TemplateEditorProvider';

const _FormControl = styled(FormControl)`
    flex: 6;
`;

const CommentSelect = () => {
    const { comment, ticketOfflineQuery, argumentsQuery, setComment } = useTemplateEditor();

    const commentOptions = React.useMemo(() => {
        if (ticketOfflineQuery.isLoading || argumentsQuery.isLoading) {
            return [];
        }
        return (
            ticketOfflineQuery.data?.comments
                .filter((comm) => comm.is_customer)
                .sort(
                    ({ inquiry_date: id1 }, { inquiry_date: id2 }) => new Date(id2).valueOf() - new Date(id1).valueOf(),
                )
                .map((comm) => {
                    const group = comm.selected_intent_id ? 'Tagged' : 'Untagged';
                    return {
                        name: comm.body ? comm.body.substring(0, 80) + '...' : comm.id.toString() + group,
                        value: comm.id,
                        group: { id: group, name: group },
                    };
                }) || []
        );
    }, [ticketOfflineQuery.data, ticketOfflineQuery.isLoading, argumentsQuery.isLoading]);

    React.useEffect(() => {
        const selectedOpt = commentOptions.find((opt) => opt.group.id === 'Tagged');
        if (selectedOpt) {
            setComment([selectedOpt]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commentOptions]);

    return (
        <_FormControl size="small">
            <InputLabel shrink>Comment</InputLabel>
            <MultiSelect
                loading={ticketOfflineQuery.isLoading || argumentsQuery.isLoading}
                multiline={false}
                value={comment ?? []}
                label="Comment"
                options={commentOptions}
                grouped
                onOptsSelect={setComment}
            />
        </_FormControl>
    );
};

export default CommentSelect;
