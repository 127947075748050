export const DOCUMENT_TITLE = 'Tymely';

export const ALERT_DISMISS_TIMEOUT_MILLIS = 5000;

export const CONDITION_EDITOR_DEBOUNCE_INTERVAL_MS = 400;

export const INTENTS_DEFAULT_CATEGORY = 'Invalid';

export const INTENTS_TOP_INFERRED_CATEGORY = 'Proposed';

export const INTENTS_CUSTOM_CATEGORY = 'Custom';

export const INTENTS_TOP_CATEGORIES = [INTENTS_TOP_INFERRED_CATEGORY];

export const INTENTS_BOTTOM_CATEGORIES = [INTENTS_DEFAULT_CATEGORY, INTENTS_CUSTOM_CATEGORY];

export const INTENTS_DEPRECATED_CATEGORIES = ['Deprecated'];

export const MULTIPLE_LOGIC_BOX_ID = 215;

export const NEITHER_LOGIC_BOX_ID = 216;

export const IDLE_TIMER_TIMEOUT = 5000;

export const NOTES_PREVIEW_MAX_LENGTH = 40;

export const ANALYST_QUEUE = 87;

export const DEMO_ORGANIZATION = 9;

export const TICKET_HANDLING_TIMEOUT_MINUTES = 1;

export const CHAT_ESCALATION_ORG_ID = 12;

export const NEW_COMMENTS_ALERT_TIMEOUT = 5000;

export const GOOGLE_MAPS_API_KEY_ENTRY = 'GOOGLE_MAPS_API_KEY';
