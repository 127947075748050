import { IArgument, IArgumentMetadata } from './argument.atoms';
import { IComment } from './comment.atoms';
import { IOrganization } from './organization.atoms';
import { ITicket } from './ticket.atoms';

export enum LlmExperimentType {
    PROMPT_ONLINE = 'PROMPT_ONLINE',
    PROMPT_OFFLINE = 'PROMPT_OFFLINE',
    ARGUMENT_OFFLINE = 'ARGUMENT_OFFLINE',
}

export interface LlmModelMetadata {
    id: number;
    title: string;
    llm_model_id: string;
    executor_name: string;
}

export enum LlmModelType {
    OPENAI = 'OPENAI',
    OPENAI_4O_MINI = 'OPENAI_4O_MINI',
    SONNET = 'SONNET',
    HAIKU = 'HAIKU',
    LLAMA_3_8B = 'LLAMA_3_8B',
    LLAMA_3_70B = 'LLAMA_3_70B',
}

export interface ILlmExperiment {
    id: number;
    total_count: number;
    processed_count: number;
    report_link?: string;
    report_analysis_link?: string;
    experiment_type: LlmExperimentType;
    request_override: Record<string, unknown>;
    additional_data: Record<string, unknown> & {
        error?: string;
    };
    created_date: string;
}

export interface ILlmExperimentCreate {
    experiment_type: LlmExperimentType;
    total_count: number;
    request_override: Record<string, unknown> & {
        llm_model_metadata_id?: number;
    };
    additional_data: Record<string, unknown> & {
        experiment_obj_id: IArgumentMetadata['id'];
        optimization_target_name: IArgumentMetadata['name'];
    };
}

export interface ILlmExperimentUpdate {
    id: number;
    additional_data: {
        error: string;
    };
}

export enum LlmExperimentDataSetType {
    TICKET = 'TICKET',
    COMMENT = 'COMMENT',
}

export interface ILlmExperimentRunParams {
    dataset?: {
        type: LlmExperimentDataSetType;
        ids: number[];
        name: string;
    };
}

export interface ILlmResponse {
    raw_response: string;
    parsed_response: any;
    model: LlmModelType;
    version: string;
    log_context?: any;
    errors?: string[];
}

export interface ILlmTrail {
    id: number;
    prompt: string;
    response: ILlmResponse;
    duration?: number;
    experiment_id: ILlmExperiment['id'];
    additional_data: Record<string, unknown> & {
        ticket_id?: ITicket['id'];
        comment_id?: IComment['id'];
        comment_original_id_from_client: string | null;
        org_id: IOrganization['id'] | null;
        arg_md_ids?: IArgumentMetadata['id'][];
    };

    human_score?: number;
    machine_score?: number;
}

export interface ILlmReportRecord {
    raw_prompt: string;
    raw_response: string;
    experiment_arg_md_id: IArgumentMetadata['id'];
    optimize_target_arg_md_id: IArgumentMetadata['id'];
    arg_id: IArgument['id'];
    arg_name: IArgumentMetadata['name'];
    ticket_id: ITicket['id'];
    comment_id: IComment['id'];
    comment?: IComment;
    predicted_correctly: boolean;
    llm_trail_id: ILlmTrail['id'];
    predicted_value: string;
    arg_value: string;
    predicted_confidence: number;
    error?: string;
}

export interface ILlmSummaryReport {
    overall_precision: number;
    overall_recall: number;
}
