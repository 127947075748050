import { Box, CircularProgress, Backdrop, styled, Typography, alpha, useTheme } from '@mui/material';
import React from 'react';

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
}));

export function Loadable<T>(props: {
    waitFor: T;
    whileWaiting?: React.ReactNode;
    reloading?: boolean;
    status?: string;
    children: React.ReactNode;
}) {
    const theme = useTheme();
    return (
        <Box position="relative">
            <StyledBackdrop open={Boolean(props.reloading && props.waitFor)}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    sx={{ position: 'sticky', top: 0, bottom: 0 }}
                >
                    <CircularProgress />
                    <Typography
                        variant="body2"
                        sx={{ mt: 1, p: 1, backgroundColor: alpha(theme.palette.common.white, 0.6) }}
                    >
                        {props.status}
                    </Typography>
                </Box>
            </StyledBackdrop>
            {!props.waitFor
                ? props.whileWaiting || (
                      <Box p={2}>
                          <CircularProgress />
                      </Box>
                  )
                : props.children}
        </Box>
    );
}
