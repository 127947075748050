import { Box, Button, styled, Typography } from '@mui/material';
import { useUser } from '@tymely/services';

import useArgumentsQuery from './useArgumentsQuery';

const _Root = styled(Box)`
    padding: ${({ theme }) => theme.spacing(2, 2, 0)};
    display: flex;
`;

const _MainContainer = styled(Box)`
    flex: 1;
`;

const _Label = styled(Typography)`
    font-weight: 500;
    padding: 0;
    margin-right: ${({ theme }) => theme.spacing(1)};
    display: inline;
`;

type ArgumentsActionsProps = {
    version?: string;
    onDependencyGraph: () => void;
    onReset: () => void;
};

const ArgumentsActions = ({ version, onDependencyGraph, onReset }: ArgumentsActionsProps) => {
    const user = useUser();
    const argsQuery = useArgumentsQuery({ version });
    return (
        <_Root>
            <_MainContainer>
                <_Label variant="h5">Arguments</_Label>
                {user?.isAdmin && (
                    <Button disabled={!argsQuery.data} onClick={onDependencyGraph}>
                        Dependency graph
                    </Button>
                )}
            </_MainContainer>
            <Button onClick={onReset} color="secondary" variant="outlined">
                Reset Text Arguments
            </Button>
        </_Root>
    );
};

export default ArgumentsActions;
