import { BrowserRouter } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import CssBaseline from '@mui/material/CssBaseline';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { newTheme, version } from '@tymely/config';
import { isMonitoringEnabled, useFeatureFlags } from '@tymely/services';
import { useEffect } from 'react';

import { AppLayout } from './AppLayout';
import { AuthContext } from './AuthContext';

import '@aws-amplify/ui-react/styles.css';
import './style.css';

if (isMonitoringEnabled()) {
    const env = import.meta.env.VITE_DEPLOY_ENV;
    const backendUrl = import.meta.env.VITE_BACKEND_URL;
    const sessionSampleRate = 100;
    const site = 'datadoghq.eu';
    const service = 'dinesh';

    datadogRum.init({
        applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
        clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'allow',
        sessionReplaySampleRate: sessionSampleRate,
        allowedTracingUrls: [backendUrl],
        site,
        service,
        env,
        version,
        sessionSampleRate,
    });
    datadogRum.startSessionReplayRecording();

    datadogLogs.init({
        clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
        forwardErrorsToLogs: true,
        beforeSend: (event) => {
            // Skipping "Network Errors" and Axios errors

            if (event.http && event.http.status_code === 0) {
                return false;
            }
            if (event.message.includes('AxiosError')) {
                return false;
            }
            return;
        },
        site,
        sessionSampleRate,
        service,
        env,
        version,
    });
}

window.addEventListener('vite:preloadError', (event) => {
    datadogLogs.logger.error('Vite preload error', event);
    window.location.reload();
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const FeaturesLoader = () => {
    const { loadFeatureFlags: loadFeatures } = useFeatureFlags();
    useEffect(loadFeatures, []);
    return null;
};

export const App = withAuthenticator(
    () => (
        <RecoilRoot>
            <FeaturesLoader />
            <QueryClientProvider client={queryClient}>
                <AuthContext>
                    <CssVarsProvider theme={newTheme}>
                        <CssBaseline />
                        <BrowserRouter>
                            <AppLayout />
                        </BrowserRouter>
                    </CssVarsProvider>
                </AuthContext>
            </QueryClientProvider>
        </RecoilRoot>
    ),
    { hideSignUp: true },
);
