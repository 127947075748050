export const containsHebrew = (text: string): boolean => /[\u0590-\u05FF]/.test(text);

export const switchHebrewToEnglish = (text: string): string =>
    Array.from(text)
        .map((a) => quertyMapping[a] || a)
        .join('');

// prettier-ignore
export const quertyMapping: Record<string, string> = {
    'ש': 'a', 'נ': 'b', 'ב': 'c',
    'ג': 'd', 'ק': 'e', 'כ': 'f',
    'ע': 'g', 'י': 'h', 'ן': 'i',
    'ח': 'j', 'ל': 'k', 'ך': 'l',
    'צ': 'm', 'מ': 'n', 'ם': 'o',
    'פ': 'p', '/': 'q', 'ר': 'r',
    'ד': 's', 'א': 't', 'ו': 'u',
    'ה': 'v', '׳': 'w', 'ס': 'x',
    'ט': 'y', 'ז': 'z', ',': '\''
}
