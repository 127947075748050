const ExitIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5445 25.5951V10.006C14.5445 9.10123 14.079 8.26074 13.3119 7.78214L8.06703 4.50408C6.32048 3.41315 4.05469 4.66799 4.05469 6.72792V22.3157C4.05469 23.2205 4.52017 24.061 5.28724 24.5396L10.5321 27.8176C12.2787 28.9099 14.5445 27.6537 14.5445 25.5951Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.7891 14.5931H27.6564"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.0312 17.2136L27.6537 14.5912L25.0312 11.9688"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.5469 25.0821H19.7918C21.2407 25.0821 22.4142 23.9086 22.4142 22.4597V21.1484"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.4144 8.03523V6.72401C22.4144 5.27511 21.2408 4.10156 19.7919 4.10156H6.67969"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ExitIcon;
