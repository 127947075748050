import sanitize, { defaults } from 'sanitize-html';

export const sanitizeHtml = (val: string) =>
    sanitize(val, {
        ...defaults,
        allowedTags: [...defaults.allowedTags, 'label', 'img'],
        allowedAttributes: {
            ...defaults.allowedAttributes,
            a: ['href'],
            img: ['src', 'width', 'height', 'style'],
            '*': ['style'],
            col: ['width'],
        },
        selfClosing: ['figure'],
    })
        // Avoid decoding HTML entities in Jinja tags
        .replace(/{[%#{].*?(&gt;|&lt;|&amp;|&quot;).*?}/gs, (jinjaTagWithHtmlEntity) =>
            jinjaTagWithHtmlEntity.replace(/&gt;|&lt;|&amp;|&quot;/g, (entity) =>
                entity === '&gt;'
                    ? '>'
                    : entity === '&lt;'
                      ? '<'
                      : entity === '&amp;'
                        ? '&'
                        : entity === '&quot;'
                          ? '"'
                          : '',
            ),
        );

export const pluralize = (str: string, amount: number) => `${str}${amount !== 1 ? 's' : ''}`;

const escapedSlashMarker = `{SLASH${Math.random().toString(36)}}`;
const escapedCommaMarker = `{COMMA${Math.random().toString(36)}}`;

// Splits by unescaped-commas, and unescapes each parts backslashes.
// To escape commas, use backslash-comma.
// To escape backslashes, use backslash-backslash.
// i.e. A user input of:
// Hello\\,World\,Whats Up,Bla Bla
// will be split to:
// 1. Hello\
// 2. World,Whats Up
// 3. Bla Bla
export const unescapeCommaSplit = (str: string): string[] =>
    str
        .replaceAll('\\\\', escapedSlashMarker)
        .replaceAll('\\,', escapedCommaMarker)
        .split(',')
        .map((part) => part.replaceAll(escapedSlashMarker, '\\').replaceAll(escapedCommaMarker, ','));
