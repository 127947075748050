import { featureFlags as featureFlagsAtom } from '@tymely/atoms';
import { useRecoilState } from 'recoil';
import _FEATURES from 'features.json';

const FEATURES = _FEATURES as { [key: string]: string };

export const useFeatureFlags = () => {
    const [flags, setFlags] = useRecoilState(featureFlagsAtom);

    const getFeatureFlagValue = (key: string) => {
        if (!(key in FEATURES)) {
            // eslint-disable-next-line no-console
            console.warn(`Trying to read non-existent feature: ${key}`);
        }
        return flags[key] || false;
    };

    const toggleFeatureFlag = (feature: string, value?: boolean) => {
        if (!(feature in FEATURES)) {
            // eslint-disable-next-line no-console
            console.warn(`Trying to toggle non-existent feature: ${feature}`);
        }

        setFlags((flags) => {
            const flagsUpdated: { [key: string]: boolean } = {};

            for (const key in FEATURES) {
                if (key === feature) {
                    if (value === undefined) {
                        value = feature in flags ? !flags[feature] : true;
                    }
                    flagsUpdated[key] = value;
                } else {
                    const value = flags[key];
                    if (value) {
                        flagsUpdated[key] = value;
                    }
                }
            }
            return flagsUpdated;
        });
    };

    const printFeatures = (features: { [key: string]: boolean }) => {
        const content: string[] = [],
            format: string[] = [];
        content.push('%cFEATURE FLAGS');
        format.push('font-weight:bold');
        for (const feature in FEATURES) {
            const value = features[feature];
            content.push(`%c${value ? '✔️' : '✘'} ${feature} %c${FEATURES[feature]}`);
            if (value) {
                format.push('color:#65E2AE;font-weight:bold', 'color:#65E2AE');
            } else {
                format.push('color:#AAA;font-weight:bold', 'color: #AAA');
            }
        }
        // eslint-disable-next-line no-console
        console.log(content.join('\n'), ...format);
    };

    const loadFeatureFlags = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const featuresUpdated: { [key: string]: boolean } = {};
        for (const feature in FEATURES) {
            const valueRaw = queryParams.get(feature);
            const value =
                valueRaw !== null ? !['0', 'false'].includes(valueRaw.toLowerCase()) : getFeatureFlagValue(feature);
            if (value) {
                featuresUpdated[feature] = value;
            }
        }
        setFlags(featuresUpdated);
        printFeatures(featuresUpdated);
    };

    return {
        features: FEATURES,
        enabledFeatureFlags: Object.keys(flags).filter((key) => flags[key]),
        getFeatureFlagValue,
        toggleFeatureFlag,
        loadFeatureFlags,
    };
};
