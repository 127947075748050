import get from 'lodash/get';
import set from 'lodash/set';
import escapeRegExp from 'lodash/escapeRegExp';
import React from 'react';
import DataGridBase, {
    RenderRowProps,
    DataGridProps as _DataGridProps,
    SelectColumn,
    RenderCellProps,
    DataGridHandle,
    RenderCheckboxProps,
    useRowSelection,
} from 'react-data-grid';
import { Box, useTheme, Skeleton, SxProps, Theme, Typography } from '@mui/material';
import { sanitizeHtmlElements } from '@tymely/utils';
import { typedForwardRef } from '@global/types';

import { Column } from './types';
import DataGridRow from './DataGridRow';
import DraggableCell from './DraggableCell';
import HeaderRenderer from './GridDataHeader';
import { CheckboxRenderer } from './styled';
import DataGridContainer from './DataGridContainer';

import 'react-data-grid/lib/styles.css';
import 'react-data-grid/lib/styles.css';

const ROW_HEIGHT = 56;
const HEADER_ROW_HEIGHT = 64;
const MAX_ROW_HEIGHT_VALUE = 300;

const toStr = (propValue: unknown): string => {
    if (propValue === null || propValue === undefined) return '';

    if (Array.isArray(propValue)) {
        return propValue
            .map((item) => (typeof item === 'object' ? Object.values(item || {}) : item))
            .flat()
            .map((item) => `- ${item}`)
            .join('\n');
    }
    if (typeof propValue === 'object') {
        return toStr(Object.values(propValue));
    }
    return String(propValue);
};

const parseRegExp = (value: string) => {
    try {
        return new RegExp(value, 'i');
    } catch (_) {
        return new RegExp(escapeRegExp(value), 'i');
    }
};

export type DataGridProps<T extends { uiId: string }, K extends React.Key = React.Key> = Omit<
    _DataGridProps<T, unknown, K>,
    'rows' | 'rowHeight' | 'columns'
> & {
    data: T[];
    columns: Column<T>[];
    loading?: boolean;
    disabled?: boolean;
    multiselect?: boolean;
    withRowSelect?: boolean;
    withRowReorder?: boolean;
    sx?: SxProps<Theme>;
    rowClass?: (row: T) => string;
    onRowReorder?: (sourceIndex: number, targetIndex: number) => void;
    onColReorder?: (sourceIndex: number, targetIndex: number) => void;
    onRowsSelect?: (rows: Set<T['uiId']>) => void;
    checkboxRenderer?: (props: RenderCheckboxProps) => React.ReactNode;
    rowHeight?: (row: T) => number;
};

const DataGrid = typedForwardRef(
    <T extends { uiId: string; order?: number; title?: string }>(
        props: DataGridProps<T, string>,
        ref: React.ForwardedRef<DataGridHandle>,
    ) => {
        const theme = useTheme();
        const [selectedRows, setSelectedRows] = React.useState<Set<string>>(new Set());
        const onRowsSelect = React.useCallback(
            (rows: Set<string>) => {
                const newRows = new Set(rows);
                if (!props.multiselect) {
                    [...selectedRows].forEach((uid) => newRows.delete(uid));
                }
                setSelectedRows(newRows);
                props.onRowsSelect?.(newRows);
            },
            [props.multiselect, props.onRowsSelect, selectedRows],
        );

        const rowHeights = React.useRef<Record<string, number>>({});
        const setHeight = React.useCallback((row: T, element: HTMLElement | null) => {
            const currentHeight = Math.max(rowHeights.current[row.uiId] || 0, (element?.scrollHeight || 0) + 20);
            rowHeights.current[row.uiId] = Math.min(Math.max(currentHeight, ROW_HEIGHT), MAX_ROW_HEIGHT_VALUE);
        }, []);

        const getRowHeight = React.useCallback(
            (row: T) => {
                if (selectedRows.has(row.uiId)) {
                    return rowHeights.current[row.uiId] || ROW_HEIGHT;
                }
                return ROW_HEIGHT;
            },
            [selectedRows],
        );

        const emptyRows = React.useMemo(
            () =>
                Array(3)
                    .fill({})
                    .map((_, index) => ({ uiId: String(index) }) as T),
            [],
        );

        const [filters, setFilters] = React.useState<Record<string, RegExp | undefined>>({});

        const applyFilter = React.useCallback((colKey: string, value?: string | string[]) => {
            setFilters((filters) =>
                set(
                    { ...filters },
                    colKey,
                    value ? parseRegExp(Array.isArray(value) ? value.map((v) => `^${v}`).join('|') : value) : undefined,
                ),
            );
        }, []);

        const editable = !props.loading && !props.disabled;

        const dgCols = React.useMemo<Column<T>[]>(() => {
            if (props.loading) {
                return props.columns.slice(0, 5).map((col) => {
                    return {
                        ...col,
                        key: col.key + '_skeleton',
                        width: null,
                        renderHeaderCell: ({ column }) => {
                            return (
                                <Typography fontWeight="bold" textTransform="capitalize">
                                    {column.name}
                                </Typography>
                            );
                        },
                        renderCell: () => <Skeleton variant="text" animation="wave" height="2em" width="10em" />,
                    };
                });
            }

            let result: Column<T>[] = [];
            if ((props.withRowSelect || props.withRowReorder) && !props.loading) {
                const actions = props.columns.find((col) => col.key === 'actions');
                result = result.concat({
                    ...(props.withRowSelect && SelectColumn),
                    key: 'select',
                    width: actions?.width ? actions.width : 80,
                    maxWidth: actions?.width ? actions.width : 80,
                    renderHeaderCell: actions?.renderHeaderCell,
                    headerCellClass: 'noBorder',
                    cellClass: 'select',
                    editable,
                    renderSummaryCell: actions?.renderSummaryCell,
                    renderCell: (cellProps: RenderCellProps<T>) => {
                        const index = props.data.findIndex((wf) => wf.uiId === cellProps.row.uiId);
                        return (
                            <>
                                <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                    {props.withRowSelect && SelectColumn.renderCell?.(cellProps)}
                                </Box>
                                {props.withRowReorder && (
                                    <DraggableCell
                                        title={props.data[index]['title']}
                                        type="row"
                                        idx={index}
                                        disabled={!editable}
                                    />
                                )}
                                {actions?.renderCell?.(cellProps)}
                            </>
                        );
                    },
                } as Column<T>);
            }

            return result
                .concat(props.columns)
                .filter((col) => col.key !== 'actions')
                .map((col) => ({
                    ...col,
                    renderCell: (props) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        const { isRowSelected } = useRowSelection();
                        const cellValue = col.toStr ? col.toStr(props.row) : toStr(get(props.row, col.key));
                        const filter = get(filters, col.key);
                        const html = filter
                            ? cellValue.replace(filter, `<span style="color: ${theme.palette.warning.light}">$&</span>`)
                            : cellValue;
                        return col.renderCell?.({
                            ...props,
                            filter,
                            html: sanitizeHtmlElements(html),
                            expanded: isRowSelected,
                            setHeight: (element: HTMLElement) => setHeight(props.row, element),
                        });
                    },
                    renderHeaderCell: (hProps) => (
                        <HeaderRenderer
                            {...hProps}
                            onColReorder={props.onColReorder}
                            inheritedRenderer={col.renderHeaderCell}
                            filterValues={col.filterValues}
                            onFilter={col.filterable ? (col.applyFilter ?? applyFilter) : undefined}
                        />
                    ),
                }));
        }, [
            props.loading,
            props.columns,
            props.data,
            props.withRowSelect,
            props.withRowReorder,
            props.onColReorder,
            setHeight,
            editable,
            applyFilter,
            filters,
        ]);

        const hasFooter = React.useMemo(() => {
            return dgCols.find((col) => Boolean(col.renderSummaryCell));
        }, [dgCols]);

        const rowRenderer = React.useCallback(
            (_: React.Key, rowProps: RenderRowProps<T>) => {
                return <DataGridRow {...rowProps} key={rowProps.row.uiId} onRowReorder={props.onRowReorder} />;
            },
            [props.onRowReorder],
        );

        const filteredRows = React.useMemo(() => {
            return props.data.filter((row) => {
                return props.columns.every(({ key }) => {
                    const filter = get(filters, key);
                    if (!filter) return true;
                    return filter.test(toStr(get(row, key)));
                });
            });
        }, [props.data, filters]);

        const width = React.useMemo(
            () => dgCols.reduce((acc, col) => acc + (Number(col.width) || 100), 0) + 2,
            [dgCols],
        );

        const bottomSummaryRows = React.useMemo(
            () => (props.loading || !hasFooter ? [] : emptyRows.slice(0, 1)),
            [props.loading, emptyRows, hasFooter],
        );

        return (
            <DataGridContainer
                loading={props.loading}
                multiselect={props.multiselect}
                rowCount={emptyRows.length}
                rowHeight={ROW_HEIGHT}
                headerRowHeight={HEADER_ROW_HEIGHT}
                width={width}
            >
                <DataGridBase
                    {...props}
                    ref={ref}
                    className="rdg-light"
                    rowKeyGetter={(row) => row.uiId}
                    headerRowHeight={HEADER_ROW_HEIGHT}
                    rowHeight={props.rowHeight || getRowHeight}
                    rows={props.loading ? emptyRows : filteredRows}
                    columns={dgCols}
                    bottomSummaryRows={bottomSummaryRows}
                    rowClass={props.rowClass}
                    renderers={{
                        renderRow: rowRenderer,
                        renderCheckbox: props.checkboxRenderer || CheckboxRenderer,
                    }}
                    enableVirtualization
                    selectedRows={selectedRows}
                    onSelectedRowsChange={onRowsSelect}
                />
            </DataGridContainer>
        );
    },
);

export default DataGrid;
