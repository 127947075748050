import { AxiosError } from 'axios';
import { JSONSchema7 } from 'json-schema';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ILlmExperiment, ILlmExperimentCreate } from '@tymely/atoms';
import { createLlmExperiment, fetchLlmExperimentSchema, searchLlmExperiments } from '@tymely/api';

import { useSetAlert } from './alerts.services';

export const LLM_EXPERIMENTS_SEARCH_QUERY_KEY = 'llm-experiments-history';

export const useLlmExperimentsSearch = (
    experimentType: ILlmExperiment['experiment_type'],
    optimizationTargetName: string,
    enabled = true,
) => {
    return useQuery<ILlmExperiment[]>(
        [LLM_EXPERIMENTS_SEARCH_QUERY_KEY, experimentType, optimizationTargetName],
        () => searchLlmExperiments(experimentType, optimizationTargetName),
        { enabled },
    );
};

export const useCreateLlmExperiment = () => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();
    return useMutation<ILlmExperiment, AxiosError, ILlmExperimentCreate>(createLlmExperiment, {
        onSuccess: (llmExperiment) =>
            queryClient.invalidateQueries([
                LLM_EXPERIMENTS_SEARCH_QUERY_KEY,
                llmExperiment.experiment_type,
                llmExperiment.additional_data.optimization_target_name,
            ]),
        onError: (error) => {
            setAlert(error.message, 'error', 5000, 'Failed creating LLM experiment.');
        },
    });
};

export const useLlmExperimentSchemaQuery = () =>
    useQuery<JSONSchema7>('organization-schema', () => fetchLlmExperimentSchema());
