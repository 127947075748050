import React from 'react';

const useDnDEvents = (opts: {
    type: string;
    droppable?: boolean;
    dragInd?: number;
    dropInd?: number;
    onDrop?: (dragInd: number, dropInd: number) => void;
}) => {
    const [isDragging, setDragging] = React.useState(false);
    const [isOver, setIsOver] = React.useState<-1 | 0 | 1>(0);

    const onDragStart = React.useCallback(
        (e: React.DragEvent<HTMLElement>) => {
            if (typeof opts.dragInd === 'number') {
                e.dataTransfer.setData(opts.type, String(opts.dragInd));
                e.dataTransfer.setData(`dragind:${opts.dragInd}`, String(opts.dragInd));
                e.dataTransfer.effectAllowed = 'move';
                setDragging(true);
            }
        },
        [opts.type, opts.dragInd],
    );

    const onDragEnd = React.useCallback(() => {
        setDragging(false);
    }, []);

    const onDrop = React.useCallback(
        (e: React.DragEvent) => {
            const dragInd = Number(e.dataTransfer.getData(opts.type));
            if (opts.droppable && typeof opts.dropInd === 'number') {
                opts.onDrop?.(dragInd, opts.dropInd);
            }
            setIsOver(0);
        },
        [opts.type, opts.droppable, opts.onDrop],
    );

    const onDragLeave = React.useCallback(() => {
        setIsOver(0);
    }, []);

    const onDragOver = React.useCallback(
        (e: React.DragEvent) => {
            const types = e.dataTransfer.types;
            if (!opts.droppable || !types.includes(opts.type) || !opts.dropInd) return;

            const dragInd = Number(types.find((type) => type.startsWith('dragind:'))?.split(':')[1]);
            setIsOver(opts.dropInd <= dragInd ? -1 : 1);
            e.dataTransfer.effectAllowed = 'move';
            e.preventDefault();
        },
        [opts.type, opts.dropInd, opts.droppable],
    );

    return {
        isOver,
        isDragging,
        onDragStart,
        onDragEnd,
        onDrop,
        onDragLeave,
        onDragOver,
    };
};

export default useDnDEvents;
