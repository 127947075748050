import { useViewEvent, useViewListener } from '@tymely/services';

import useGetTemplateChannel from './useGetTemplateChannel';

const TEMPLAT_VIEW_EVENT = 'TEMPLATE_BEING_PROCESSED';

export const useTemplateViewEvent = () => {
    const channel = useGetTemplateChannel();
    useViewEvent(channel, TEMPLAT_VIEW_EVENT);
};

export const useTemplateViewListener = () => {
    const channel = useGetTemplateChannel();
    return useViewListener(channel, TEMPLAT_VIEW_EVENT);
};
