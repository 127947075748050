import { applyArgUpdates } from '@tymely/atoms';
import React from 'react';
import { Loadable } from '@tymely/components';
import { AppMode, useAppMode } from '@tymely/services';

import ArgumentList from './ArgumentList';
import CalculatePolicyButton from './CalculatePolicyButton';
import useArgumentsQuery from './useArgumentsQuery';
import { useArgumentsTabsContext } from './ArgumentsTabsProvider';

type SystemArgumentSectionProps = {
    loadingStatus?: string;
};

const SystemArgumentSection = ({ loadingStatus }: SystemArgumentSectionProps) => {
    const { appMode } = useAppMode();
    const { version, updates } = useArgumentsTabsContext();

    const argsQuery = useArgumentsQuery({ version });

    const args = React.useMemo(() => {
        if (!argsQuery.data) {
            return [];
        }
        return applyArgUpdates(argsQuery.data, updates).filter(({ arg_type }) => arg_type === 'SYSTEM_ARGUMENT');
    }, [argsQuery.data, updates]);

    return (
        <Loadable waitFor={args} status={loadingStatus} reloading={!!loadingStatus}>
            <ArgumentList
                loading={!!loadingStatus}
                argumentItems={args}
                withCopy
                disabled={appMode !== AppMode.Analyst}
            />
            <CalculatePolicyButton />
        </Loadable>
    );
};

export default SystemArgumentSection;
