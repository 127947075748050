import { memo, useCallback, ChangeEvent } from 'react';
import { IArgumentInteger } from '@tymely/atoms';
import { useSetAlert } from '@tymely/services';

import { ArgumentFieldProps, StyledOutlinedInput } from './Layout';

export const IntegerArgument = memo((props: ArgumentFieldProps<IArgumentInteger>) => {
    const setAlert = useSetAlert();

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const argumentValue = event.target.valueAsNumber;

            if (!Number.isInteger(argumentValue)) {
                setAlert('Integer arg is not a valid int', 'error');
            }

            props.onChange?.([{ ...props.argument, value: argumentValue }]);
        },
        [props.argument, props.onChange],
    );

    return (
        <StyledOutlinedInput
            edited={props.argument.is_edited}
            type="number"
            id={`${props.argument.extractor_cls_name.toLowerCase()}-select`}
            value={props.argument.value === null ? '' : props.argument.value}
            specialValue={props.argument.special_value}
            disabled={props.disabled || props.loading}
            onChange={onChange}
        />
    );
});

IntegerArgument.displayName = 'IntegerArgument';
