import React from 'react';
import { Box, BoxProps, Chip, styled, Typography } from '@mui/material';

import { useTemplateViewEvent, useTemplateViewListener } from '../hooks/templateViewHooks';

const MAX_SHOWN_VIEWERS = 2;

const _Chip = styled(Chip)`
    font-size: ${({ theme }) => theme.typography.fontSmall};
    font-weight: bold;
    text-transform: uppercase;
    animation: blink 300ms;
    animation-iteration-count: infinite;
    @keyframes blink {
        50% {
            opacity: 0.5;
        }
    }
`;

type ViewerProps = { name?: string };

const Viewer = ({ name = 'Anonymous' }: ViewerProps) => (
    <_Chip label={name} color="error" size="small" variant="filled" />
);

const _Root = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.background.main};
    border-radius: 32px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    padding: ${({ theme }) => theme.spacing(1)};
    gap: ${({ theme }) => theme.spacing(1)};
`;

const _ViewedByLabel = styled(Typography)`
    display: inline;
`;

// TODO: Possibly extract this component (alongside `PolicyViewers.tsx`) into a shared one
const TemplateViewers = ({ ...rest }: BoxProps) => {
    useTemplateViewEvent();

    const viewers = useTemplateViewListener();
    if (!viewers.length) {
        return null;
    }

    const shownViewers = [...viewers.slice(0, MAX_SHOWN_VIEWERS)];

    return (
        <_Root {...rest}>
            <_ViewedByLabel>Currently viewed by</_ViewedByLabel>
            {shownViewers.map(({ username, email, viewedAt }) => (
                <Viewer key={viewedAt.toISOString()} name={username ?? email} />
            ))}
            {viewers.length > MAX_SHOWN_VIEWERS && (
                <_ViewedByLabel>and {viewers.length - MAX_SHOWN_VIEWERS} more</_ViewedByLabel>
            )}
        </_Root>
    );
};

export default TemplateViewers;
