import React from 'react';
import {
    styled,
    ToggleButton as MuiToggleButton,
    Typography,
    TypographyProps,
    Checkbox,
    IconButton,
} from '@mui/material';
import { RenderCheckboxProps } from 'react-data-grid';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

export type CellTypographyProps = TypographyProps & { wrap?: boolean; shown?: boolean };

const _Typography = React.forwardRef((props: CellTypographyProps, ref: React.ForwardedRef<HTMLSpanElement>) => (
    <Typography component="span" ref={ref} variant="body2" {...props} />
));

_Typography.displayName = '_Typography';

export const CellTypography = styled(_Typography)`
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: ${({ wrap }) => (wrap ? 'pre-wrap' : 'nowrap')};
    position: ${({ wrap }) => (wrap ? 'static' : 'abolute')};
    overflow-y: auto;
    padding: 0 8px;
    opacity: ${({ shown }) => Number(shown)};
`;

export const ToggleButton = styled(MuiToggleButton)`
    padding: 0;
    border: 0;
    margin-left: ${({ theme }) => theme.spacing(1)};
    &.Mui-selected {
        background-color: transparent;
    }
`;

export const CheckboxRenderer = ({ onChange, ...props }: RenderCheckboxProps) => (
    <Checkbox {...props} onChange={(e, checked) => onChange(checked, (e.nativeEvent as MouseEvent).shiftKey)} />
);

export function ExpandRenderer({ onChange, ...props }: RenderCheckboxProps) {
    return (
        <IconButton onClick={(e) => onChange(!props.checked, e.shiftKey)} size="small">
            {props.checked ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
        </IconButton>
    );
}
