import React from 'react';
import { Card, styled } from '@mui/material';

import RichTextEditor from '../../Ticket/TicketLayout/RichTextEditor';
import { useTemplateEditor } from './TemplateEditorProvider';

const _Card = styled(Card)`
    height: 100%;
    padding: ${({ theme }) => theme.spacing(2)};
    overflow: scroll;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
`;

const TemplateRichText = () => {
    const { renderTemplateQuery } = useTemplateEditor();
    return (
        <_Card>
            <RichTextEditor value={renderTemplateQuery.data ?? ''} />
        </_Card>
    );
};

export default TemplateRichText;
