import React from 'react';
import { RenderHeaderCellProps } from 'react-data-grid';
import { debounce } from 'lodash';
import { Box, ClickAwayListener, styled, TextField, Typography } from '@mui/material';
import { Nullable } from '@global/types';
import SearchIcon from '@mui/icons-material/Search';

import { Column } from './types';
import useDnDEvents from './useDnDEvents';
import MultiSelect, { MultiSelectProps, Option } from '../MultiSelect';
import DraggableCell from './DraggableCell';
import { ToggleButton } from './styled';

type GridDataHeaderProps<T extends { [prop: string]: unknown }, S> = RenderHeaderCellProps<T, S> & {
    filterValues?: string[];
    inheritedRenderer: Column<T, S>['renderHeaderCell'];
    onFilter?: (key: string, value?: string | string[]) => void;
    onColReorder?: (sourceIndex: number, targetIndex: number) => void;
};

const _Root = styled(Box, { shouldForwardProp: (prop: string) => !['isOver'].includes(prop) })<{ isOver: number }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: ${({ theme }) => theme.spacing(0, 1)};
    border-left: ${({ theme, isOver }) => (isOver === -1 ? `2px solid ${theme.palette.grey.A400}` : 'none')};
    border-right: ${({ theme, isOver }) => (isOver === 1 ? `2px solid ${theme.palette.grey.A400}` : 'none')};
`;

const _DraggableCell = styled(DraggableCell)`
    margin-right: ${({ theme }) => theme.spacing(1)};
`;

const _MultiSelect = styled(MultiSelect)`
    height: ${({ theme }) => theme.spacing(3)};
` as typeof MultiSelect;

const _TextField = styled(TextField)`
    &.MuiInputBase-root {
        height: ${({ theme }) => theme.spacing(1)};
        padding: ${({ theme }) => theme.spacing(1, 0)};
    }
`;

const HeaderRenderer = <T extends { [prop: string]: unknown }, S>({
    inheritedRenderer,
    filterValues,
    onFilter,
    ...props
}: GridDataHeaderProps<T, S>) => {
    const [searchInput, setSearchInput] = React.useState('');
    const [searchOpen, setSearchOpen] = React.useState(false);
    const [selectOpen, setSelectOpen] = React.useState(false);
    const [shouldFocusOnOpen, setShouldFocusOnOpen] = React.useState(true);
    const onSearch = React.useMemo(
        () =>
            debounce((value: string | string[]) => {
                React.startTransition(() => {
                    onFilter?.(props.column.key, value);
                });
            }, 300),
        [props.column, onFilter],
    );
    const onToggleSearch = React.useCallback(() => {
        if (searchOpen) {
            setShouldFocusOnOpen(true);
            setSearchInput('');
            onSearch('');
        }
        setSearchOpen((searchOpen) => !searchOpen);
    }, [searchOpen, onSearch]);
    const [selected, setOptions] = React.useState<Option<string>[]>([]);
    const options = React.useMemo(
        () =>
            filterValues?.map((value) => ({
                value,
                name: value,
            })),
        [filterValues],
    );
    const onOptsSelect = React.useCallback(
        (options: Nullable<Option<string>[]>) => {
            options = options || [];
            setOptions(options);
            onFilter?.(
                props.column.key,
                options.map(({ value }) => value),
            );
        },
        [onFilter],
    ) as MultiSelectProps<string>['onOptsSelect'];

    const { isOver, onDrop, onDragLeave, onDragOver } = useDnDEvents({
        droppable: props.column.droppable,
        type: 'column',
        dropInd: props.column.idx,
        onDrop: props.onColReorder,
    });

    return (
        <_Root isOver={isOver} onDrop={onDrop} onDragLeave={onDragLeave} onDragOver={onDragOver}>
            <Box display="flex" width="100%" alignItems="center">
                {props.column.draggable && (
                    <_DraggableCell
                        title={inheritedRenderer?.(props) || props.column.name}
                        type="column"
                        idx={props.column.idx}
                        disabled={!props.column.editable}
                    />
                )}
                {inheritedRenderer ? (
                    inheritedRenderer(props)
                ) : (
                    <Typography fontWeight="bold" textTransform="capitalize">
                        {props.column.name}
                    </Typography>
                )}
                {onFilter && (
                    <ToggleButton value="check" selected={searchOpen} size="small" onChange={onToggleSearch}>
                        <SearchIcon />
                    </ToggleButton>
                )}
            </Box>
            {searchOpen && options && onFilter && (
                <ClickAwayListener onClickAway={() => setSelectOpen(false)}>
                    <_MultiSelect
                        multiple
                        fullWidth
                        open={selectOpen}
                        placeholder="Select..."
                        value={selected}
                        options={options}
                        disablePortal
                        MenuProps={{
                            transformOrigin: {
                                vertical: 8,
                                horizontal: 250,
                            },
                        }}
                        onOpen={() => setSelectOpen(true)}
                        onOptsSelect={onOptsSelect}
                    />
                </ClickAwayListener>
            )}
            {searchOpen && !options && onFilter && (
                <_TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Search..."
                    inputRef={(input) => {
                        if (input && shouldFocusOnOpen) {
                            setTimeout(() => input.focus());
                            setShouldFocusOnOpen(false);
                        }
                    }}
                    inputProps={{
                        tabIndex: props.tabIndex,
                        value: searchInput,
                        onChange: (e: React.FormEvent<HTMLInputElement>) => {
                            setSearchInput(e.currentTarget.value);
                            onSearch(e.currentTarget.value);
                        },
                        onKeyDown: (e) => e.stopPropagation(),
                        onClick: (e) => e.stopPropagation(),
                    }}
                    size="small"
                />
            )}
        </_Root>
    );
};

export default HeaderRenderer;
