import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { Box, IconButton, styled, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import { useCreateArgumentMetadataMutation, useEditArgumentMetadataMutation } from '@tymely/services';
import { IArgumentMetadata, IArgument } from '@tymely/atoms';
import { ITemplateVariable } from '@tymely/api';

import { ArgMetadataEditDialog, defaultArgMetadata } from '../../ArgMetadataEditor/ArgMetadataEditDialog';
import { ArgErrorFallback } from '../../Ticket/Arguments';
import convertToArgument from '../utils/convertToArgument';
import ArgEditor from './ArgEditor';

const _Root = styled(Box)`
    display: flex;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const _RadioContainer = styled(Box)`
    display: inline-flex;
`;

type VariableProps = {
    name: string;
    value: ITemplateVariable;
    argMetadata?: IArgumentMetadata;
    onChange: (varName: string, value: ITemplateVariable) => void;
};

const Variable = ({ name, value, argMetadata, onChange }: VariableProps) => {
    const [editOpen, setEditOpen] = React.useState(false);
    const editArgMetadata = useEditArgumentMetadataMutation();
    const createArgMetadata = useCreateArgumentMetadataMutation();
    const [argMetadataState, setArgMetadata] = React.useState(
        argMetadata ?? {
            ...omit(defaultArgMetadata, 'id'),
            name: name,
            id: 0,
        },
    );

    const argument = React.useMemo(() => convertToArgument(argMetadataState, value), [argMetadataState, value]);

    const onSubmitArgMetadata = React.useCallback(
        async (updatedArgMetadata: IArgumentMetadata) => {
            const onSuccess = ({ id: argMetaId }: IArgumentMetadata) => {
                if (argMetadataState.dtype !== updatedArgMetadata.dtype) {
                    onChange(updatedArgMetadata.name, {
                        ...value,
                        special_value: null,
                        value: '',
                    });
                }
                if (!isEqual(updatedArgMetadata.options?.categories, argMetadataState.options?.categories)) {
                    onChange(updatedArgMetadata.name, {
                        ...value,
                        value: '',
                    });
                }
                if (argMetadataState.options?.neitherable !== updatedArgMetadata.options?.neitherable) {
                    onChange(updatedArgMetadata.name, {
                        ...value,
                        value: '',
                        special_value: argument.special_value === 'neither' ? null : argument.special_value,
                    });
                }
                if (argMetadataState.unspecifiable !== updatedArgMetadata.unspecifiable) {
                    onChange(updatedArgMetadata.name, {
                        ...value,
                        special_value: argument.special_value === 'unspecified' ? null : argument.special_value,
                    });
                }
                if (argMetadataState.unknowable !== updatedArgMetadata.unknowable) {
                    onChange(updatedArgMetadata.name, {
                        ...value,
                        special_value: argument.special_value === 'unknown' ? null : argument.special_value,
                    });
                }
                setArgMetadata({ ...updatedArgMetadata, id: argMetaId });
                setEditOpen(false);
            };

            if (argMetadataState.id) {
                await editArgMetadata.mutateAsync(updatedArgMetadata).then(onSuccess);
            } else {
                await createArgMetadata.mutateAsync({ metadata: updatedArgMetadata }).then(onSuccess);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [argMetadataState, editArgMetadata, createArgMetadata, argument],
    );

    const _onChange = React.useCallback(
        async ([argument]: IArgument[]) => {
            onChange(argument.name, {
                ...value,
                value: argument.value,
                special_value: argument.value === null ? 'neither' : null,
            });
            return [argument];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [value],
    );

    const onUnspecify = React.useCallback(() => {
        onChange(argument.name, {
            ...value,
            value: null,
            special_value: 'unspecified',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [argument, value]);

    const onSetUnknown = React.useCallback(() => {
        onChange(argument.name, {
            ...value,
            value: null,
            special_value: 'unknown',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [argument, value]);

    return (
        <>
            <_Root>
                <ErrorBoundary resetKeys={[argument]} FallbackComponent={ArgErrorFallback}>
                    <ArgEditor argument={argument} onChange={_onChange} />
                </ErrorBoundary>
                <Tooltip title="Unspecified" enterDelay={0.5}>
                    <_RadioContainer>
                        <IconButton aria-label="unspecified" disabled={!argument.unspecifiable} onClick={onUnspecify}>
                            {argument.special_value === 'unspecified' ? (
                                <RadioButtonCheckedIcon fontSize="small" />
                            ) : (
                                <RadioButtonUncheckedIcon fontSize="small" />
                            )}
                        </IconButton>
                    </_RadioContainer>
                </Tooltip>
                <Tooltip title="Unknown" enterDelay={0.5}>
                    <_RadioContainer>
                        <IconButton aria-label="unknown" disabled={!argument.unknowable} onClick={onSetUnknown}>
                            <PsychologyAltIcon
                                fontSize="small"
                                color={argument.special_value === 'unknown' ? 'inherit' : 'disabled'}
                            />
                        </IconButton>
                    </_RadioContainer>
                </Tooltip>
                <IconButton disabled={editArgMetadata.isLoading} onClick={() => setEditOpen(true)}>
                    {argMetadata ? <EditIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                </IconButton>
            </_Root>
            {editOpen && (
                <ArgMetadataEditDialog
                    argMetadata={argMetadataState}
                    onSubmit={onSubmitArgMetadata}
                    onClose={() => setEditOpen(false)}
                />
            )}
        </>
    );
};

export default Variable;
