import { memo, useCallback, useState } from 'react';
import { CardContent, Chip, styled, useTheme } from '@mui/material';
import { BaseModal, ContainerCard, DemoIntents, Intents, StyledLoader } from '@tymely/components';
import {
    IWaitType,
    useCreateHandlingDurationsCrumb,
    useIntentsQuery,
    useSelectedComment,
    useSelectedCommentIntents,
    useTagComment,
    useTicketUserWaited,
} from '@tymely/services';
import { IDineshTicketOperations, IPolicySet } from '@tymely/atoms';
import { INTENTS_TOP_INFERRED_CATEGORY, MULTIPLE_LOGIC_BOX_ID } from '@tymely/config';

const SelectedIntent = styled(Chip)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[600],
    '& .MuiSvgIcon-root': {
        color: theme.palette.common.white,
    },
}));

const IntentsModal = memo(
    (props: { open: boolean; onSelect: (intents: IPolicySet[]) => void; onClose: () => void }) => {
        const [selectedIntents, setSelectedIntents] = useState<IPolicySet[]>([]);
        const intents = useIntentsQuery();
        const onIntentSelect = useCallback((intent: IPolicySet) => {
            setSelectedIntents((intents) => [...intents, intent]);
        }, []);

        const onIntentDeselect = useCallback((intent: IPolicySet) => {
            setSelectedIntents((intents) => intents.filter(({ id }) => id !== intent.id));
        }, []);

        const onSelect = useCallback(() => {
            if (!intents.data) return;
            const multipleIntent = intents.data.find((intent) => intent.id === MULTIPLE_LOGIC_BOX_ID);
            props.onSelect([multipleIntent!, ...selectedIntents.filter(({ id }) => id !== MULTIPLE_LOGIC_BOX_ID)]);
        }, [intents.data, selectedIntents]);

        return (
            <BaseModal
                title="Select Multiple Intents"
                open={props.open}
                PaperProps={{ sx: { height: 800 } }}
                disabled={selectedIntents.length <= 1}
                onOk={onSelect}
                onClose={props.onClose}
            >
                {selectedIntents.map((intent) => (
                    <SelectedIntent
                        key={intent.id}
                        label={intent.name}
                        size="small"
                        variant="filled"
                        color="primary"
                        sx={{ mb: 1, mr: 1 }}
                        onDelete={() => onIntentDeselect(intent)}
                    />
                ))}
                <Intents selectedIds={selectedIntents.map(({ id }) => id)} onIntentSelect={onIntentSelect} />
            </BaseModal>
        );
    },
);

IntentsModal.displayName = 'IntentsModal';

export const IntentsSection = memo((props: { onSelectIntent?: () => void; onDeselectIntent?: () => void }) => {
    const selectedIntents = useSelectedCommentIntents();
    const selectedComment = useSelectedComment();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const timer = useTicketUserWaited();

    const tagComment = useTagComment();
    const createHandlingDurationsCrumb = useCreateHandlingDurationsCrumb();
    const createTicketCrumb = useCreateHandlingDurationsCrumb();
    const onTagComment = useCallback(
        async (intents: IPolicySet[], searchTerm?: string) => {
            setModalOpen(false);
            const mainIntent = intents[0];
            if (selectedComment) {
                setLoading(true);
                timer.start(IWaitType.TAGGING);
                tagComment(
                    selectedComment.id,
                    intents.map((intent) => intent.id),
                )
                    .then(props.onSelectIntent)
                    .finally(() => {
                        setLoading(false);
                        const inferredIntents = selectedComment?.infer_data?.intents;
                        createHandlingDurationsCrumb(IDineshTicketOperations.USER_TAGGED_TICKET, {
                            selected_from_proposed: intents.some(
                                (intent) => intent.category === INTENTS_TOP_INFERRED_CATEGORY.toLowerCase(),
                            ),
                            proposed:
                                inferredIntents?.map((intent) => intent.intent_id).includes(mainIntent.id) || false,
                            inferred_intents: inferredIntents?.map((intent) => intent.intent_id) || [],
                            searchTerm: searchTerm || '',
                            wait_duration: timer.stop(IWaitType.TAGGING),
                        });
                    });
            }
        },
        [selectedComment, tagComment, createTicketCrumb, createHandlingDurationsCrumb, props.onSelectIntent],
    );

    const onIntentSelect = useCallback(
        async (intents: IPolicySet[], searchTerm?: string) => {
            const mainIntent = intents[0];
            if (mainIntent.id === MULTIPLE_LOGIC_BOX_ID) {
                setModalOpen(true);
            } else {
                onTagComment(intents, searchTerm);
            }
        },
        [onTagComment],
    );

    return (
        <>
            <IntentsModal open={modalOpen} onSelect={onTagComment} onClose={() => setModalOpen(false)} />
            <Intents
                selectedIds={selectedIntents.map((intent) => intent.id)}
                disabled={loading}
                onIntentSelect={(intent, searchTerm) => onIntentSelect([intent], searchTerm)}
            />
            {loading && <StyledLoader id="intents-styled-loader" />}
        </>
    );
});

IntentsSection.displayName = 'IntentsSection';

export const DemoIntentsSection = memo((props: { onSelectIntent?: () => void; onNext?: () => void }) => {
    const theme = useTheme();
    return (
        <ContainerCard>
            <CardContent sx={{ height: 1, p: 3, [theme.breakpoints.up(1600)]: { p: 4 } }}>
                <DemoIntents onSelectIntent={props.onSelectIntent} onNext={props.onNext} />
            </CardContent>
        </ContainerCard>
    );
});

DemoIntentsSection.displayName = 'DemoIntentsSection';
