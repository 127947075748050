import React from 'react';
import { IArgument, isCategorical } from '@tymely/atoms';

import {
    AddressArgument,
    ArgumentFieldProps,
    BooleanArgument,
    DateTimeArgument,
    InputFieldArgument,
    MultiCategoryArgument,
    ObjectArgument,
} from '../../Ticket';

type ArgEditorProps = {
    argument: IArgument;
    onChange?: ArgumentFieldProps<IArgument>['onChange'];
};

const ArgEditor = ({ argument, onChange }: ArgEditorProps) => {
    if (isCategorical(argument)) {
        const _onChange = (args: (typeof argument)[]) => onChange?.(args) as Promise<(typeof argument)[]>;
        return <MultiCategoryArgument onChange={_onChange} withLabel argument={argument} />;
    }

    switch (argument.dtype) {
        case 'Image':
        case 'Url':
        case 'VideoUrl':
        case 'list':
        case 'int':
        case 'float':
        case 'str':
        case 'EmailStr':
        case 'list[str]':
        case 'list[Image]':
        case 'list[VideoUrl]':
        case 'list[Url]':
        case 'list[EmailStr]':
            return <InputFieldArgument onChange={onChange} withLabel argument={argument} />;
        case 'bool':
        case 'bool|None':
            return <BooleanArgument onChange={onChange} withLabel argument={argument} />;
        case 'datetime':
            return <DateTimeArgument onChange={onChange} withLabel argument={argument} />;
        case 'AddressStr':
            return <AddressArgument withLabel argument={argument} onChange={onChange} />;
        default:
            return <ObjectArgument withLabel argument={argument} onChange={onChange} />;
    }
};

export default ArgEditor;
