import { memo, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
    Theme,
    Drawer as MuiDrawer,
    CSSObject,
    List,
    ListItem,
    ListItemProps,
    ListItemButton,
    useTheme,
    Box,
    Typography,
    styled,
    BoxProps,
    Tooltip as MuiTooltip,
    TooltipProps,
    tooltipClasses,
} from '@mui/material';
import { SxProps } from '@mui/system';
import useSwitch, { UseSwitchParameters } from '@mui/base/useSwitch';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import Logo from '../Icons/Logo';

const openedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: 'transparent',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    width: theme.spacing(59.5),
    flexDirection: 'row',
    overflow: 'visible',
    border: 0,
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(11.5),
    overflow: 'visible',
    border: 0,
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
            ...openedMixin(theme),
        },
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export const NavList = styled(List)(
    ({ theme }) => `
    padding: 0;
    background-color: ${theme.nav.default};
    width: ${theme.spacing(11.5)};
`,
);

export const NavItem = styled(ListItem, { shouldForwardProp: (prop) => prop !== 'active' })<
    ListItemProps & { active?: boolean }
>(
    ({ theme, active }) => `
    display: flex;
    justify-content: center;
    width: auto;
    padding: ${theme.spacing(2, 0)};
    margin-bottom: ${theme.spacing(1)};
    justify-content: center;
    color: ${active ? theme.palette.common.white : theme.nav.text};
    &:hover: {
        color: ${theme.palette.common.white};
    }
    background-color: ${active ? theme.nav.active : 'transparent'};
    border-left: solid ${theme.spacing(1)} transparent;
    border-left-color: ${active ? theme.palette.primary.light : 'transparent'};
    cursor: pointer;
    .MuiButtonBase-root {
        margin-right: ${theme.spacing(1)};
    }
`,
);

const MenuItem = styled(NavItem)(
    ({ theme }) => `
    border-top-left-radius: ${theme.spacing(4)};
    border-bottom-left-radius: ${theme.spacing(4)};
    margin-left: ${theme.spacing(1)};
    border-width: 0;
`,
);

const NavItemButton = styled(ListItemButton)(
    ({ theme }) => `
    justify-content: center;
    padding: 0;
    height: 34px;
    min-width: auto;
    &:hover {
        color: ${theme.palette.primary.light};
    }
`,
);

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip
        {...props}
        arrow
        classes={{ popper: className }}
        placement={props.placement || 'right'}
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -8],
                        },
                    },
                ],
            },
        }}
    />
))(
    ({ theme }) => `
    .${tooltipClasses.arrow} {
      color: ${theme.nav.default}
    }
    .${tooltipClasses.tooltip} {
      background-color: ${theme.nav.default};
      text-align: center;
      padding-top: 0,
      padding-bottom: 0;
      border-radius: 6.6px;
      display: flex;
      align-items: center;
    },
  `,
);

const WarningTooltip = styled((props: Omit<TooltipProps, 'title'> & { warning?: ReactNode }) => (
    <Tooltip
        {...props}
        placement="top"
        title={
            props.warning && (
                <Typography fontSize="fontTiny" variant="subtitle2">
                    {props.warning}
                </Typography>
            )
        }
    ></Tooltip>
))`
    .${tooltipClasses.tooltip} {
        background-color: ${({ theme }) => theme.palette.error.main};
        height: auto;
    }
    .${tooltipClasses.arrow} {
        color: ${({ theme }) => theme.palette.error.main};
    }
`;

export const BarItem = memo(
    (props: {
        active?: boolean;
        icon: ReactElement;
        title?: string;
        children?:
            | ReactElement
            | ((anchor: HTMLLIElement | null, active: boolean, setClose: () => void) => ReactElement);
        sx?: SxProps<Theme>;
        hasMenu?: boolean;
        warning?: ReactNode;
        disabled?: boolean;
        onActive?: (active: boolean) => void;
        onClick?: () => void;
    }) => {
        const [active, setActive] = useState(Boolean(props.active));
        const anchorRef = useRef<HTMLLIElement>(null);
        useEffect(() => {
            setActive(Boolean(props.active));
        }, [props.active]);
        const ItemComp = props.hasMenu ? MenuItem : NavItem;
        const activable = !props.disabled && (props.hasMenu || props.onActive);
        const onNavToggle = useCallback(() => {
            if (activable) {
                setActive(!active);
                props.onActive?.(!active);
            }
            props.onClick?.();
        }, [active, activable, anchorRef.current, props.onClick, props.onActive]);
        return (
            <>
                <Tooltip
                    disableHoverListener={!props.title || props.disabled}
                    title={active ? null : <Typography variant="subtitle2">{props.title}</Typography>}
                    placement="right"
                >
                    <ItemComp
                        ref={anchorRef}
                        active={active}
                        sx={props.sx}
                        onMouseDown={(e) => {
                            e.preventDefault?.();
                        }}
                        onClick={onNavToggle}
                    >
                        <WarningTooltip open={Boolean(props.warning)} warning={props.warning}>
                            <NavItemButton disableRipple disabled={props.disabled}>
                                {props.icon}
                            </NavItemButton>
                        </WarningTooltip>
                    </ItemComp>
                </Tooltip>
                {typeof props.children === 'function'
                    ? props.children?.(anchorRef.current, Boolean(active), onNavToggle)
                    : props.children}
            </>
        );
    },
);

BarItem.displayName = 'BarItem';

export const LogoItem = memo(() => {
    const navigate = useNavigate();
    return (
        <NavItem sx={{ mb: 3.5 }}>
            <NavItemButton onClick={() => navigate('/ticket')}>
                <Logo />
            </NavItemButton>
        </NavItem>
    );
});

LogoItem.displayName = 'LogoItem';

const DrawerContainer = styled(Box)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 0;
    flex: 1;
    background-color: ${theme.palette.common.white};
    padding-top: ${theme.spacing(4)};
`,
);

export const DrawerContent = memo(
    ({ children, onClose, ...props }: { children?: ReactNode; onClose: () => void } & BoxProps) => {
        const theme = useTheme();
        return (
            <DrawerContainer {...props}>
                <Box component="header" width={1} minHeight={theme.spacing(4)} position="relative">
                    <Box
                        role="button"
                        display="inline-flex"
                        alignItems="center"
                        position="absolute"
                        right={theme.spacing(-2)}
                        bottom={0}
                        sx={{ cursor: 'pointer' }}
                        onClick={onClose}
                    >
                        <Typography color="text.disabled" variant="subtitle2" marginRight={-1.5} sx={{ zIndex: 1 }}>
                            Collapse
                        </Typography>
                        <Box
                            display="inline-flex"
                            alignItems="center"
                            width={44}
                            height={44}
                            borderRadius="50%"
                            sx={{ backgroundColor: theme.palette.common.white }}
                        >
                            <ArrowBackIosIcon
                                sx={{ position: 'absolute', right: 4, fontSize: '1rem', color: '#6E6D99' }}
                            />
                        </Box>
                    </Box>
                </Box>
                {children}
            </DrawerContainer>
        );
    },
);

DrawerContent.displayName = 'DrawerContent';

const SwitchRoot = styled('div')<{ withIcon: boolean }>(
    ({ theme, withIcon }) => `
    display: inline-block;
    position: relative;
    width: ${withIcon ? theme.spacing(7) : theme.spacing(8)};
    height: ${withIcon ? theme.spacing(3.5) : theme.spacing(4)};
    font-size: ${theme.typography.fontTiny};
    color: ${theme.palette.common.white};
`,
);

const SwitchTrack = styled('div')(
    ({ theme }) => `
    background-color: ${theme.components?.Switch?.backgroundColor};
    border-radius: ${theme.spacing(3)};
    width: 100%;
    height: 100%;
  `,
);

const SwitchInput = styled('input')(
    () => `
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    margin: 0;
`,
);

const SwitchThumb = styled('span')<{ withIcon: boolean }>(
    ({ theme, withIcon }) => `
    position: absolute;
    display: inline-flex;
    align-items: center;
    background-color: ${theme.palette.common.selected};
    border-radius: ${theme.spacing(3)};
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
    height: 100%;
    padding: 0 10px;
    color: ${theme.palette.common.white};
    &.checked {
        transform: translateX(${withIcon ? theme.spacing(3) : theme.spacing(4)});
    }
`,
);

const SwitchText = styled(Box)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

export const Switch = memo(
    (props: UseSwitchParameters & { onLabel: ReactNode; offLabel: ReactNode; sx?: SxProps<Theme> }) => {
        const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);
        const withIcon = typeof props.onLabel !== 'string';

        const stateClasses = {
            checked,
            disabled,
            focusVisible,
        };

        return (
            <SwitchRoot className={clsx(stateClasses)} sx={props.sx} withIcon={withIcon}>
                <SwitchTrack>
                    {checked && <SwitchText left={8}>{props.offLabel}</SwitchText>}
                    <SwitchThumb className={clsx(stateClasses)} withIcon={withIcon}>
                        {checked ? props.onLabel : props.offLabel}
                    </SwitchThumb>
                    {!checked && <SwitchText right={8}>{props.onLabel}</SwitchText>}
                </SwitchTrack>
                <SwitchInput {...getInputProps()} aria-label="Nav Switch" />
            </SwitchRoot>
        );
    },
);

Switch.displayName = 'Switch';
