import { v4 as uuid } from 'uuid';
import { UiId } from '@tymely/atoms';
import sanitize, { defaults } from 'sanitize-html';

export const newUiId = () => uuid().slice(0, 8);

export function isValidJson(json: string): boolean {
    try {
        JSON.parse(json);
    } catch (_error) {
        return false;
    }

    return true;
}

export function reorderUiObjects<T extends UiId<{ order: number }>>(
    objects: T[],
    source: number | string,
    target: number | string,
    onError?: (msg: string) => void,
): T[] {
    if (source === target) {
        return objects;
    }

    const sourceObj = typeof source === 'number' ? objects[source] : objects.find((obj) => obj.uiId === source);
    const destObj = typeof target === 'number' ? objects[target] : objects.find((obj) => obj.uiId === target);

    if (!sourceObj || !destObj) {
        onError?.('Error while reordering');
        return objects;
    }

    return objects.map((obj) => {
        if (obj === sourceObj) {
            return { ...obj, order: destObj.order };
        }

        if (sourceObj.order < destObj.order && obj.order > sourceObj.order && obj.order <= destObj.order) {
            return { ...obj, order: obj.order - 1 };
        }

        if (sourceObj.order > destObj.order && obj.order < sourceObj.order && obj.order >= destObj.order) {
            return { ...obj, order: obj.order + 1 };
        }

        return obj;
    });
}

export const sanitizeHtmlElements = (x: string) =>
    sanitize(x, {
        ...defaults,
        allowedTags: [],
        allowedAttributes: {},
    });

export const numbersStringToNumberArray = (ticketIds: string): number[] => {
    let ticketIds_ = '';
    let ticketIdsList: number[] = [];

    if (ticketIds && (ticketIds_ = ticketIds.trim())) {
        ticketIds_ = ticketIds_.replace(/\s*,\s*/g, ',');
        ticketIdsList = ticketIds_
            .split(/\s|,/)
            .filter((n) => n)
            .map((n) => Number(n));
    }

    return ticketIdsList;
};
