import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import { Nullable } from '@global/types';

export const ArgTypes = [
    'TEXT_ARGUMENT',
    'SYSTEM_ARGUMENT',
    'USER_INPUT',
    'VARIABLE',
    'WORKFLOW_ARGUMENT',
    'AUXILIARY_ARGUMENT',
    'INFO_ARGUMENT',
] as const;
export type ArgType = (typeof ArgTypes)[number];

export const ArgScopes = ['COMMENT', 'TICKET', 'INTENT', 'ORGANIZATION'] as const;
export type ArgScope = (typeof ArgScopes)[number];

const mediaDTypes = ['Image', 'Url', 'VideoUrl'] as const;
type mediaDType = (typeof mediaDTypes)[number];

export const dTypes = [
    'bool',
    'int',
    'float',
    'str',
    'EmailStr',
    'AddressStr',
    'datetime',
    'dict',
    ...mediaDTypes,
] as const;

export type dType = (typeof dTypes)[number];
export type dList<T extends dType> = `list[${T}]`;
export type dNullableType<T extends dType> = `${T}|None`;

export type dTypeSchema = {
    title: string;
    type: string;
    enum?: string[];
};

type getValueType<DT> = DT extends 'bool'
    ? boolean
    : DT extends 'int' | 'float'
      ? number
      : DT extends 'str' | 'EmailStr' | 'AddressStr' | 'datetime' | mediaDType
        ? string
        : DT extends 'list'
          ? string[]
          : object;

export interface IArgumentBase<DT extends dType | dList<dType> | 'list' | dNullableType<dType>> {
    extractor_cls_name: string;
    id: number;
    md_id: number;
    title: string;
    description: string;
    show_description: boolean;
    value: Nullable<DT extends dList<infer E> ? getValueType<E>[] : getValueType<DT>>;
    is_edited: boolean;
    confidence: Nullable<number>;
    high_confidence: boolean;
    unspecifiable: boolean;
    unknowable: boolean;
    neitherable: boolean;
    special_value: Nullable<'unspecified' | 'unknown' | 'neither'>;
    is_list: boolean;
    dtype: DT;
    arg_type: ArgType;
    rank: number;
    order: number;
    name: string;
    icon?: string;
    created_at: string;
    approved_at: string;
    dependencies: string[];
    dependent_node_ids?: string[];
}

export type ICategoryItem = { name: string; title: string; group: string };
export type IArgumentBoolean = IArgumentBase<'bool' | 'bool|None'>;
export type IArgumentUrl = IArgumentBase<'Url'>;
export type IArgumentUrls = IArgumentBase<'list[Url]'>;
export type IArgumentImageUrl = IArgumentBase<'Image'>;
export type IArgumentImageUrls = IArgumentBase<'list[Image]'>;
export type IArgumentString = IArgumentBase<'str'>;
export type IArgumentStrings = IArgumentBase<'list[str]'>;
export type IArgumentEmailString = IArgumentBase<'EmailStr'>;
export type IArgumentEmailStrings = IArgumentBase<'list[EmailStr]'>;
export type IArgumentAddress = IArgumentBase<'AddressStr'>;
export type IArgumentDateTime = IArgumentBase<'datetime'>;
export type IArgumentInteger = IArgumentBase<'int' | 'float'>;
export type IArgumentList = IArgumentBase<'list'>;
export type IArgumentObject = IArgumentBase<'dict'>;
export type IArgumentVideoUrl = IArgumentBase<'VideoUrl'>;
export type IArgumentVideoUrls = IArgumentBase<'list[VideoUrl]'>;
export type IArgumentCategories<T> = Record<string, T>;
export type IArgumentDisplayRegulator = IArgumentBoolean;

export type IArgumentDynamicCategories = {
    base_arg_md_id: number;
    jsonpath: string;
    label: string;
    group_by_arg_md_id: Nullable<number>;
};

export interface IArgumentMultiCategory<T extends string | ICategoryItem, multi = true>
    extends IArgumentBase<multi extends true ? 'list[str]' : 'str'> {
    group_by_label?: boolean;
    group_by: Nullable<string>;
    lazy?: boolean;
    regulator_md_id: Nullable<number>;
    search_md_id: Nullable<number>;
    categories: IArgumentCategories<T>;
}

export type IArgumentCategory = IArgumentMultiCategory<string, false>;

export type IArgument =
    | IArgumentBoolean
    | IArgumentUrl
    | IArgumentUrls
    | IArgumentVideoUrl
    | IArgumentVideoUrls
    | IArgumentImageUrl
    | IArgumentImageUrls
    | IArgumentString
    | IArgumentStrings
    | IArgumentDateTime
    | IArgumentCategory
    | IArgumentMultiCategory<string>
    | IArgumentMultiCategory<ICategoryItem>
    | IArgumentList
    | IArgumentInteger
    | IArgumentObject
    | IArgumentAddress
    | IArgumentEmailString
    | IArgumentEmailStrings;

export type IStringValueArgument =
    | IArgumentUrl
    | IArgumentVideoUrl
    | IArgumentImageUrl
    | IArgumentString
    | IArgumentEmailString
    | IArgumentDateTime
    | IArgumentList
    | IArgumentAddress;

interface IArgumentOptions {
    categories: Nullable<IArgumentCategories<string> | IArgumentCategories<ICategoryItem>>;
    dynamic_categories: Nullable<IArgumentDynamicCategories>;
    neitherable: boolean;
    group_by_label: boolean;
    regulator_md_id: Nullable<number>;
    search_md_id: Nullable<number>;
}

export interface IArgumentMetadata {
    id: number;
    title: string;
    description: string;
    name: string;
    extractor_name: string;
    is_list: boolean;
    options?: IArgumentOptions;
    unspecifiable: boolean;
    unknowable: boolean;
    dtype: string;
    arg_type: ArgType;
    scope: ArgScope;
    is_ticket_arg: boolean;
    params: Record<string, unknown>;
    dependencies: string[];
    additional_data: Record<string, unknown>;
    created_date: Date;
    updated_at?: Date;
    deleted_at?: Date;
}

export interface IArgumentUpdate {
    id: IArgument['id'];
    value: IArgument['value'];
    special_value?: 'reset' | 'unspecified' | 'unknown' | 'neither';
}

export interface IArgumentExtractorInfo {
    name: string;
    return_type: string;
    possible_values?: string[];
    ttl_minutes: number;
}

const dtypeOrdinal = (dtype: string) => {
    if (dtype === 'None') {
        return 4;
    } else if (dtype.match(/^list\[(.+)\]$/)) {
        return 3;
    } else if (dtype.match(/^dict\[.+\]$/)) {
        return 2;
    } else if (dtype[0] === dtype[0].toUpperCase()) {
        return 1;
    }
    return 0;
};

export const normalizeDtype = (dtype: IArgument['dtype']): IArgument['dtype'] =>
    dtype
        .replace(/\s/g, '')
        .split('|')
        .map((bit) => {
            const match = bit.match(/list\[(.+)\]/);
            if (match) {
                return `list[${normalizeDtype(match[1] as IArgument['dtype'])}]`;
            }
            return bit;
        })
        .sort((a, b) => {
            const aOrdinal = dtypeOrdinal(a),
                bOrdinal = dtypeOrdinal(b);
            if (aOrdinal !== bOrdinal) {
                return aOrdinal - bOrdinal;
            }
            return a >= b ? 1 : -1;
        })
        .join('|') as IArgument['dtype'];

export const isTextArgument = (arg: IArgument) =>
    ['TEXT_ARGUMENT', 'INFO_ARGUMENT', 'USER_INPUT'].includes(arg.arg_type);

export const isCategorical = (
    arg: IArgument,
): arg is IArgumentCategory | IArgumentMultiCategory<string> | IArgumentMultiCategory<ICategoryItem> => {
    return !!(arg as IArgumentCategory).categories;
};

export const isMultiCategorical = (
    arg: IArgument,
): arg is IArgumentMultiCategory<string> | IArgumentMultiCategory<ICategoryItem> => {
    return isCategorical(arg) && arg.is_list;
};

export const isCategoricalMetadata = (argMetadata: Pick<IArgumentMetadata, 'options'>) => {
    return !isEmpty(argMetadata.options?.categories) || !isEmpty(argMetadata.options?.dynamic_categories);
};

export const isNestedCategorical = (arg: IArgument): arg is IArgumentMultiCategory<ICategoryItem> => {
    return Boolean(isCategorical(arg) && arg.categories && typeof Object.values(arg.categories)[0] !== 'string');
};

const mediaDLists = mediaDTypes.map((type) => `list[${type}]` as const);
export const requiresInput = (arg: IArgument) => {
    if (mediaDLists.includes(arg.dtype) || mediaDTypes.includes(arg.dtype)) {
        return false;
    }

    // "No data" categorical case is considered truthy (TYM-2417)
    if (isCategorical(arg) && Object.keys(arg.categories).length === 0) {
        return false;
    }

    return (
        (arg.value === null && !arg.is_edited && !arg.special_value) ||
        arg.value === undefined ||
        arg.value === '' ||
        (Array.isArray(arg.value) && arg.value.length === 0)
    );
};

export const applyArgUpdates = (args: IArgument[], updates: IArgumentUpdate[]) =>
    args.map((arg) => {
        const update = updates.find((update) => update.id === arg.id);
        if (update) {
            return {
                ...arg,
                value: update.special_value ? null : update.value,
                is_edited: update.special_value !== 'reset',
                special_value: update.special_value ?? null,
            } as IArgument;
        }
        return arg;
    });

export const wereArgsChanged = (updated: IArgument[], original: IArgument[]) =>
    updated.find((updatedArg) => {
        const argB = original.find((originalArg) => originalArg.id === updatedArg.id);
        return !argB || updatedArg.value !== argB.value || updatedArg.special_value !== argB.special_value;
    });

export const sortArgs = (args: IArgument[]) => {
    return args.sort((a, b) => a.rank - b.rank || a.order - b.order || a.name.localeCompare(b.name));
};

const applyRankedVisibility = (args: IArgument[]) => {
    const firstFalsyArgIndex = args.findIndex((arg) => requiresInput(arg) && isTextArgument(arg));
    if (firstFalsyArgIndex === -1) return args;

    const firstFalsyArg = args[firstFalsyArgIndex];
    const nextRankStartsAt = findIndex(args, (arg) => arg.rank > firstFalsyArg.rank, firstFalsyArgIndex);
    if (nextRankStartsAt === -1) return args;

    return args.slice(0, nextRankStartsAt).concat(
        args.slice(nextRankStartsAt).filter(
            (arg) => !isTextArgument(arg) || mediaDTypes.includes(arg.dtype) || mediaDLists.includes(arg.dtype), // not editable
        ),
    );
};

export const getVisibleArgs = (args: IArgument[]) => {
    const groupingArgs = new Set(args.map((arg) => isNestedCategorical(arg) && arg.group_by).filter(Boolean));
    const searchArgs = new Set(args.map((arg) => isCategorical(arg) && arg.search_md_id));
    return applyRankedVisibility(args.filter((arg) => !groupingArgs.has(arg.name) && !searchArgs.has(arg.md_id)));
};

export interface ArgMetadataUsage {
    organization_id: number;
    organization_name: string;
    policy_set_id: number;
    intent_name: string;
    workflow_id: number;
    action_id: number;
    action_name: string;
    workflow_name: string;
}

export interface ArgMdActionUsageDetails extends ArgMetadataUsage {
    path: string;
}

export interface ArgMetadatadInUseDetails {
    policies: ArgMetadataUsage[];
    workflows: ArgMetadataUsage[];
    actions: ArgMdActionUsageDetails[];
    templates: ArgMetadataUsage[];
    dependent_arg_names: string[];
}
