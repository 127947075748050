import { Auth } from '@aws-amplify/auth';
import axios from 'axios';

export const AuthSignOut = () => {
    return Auth.signOut({ global: true });
};

export const attachPolicy = (identityId: string) => {
    return axios.post('/auth/attach-policy', { identity_id: identityId }).then((resp) => resp.data);
};

export const getServiceProviderSecrets = (keys: string[]) => {
    return axios
        .post('third-party/service-provider-secrets', {
            keys: keys,
        })
        .then((resp) => resp.data);
};
