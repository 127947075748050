import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';
import qs from 'qs';
import { ArgMetadatadInUseDetails } from '@tymely/atoms';
import { datadogLogs } from '@datadog/browser-logs';

import { ITemplateError } from './template.api';

export type ErrorCode =
    | 'NO_POLICY'
    | 'STALE_TICKET_UPDATE'
    | 'ARGUMENT_METADATA_IN_USE'
    | 'TEMPLATE_ERROR'
    | 'BAD_SCHEMA'
    | 'NON_EMPTY_ITEM_OVERWRITE';

export class ApiError<C extends ErrorCode = ErrorCode, D = unknown> extends AxiosError {
    override code: C;
    detail: D;

    constructor(code: C, message: string, detail: D) {
        super(message);
        this.message = message;
        this.code = code;
        this.detail = detail;
    }
}

export interface ISchemaError {
    detail: {
        msg: string;
        type: string;
        loc: (string | number)[];
    }[];
}

export class ArgumentUsedError extends ApiError<'ARGUMENT_METADATA_IN_USE', ArgMetadatadInUseDetails> {
    constructor(message: string, detail: ArgMetadatadInUseDetails) {
        super('ARGUMENT_METADATA_IN_USE', message, detail);
    }
}

export class NoPolicyError extends ApiError<'NO_POLICY', null> {
    constructor(message: string) {
        super('NO_POLICY', message, null);
    }
}

export class StaleTicketUpdate extends ApiError<'STALE_TICKET_UPDATE', null> {
    constructor(message: string) {
        super('STALE_TICKET_UPDATE', message, null);
    }
}

export class TemplateError extends ApiError<'TEMPLATE_ERROR', ITemplateError[]> {
    constructor(message: string, detail: ITemplateError[]) {
        super('TEMPLATE_ERROR', message, detail);
    }
}

export class SchemaError extends ApiError<'BAD_SCHEMA', ISchemaError> {
    constructor(message: string, detail: ISchemaError) {
        super('BAD_SCHEMA', message, detail);
    }
}

export class NonEmptyItemOverwrite extends ApiError<'NON_EMPTY_ITEM_OVERWRITE', null> {
    constructor(message: string) {
        super('NON_EMPTY_ITEM_OVERWRITE', message, null);
    }
}

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
const env = import.meta.env.VITE_DEPLOY_ENV;

axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, { indices: false });
};

axios.interceptors.request.use((request) => {
    datadogLogs.logger.info(`API request ${request.url}`, { request: request, env: env.toLowerCase() });
    return request;
});

axios.interceptors.response.use(
    (response) => {
        datadogLogs.logger.info(`API response`, { response: response, env: env.toLowerCase() });
        return response;
    },
    (error) => {
        datadogLogs.logger.info('API Error', error);
        // eslint-disable-next-line no-console
        console.log('error', error);
        if (error.response?.status === 422) {
            throw new SchemaError('Bad schema', error.response.data);
        }

        if (error.response?.status >= 400 && error.response?.data) {
            switch (error.response.data.code) {
                case 'NO_POLICY':
                    throw new NoPolicyError(error.response.data.detail);
                case 'STALE_TICKET_UPDATE':
                    throw new StaleTicketUpdate(error.response.data.detail);
                case 'ARGUMENT_METADATA_IN_USE':
                    throw new ArgumentUsedError('Argument metadata is being used', error.response.data.detail);
                case 'TEMPLATE_ERROR':
                    throw new TemplateError('Template error', error.response.data.detail);
                case 'NON_EMPTY_ITEM_OVERWRITE':
                    throw new NonEmptyItemOverwrite(error.response.data.detail);
                default:
                    throw new ApiError(error.response.data.code, String(error.response.data.detail), null);
            }
        }
        throw error;
    },
);

axios.interceptors.request.use(async function (config: InternalAxiosRequestConfig) {
    if (!('Authorization' in config.headers)) {
        config.headers.Authorization = await getJwtToken();
    }
    return config;
});

async function getJwtToken() {
    try {
        const currentSession = await Auth.currentSession();
        return currentSession.getIdToken().getJwtToken();
    } catch {
        return Promise.reject(() => window.location.reload());
    }
}
