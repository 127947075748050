const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
            <path
                d="M0.94261 1.27614L9.72773 10.0613"
                stroke="#6D73A6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.72773 1.28249L0.94261 10.0676"
                stroke="#6D73A6"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CloseIcon;
