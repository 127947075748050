import { useEffect, useCallback, useRef, useState, MutableRefObject, RefObject } from 'react';
import { useKeys as useKeysBase } from 'rooks';
import { useDebounce as useDebounceBase, usePrevious as usePreviousBase } from 'react-use';
import { useLocation, useNavigationType } from 'react-router-dom';

export const usePrevious = <T>(value: T) => {
    return usePreviousBase(value);
};

export const useNotify = () => {
    const promise = import('howler').then(({ Howl }) => {
        return new Howl({ src: ['/assets/ding.ogg'] });
    });
    let interval: number;
    const currentTitle = document.title;
    const stopNotify = () => {
        window.clearInterval(interval);
        document.title = currentTitle;
    };
    return useCallback((message: string) => {
        stopNotify();
        promise.then((sound) => {
            sound.play();
        });
        document.title = message;
        interval = window.setInterval(() => {
            if (document.title === currentTitle) {
                document.title = message;
            } else {
                document.title = currentTitle;
            }
        }, 500);
        return stopNotify;
    }, []);
};

export const useDebounce = <T>(value: T, delay = 500): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useDebounceBase(
        () => {
            setDebouncedValue(value);
        },
        delay,
        [value],
    );

    return debouncedValue;
};

export const useCounter = (): [number, () => void] => {
    const [state, setState] = useState(0);
    const increment = useCallback(() => {
        setState((v) => v + 1);
    }, [setState]);
    return [state, increment];
};

export const useHotKeyFocus = <T extends HTMLElement = HTMLElement>(keysList: string[]) => {
    const searchRef = useRef() as MutableRefObject<T>;
    useKeysBase(keysList, () => {
        searchRef.current.focus();
    });
    return searchRef;
};

export const useHistoryStack = () => {
    const [stack, setStack] = useState<string[]>([]);
    const { pathname } = useLocation();
    const type = useNavigationType();

    useEffect(() => {
        if (type === 'POP') {
            setStack((prev) => prev.slice(0, prev.length - 1));
        } else if (type === 'PUSH') {
            setStack((prev) => [...prev, pathname]);
        } else {
            setStack((prev) => [...prev.slice(0, prev.length - 1), pathname]);
        }
    }, [pathname, type]);

    return stack;
};

export const useKeys = (keys: string[], callback: () => void, opts?: { ref: RefObject<HTMLInputElement> }) => {
    useKeysBase(keys, callback, { target: opts?.ref });
};
