import { datadogLogs } from '@datadog/browser-logs';
import { Box, Typography, styled } from '@mui/material';
import { keyframes } from '@mui/system';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { routes } from '@tymely/config';
import {
    useCreateTicketCrumb,
    useOrganizationsQuery,
    useTicketCountQuery,
    useTicketOrganization,
} from '@tymely/services';
import { useEffect, useState } from 'react';
import { BaseModal } from '@tymely/components';
import { IDineshTicketOperations, IOrganization } from '@tymely/atoms';
import { sum } from 'lodash';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const AlertButton = styled((props: LoadingButtonProps) => {
    return (
        <LoadingButton loading={false} color="error" variant="contained" {...props}>
            {props.children}
        </LoadingButton>
    );
})(() => ({
    height: '100%',
    animation: `${blink} 1s linear infinite`,
}));

export const ChatAlertButton = () => {
    const { config: orgConfig } = useTicketOrganization();
    const allOrgs = useOrganizationsQuery();
    const ticketCountQuery = useTicketCountQuery();

    const ChatOrgById = (allOrgs.data ?? [])
        .filter((org) => org.config.chat?.enabled)
        .reduce<Record<IOrganization['id'], IOrganization>>((acc, org) => {
            acc[org.id] = org;
            return acc;
        }, {});

    const chatTicketCount = sum(
        (ticketCountQuery.data ?? []).filter((qi) => qi.org_id in ChatOrgById).map((qi) => qi.ticket_count),
    );
    const createTicketCrumb = useCreateTicketCrumb();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const url = `${routes.onlineTicket}?chat`;
    const openTicket = () => {
        createTicketCrumb(IDineshTicketOperations.USER_CLICKED_CHAT_ALERT);
        window.open(url, '_blank');
        setIsModalOpen(false);
    };

    const isChatTickets = chatTicketCount > 0;

    useEffect(() => {
        if (!orgConfig.chat?.enabled && isChatTickets) {
            setIsModalOpen(true);
            createTicketCrumb(IDineshTicketOperations.USER_SAW_CHAT_TICKET_ALERT, {
                orgIds: Object.keys(ChatOrgById),
            });
            datadogLogs.logger.info('Chat Alert modal is shown to agent', {
                orgIds: Object.keys(ChatOrgById),
                chatTicketCount: chatTicketCount,
            });
        }
    }, [isChatTickets]);

    return (
        <>
            {isChatTickets ? <AlertButton onClick={openTicket}>Chat ({chatTicketCount})</AlertButton> : null}
            <BaseModal
                open={isModalOpen}
                okLabel="Open Ticket"
                closeLabel="Close Notification"
                onClose={() => setIsModalOpen(false)}
                onOk={openTicket}
            >
                <Box>
                    <Typography>A chat ticket is waiting in the queue and should be handled ASAP.</Typography>
                </Box>
            </BaseModal>
        </>
    );
};
