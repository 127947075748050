import { ReactNode } from 'react';
import { AlertProps } from '@mui/material';
import { atom } from 'recoil';

export interface IAlert {
    id: string;
    message: ReactNode;
    title?: string;
    severity?: AlertProps['severity'];
}

export const alertsAtom = atom<IAlert[]>({
    key: 'alerts',
    default: [],
});
