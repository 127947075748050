import React from 'react';
import { PubSub } from 'aws-amplify';
import { ZenObservable } from 'zen-observable-ts';

type DefaultSubscriptioValue = string | Record<string, unknown>;
type UnsubscribeCallback = ZenObservable.Subscription | undefined;

const createSubscription = <T = DefaultSubscriptioValue>(
    channel: string,
    callback: (value: T, channel: string) => void,
    complete?: () => void,
): UnsubscribeCallback => {
    if (!channel || channel.includes('undefined')) {
        return;
    }
    const subscription = PubSub.subscribe(channel).subscribe({
        next: (data) => callback(data.value as T, channel),
        complete,
    });
    return subscription;
};

export const useSubscription = <T = DefaultSubscriptioValue>(
    channel: string,
    callback: (value: T) => void,
    complete?: () => void,
    deps: React.DependencyList = [],
) => {
    const subscriptionRef = React.useRef<UnsubscribeCallback>();

    React.useEffect(() => {
        subscriptionRef.current = createSubscription(channel, callback, complete);
        return () => {
            subscriptionRef.current?.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channel, ...deps]);

    React.useEffect(() => () => subscriptionRef.current?.unsubscribe(), []);
};

export const useMultiSubscriptions = <T = DefaultSubscriptioValue>(
    channels: string[],
    callback: (value: T, channel: string) => void,
    deps: React.DependencyList = [],
) => {
    const channelsRef = React.useRef(new Map<string, UnsubscribeCallback>());

    React.useEffect(() => {
        channels.forEach((channel) => {
            const subscription = channelsRef.current.get(channel);
            if (!subscription || subscription.closed) {
                channelsRef.current.set(channel, createSubscription(channel, callback));
            }
        });

        channelsRef.current.forEach((subscription, channel) => {
            if (!channels.includes(channel)) {
                subscription?.unsubscribe();
                channelsRef.current.delete(channel);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channels, ...deps]);

    React.useEffect(
        () => () => {
            channelsRef.current.forEach((subscription) => subscription?.unsubscribe());
            channelsRef.current.clear();
        },
        [],
    );
};

export interface IEvent<T = Record<string, unknown>> {
    object_type: string;
    object_id: number | null;
    action: string | null;
    data: T;
}

export type UseEventSubscriptionArgs = {
    channel: string;
    objectTypes: string[];
};

export const useEventSubscription = <T = Record<string, unknown>>(
    { channel, objectTypes }: UseEventSubscriptionArgs,
    callback: (value: IEvent<T>) => void,
    complete?: () => void,
    deps: React.DependencyList = [],
) =>
    useSubscription<IEvent<T>>(
        channel,
        (value) => {
            if (value && typeof value === 'object' && objectTypes.includes(value.object_type)) {
                callback(value);
            }
        },
        complete,
        deps,
    );

type PersistentEvent = Record<string, unknown> & { event: string; interval?: number };

export const usePersistentEvent = <T extends PersistentEvent = PersistentEvent>(
    channel: string,
    { interval = 5000, ...data }: T,
    deps: React.DependencyList = [],
    enabled = true,
) => {
    const intervalRef = React.useRef<NodeJS.Timer>();

    const stop = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    React.useEffect(() => {
        if (!enabled) return;
        if (!channel || channel.includes('undefined')) {
            return;
        }
        intervalRef.current = setInterval(() => PubSub.publish(channel, data), interval);
        return stop;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channel, interval, ...deps]);

    React.useEffect(() => stop, []);
};
