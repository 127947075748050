import { ReactNode } from 'react';
import { styled, Avatar as AvatarBase, Box, BoxProps, useTheme, Tooltip } from '@mui/material';

export const getInitials = (name?: string) => {
    return name
        ?.split(' ')
        .filter(Boolean)
        .slice(0, 2)
        .map(([c]) => c.toUpperCase())
        .join('');
};

export const Avatar = styled(
    ({
        username = '',
        size = 'medium',
        ...props
    }: BoxProps & { username?: ReactNode; size?: 'small' | 'medium' | 'large' }) => {
        const theme = useTheme();
        const initials =
            typeof username === 'string' ? (username?.length > 2 ? getInitials(username) : (username ?? '')) : username;
        const sizes = { small: theme.spacing(3), medium: theme.spacing(4), large: theme.spacing(5) };
        const fonts = {
            small: theme.typography.fontSmall,
            medium: theme.typography.fontMedium,
            large: theme.typography.fontSize,
        };
        const sx = { width: sizes[size], minWidth: sizes[size], height: sizes[size], fontSize: fonts[size] };
        return (
            <Tooltip disableHoverListener={typeof username !== 'string'} title={username}>
                {initials ? (
                    <Box {...props} sx={sx}>
                        {initials}
                    </Box>
                ) : (
                    <AvatarBase sx={sx} className={props.className} />
                )}
            </Tooltip>
        );
    },
    { shouldForwardProp: (prop) => prop !== 'customer' },
)<{ customer?: boolean; selected?: boolean }>(
    ({ theme, customer, selected }) => `
  border-radius: 50%;
  background: ${
      selected ? theme.palette.common.white : customer ? theme.palette.secondary.light : theme.palette.primary.main
  };
  color: ${selected ? '#6F87C4' : theme.palette.common.white};
  display: flex;
  align-items: center;
  justify-content: center;
`,
);
