import React from 'react';
import { Box, styled } from '@mui/material';
import { useHoverDirty } from 'react-use';

import { CopyToClipboardButton } from '../CopyToClipboardButton';

type CopyCellProps = { text: string };

const _Root = styled(Box)`
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-weight: bold;
`;

const CopyCell = ({ text }: CopyCellProps) => {
    const ref = React.useRef(null);
    const isHovering = useHoverDirty(ref);

    return (
        <_Root ref={ref}>
            <Box mr={1}>{text}</Box>
            <CopyToClipboardButton text={text} size="small" hide={!isHovering} />
        </_Root>
    );
};

export default CopyCell;
