import React from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useIsMutating } from 'react-query';

import { useArgumentsTabsContext } from './ArgumentsTabsProvider';

const _CalculatePolicyButton = styled(LoadingButton)`
    margin-top: ${({ theme }) => theme.spacing(2)};
`;

const CalculatePolicyButton = (props: LoadingButtonProps) => {
    const { onEvaluatePolicy } = useArgumentsTabsContext();
    const analyzing = useIsMutating('evaluatePolicy') > 0;

    return (
        <_CalculatePolicyButton
            loading={analyzing}
            disabled={analyzing}
            color="success"
            loadingPosition="start"
            startIcon={<RefreshIcon />}
            variant="contained"
            {...props}
            onClick={onEvaluatePolicy}
        >
            Calculate Policy
        </_CalculatePolicyButton>
    );
};

export default CalculatePolicyButton;
