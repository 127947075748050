import { Box, BoxProps, styled, ImageListItem } from '@mui/material';
import React from 'react';
import { VideoPlayer } from '@tymely/components';
import { getSignedUrl } from '@tymely/api';

import PDFPreview from './PDFPreview';

import('react-photo-view');

const PhotoProvider = React.lazy(() =>
    import('react-photo-view').then((module) => ({ default: module.PhotoProvider })),
);
const PhotoView = React.lazy(() => import('react-photo-view').then((module) => ({ default: module.PhotoView })));

const _Root = styled(Box)`
    gap: ${({ theme }) => theme.spacing(1)};
    padding: 0;
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

type AttachmentsProps = BoxProps & { attachments: string[] };

const Attachments = ({ attachments, ...rest }: AttachmentsProps) => {
    const [fetchedAttachments, setFetchedAttachments] = React.useState<string[]>([]);

    React.useEffect(() => {
        const fetchAttachments = async () => {
            const signedUrls = await Promise.all(attachments.map((url) => getSignedUrl(url)));
            setFetchedAttachments(signedUrls);
        };
        fetchAttachments();
    }, []);

    return (
        <_Root component="ul" m={0} onClick={(e) => e.stopPropagation()} {...rest}>
            <React.Suspense>
                <PhotoProvider>
                    {fetchedAttachments.map((url, index) => (
                        <ImageListItem key={`${url}${index}`} sx={{ cursor: 'pointer', position: 'relative' }}>
                            {url.toLowerCase().includes('.pdf') ? (
                                <PDFPreview url={url} />
                            ) : url.toLowerCase().includes('.txt') ? (
                                <a href={url} target="_blank" rel="noreferrer">
                                    {url}
                                </a>
                            ) : url.toLowerCase().includes('.mov') || url.toLowerCase().includes('.mp4') ? (
                                <VideoPlayer url={url} />
                            ) : (
                                <PhotoView src={url}>
                                    <img src={url} loading="lazy" alt="attachment" />
                                </PhotoView>
                            )}
                        </ImageListItem>
                    ))}
                </PhotoProvider>
            </React.Suspense>
        </_Root>
    );
};

export default Attachments;
