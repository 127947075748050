import { useMemo, useState } from 'react';
import { OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import { Key } from 'ts-key-enum';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { useHotKeyFocus } from '@tymely/utils';
import { useCreateIntentMutation, useIntentExists, useSelectedComment, useTagComment } from '@tymely/services';

import { AlertDialog } from '../AlertDialog';

export const SearchAddIntent = (props: {
    disabled: boolean;
    setLoading: (busy: boolean) => void;
    search: string;
    onAdd: () => void;
    onSearch: (value: string) => void;
}) => {
    const searchRef = useHotKeyFocus([Key.Control, Key.Alt, 'KeyI']);
    const intentExists = useIntentExists();
    const selectedComment = useSelectedComment();
    const tagComment = useTagComment();
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const createIntentMutation = useCreateIntentMutation(async (intent) => {
        if (selectedComment) {
            await tagComment(selectedComment.id, [intent.id]);
            props.setLoading(false);
        }
    });

    const addButtonDisabled = useMemo(() => {
        return props.search.trim().length < 3 || intentExists(props.search) || props.disabled;
    }, [props.search]);

    const addIntent = async () => {
        props.setLoading(true);
        createIntentMutation.mutate(props.search);
        props.onAdd();
    };

    return (
        <>
            <OutlinedInput
                size="small"
                value={props.search}
                onChange={(e) => props.onSearch(e.currentTarget.value)}
                disabled={props.disabled}
                placeholder="Search intent..."
                inputRef={searchRef}
                fullWidth
                sx={{ borderRadius: '5px' }}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            id="intent-add-icon-btn"
                            disabled={addButtonDisabled}
                            onClick={() => setOpenAlertDialog(true)}
                            size="small"
                        >
                            <AddIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
            <AlertDialog
                title="Add intent"
                description={props.search}
                open={openAlertDialog}
                onClose={() => setOpenAlertDialog(false)}
                onAgree={() => addIntent()}
            />
        </>
    );
};
