import { useQuery, useMutation, useQueryClient } from 'react-query';
import { JSONSchema7 } from 'json-schema';
import { IActionMetadata } from '@tymely/atoms';
import {
    listActionsMetadata,
    editActionMetadata,
    createActionMetadata,
    getArgsInActions,
    getExecutorSchema,
} from '@tymely/api';
import { AxiosError } from 'axios';

import { useSetAlert } from './alerts.services';

const ACTIONS_METADATA_QUERY_KEY = 'actionsMetadata';
const EXECUTOR_SCHEMA_QUERY_KEY = 'executorSchema';

export const useActionsMetadataQuery = (onSuccess?: (actionsMetadata: IActionMetadata[]) => void) => {
    const setAlert = useSetAlert();

    return useQuery<IActionMetadata[]>(ACTIONS_METADATA_QUERY_KEY, listActionsMetadata, {
        onSuccess,
        onError: () => {
            setAlert('failed fetching actions metadata', 'error');
        },
    });
};

export const useActionMetadataExecutorSchema = (
    metadataId: IActionMetadata['id'],
    onSuccess?: (executorSchema: JSONSchema7) => void,
) => {
    const setAlert = useSetAlert();

    return useQuery<JSONSchema7>(
        [ACTIONS_METADATA_QUERY_KEY, metadataId, EXECUTOR_SCHEMA_QUERY_KEY],
        () => getExecutorSchema(metadataId),
        {
            onSuccess,
            onError: () => {
                setAlert('failed fetching action metadata executor schema', 'error');
            },
        },
    );
};

export const useEditActionMetadataMutation = () => {
    const queryClient = useQueryClient();
    return useMutation<IActionMetadata, AxiosError, IActionMetadata>(editActionMetadata, {
        onSuccess: (data) => {
            queryClient.setQueriesData<IActionMetadata[] | undefined>(
                ACTIONS_METADATA_QUERY_KEY,
                (cache?: IActionMetadata[]) => cache?.map((item) => (item.id === data.id ? data : item)),
            );
        },
    });
};

export const useCreateActionMetadataMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<IActionMetadata, AxiosError, { metadata: Omit<IActionMetadata, 'id'> }>(
        (params) => createActionMetadata(params.metadata),
        {
            onSuccess: (data) => {
                queryClient.setQueriesData(ACTIONS_METADATA_QUERY_KEY, (cache?: IActionMetadata[]) =>
                    cache ? cache.concat(data) : [data],
                );
            },
        },
    );
};

export const useArgsInActionsQuery = (
    onSuccess?: (usage: ReturnType<typeof getArgsInActions> extends Promise<infer T> ? T : never) => void,
    onError?: (error: AxiosError) => void,
) => {
    return useQuery('args-in-actions', getArgsInActions, {
        onSuccess,
        onError,
    });
};
