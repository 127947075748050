import { Box, CircularProgress, CircularProgressProps, styled } from '@mui/material';
import { ComponentProps } from 'react';

const Root = styled(Box)({
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
});

export const Loader = (props: ComponentProps<typeof Box> & { size?: CircularProgressProps['size'] }) => (
    <Root {...props}>
        <CircularProgress id="loader-circular-progress" size={props.size} />
    </Root>
);
