import React from 'react';
import { styled } from '@mui/material';
import { RenderRowProps, Row } from 'react-data-grid';

import useDnDEvents from './useDnDEvents';

type BaseRowProps<T, S> = RenderRowProps<T, S> & { isOver?: -1 | 0 | 1 };

const BaseRow = <T, S>(props: BaseRowProps<T, S>) => <Row {...props} />;

const _Row = styled(BaseRow, { shouldForwardProp: (prop: string) => !['isOver'].includes(prop) })`
    & .rdg-cell {
        border-top: ${({ isOver, theme }) => (isOver === -1 ? `2px solid ${theme.palette.grey.A400}` : 'none')};
        border-bottom: ${({ isOver, theme }) => (isOver === 1 ? `2px solid ${theme.palette.grey.A400}` : 'none')};
        background-color: ${({ isOver, theme, isRowSelected }) =>
            isOver || isRowSelected ? theme.palette.grey.A200 : 'none'};
    }
` as typeof BaseRow;

type DataGridRowProps<T, S> = RenderRowProps<T, S> & {
    onRowReorder?: (sourceIndex: number, targetIndex: number) => void;
};

const DataGridRow = <R, SR>({ rowIdx, onRowReorder, ...props }: DataGridRowProps<R, SR>) => {
    const { isOver, onDrop, onDragLeave, onDragOver } = useDnDEvents({
        droppable: true,
        type: 'row',
        dropInd: rowIdx,
        onDrop: onRowReorder,
    });
    return (
        <_Row
            rowIdx={rowIdx}
            onDrop={onDrop}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            isOver={isOver}
            {...props}
        />
    );
};

export default DataGridRow;
