import { Alert, AlertTitle, Box, Collapse, styled } from '@mui/material';
import { IAlert } from '@tymely/atoms';
import { useDismissAlert, useAlerts } from '@tymely/services';
import { useCallback, useState } from 'react';

const Root = styled(Box)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    zIndex: 10,
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const _Message = styled(Box)`
    white-space: pre-line;
`;

const animationTime = 300;

interface AnimatedAlertProps {
    alert: IAlert;
    onClose: (id: string) => void;
}
const AnimatedAlert = ({ alert, onClose }: AnimatedAlertProps) => {
    const [show, setShow] = useState(true);

    const closeAlert = useCallback(
        (alertId: string) => {
            setShow(false);
            setTimeout(() => onClose(alertId), animationTime);
        },
        [onClose],
    );

    return (
        <Collapse in={show} timeout={{ enter: animationTime, exit: animationTime }}>
            <StyledAlert
                id={`styled-alert-message-${alert.id}`}
                severity={alert.severity || 'info'}
                onClose={() => closeAlert(alert.id)}
                elevation={4}
            >
                {alert.title && (
                    <AlertTitle>
                        <strong>{alert.title}</strong>
                    </AlertTitle>
                )}
                <_Message>{alert.message}</_Message>
            </StyledAlert>
        </Collapse>
    );
};

export const AlertsBar = () => {
    const alerts = useAlerts();
    const dismissAlert = useDismissAlert();

    return (
        <Root hidden={alerts.length === 0}>
            {alerts.map((alert) => (
                <AnimatedAlert key={alert.id} alert={alert} onClose={(id) => dismissAlert(id)} />
            ))}
        </Root>
    );
};
