import { Box, ListItem, ListItemText, styled, Typography, TypographyProps } from '@mui/material';

const _CommentDate = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.secondary};
`;

export const CommentDate = (props: TypographyProps) => <_CommentDate variant="caption" {...props} />;

export const BottomBar = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row-reverse;
    height: ${({ theme }) => theme.spacing(3)};
`;

type CommentContentProps = {
    isCustomer: boolean;
    isSelected: boolean;
    isInternal: boolean;
    hasIntent: boolean;
};

export const CommentContent = styled(ListItemText, {
    shouldForwardProp: (prop: string) => !['isCustomer', 'isSelected', 'isInternal', 'hasIntent'].includes(prop),
})<CommentContentProps>`
    position: relative;
    max-width: 85%;
    padding: ${({ theme }) => theme.spacing(1)};
    margin-top: ${({ theme, hasIntent }) => theme.spacing(hasIntent ? 3 : 1)};
    border-radius: ${({ theme }) => theme.shape.borderRadius};
    color: ${({ theme }) => theme.palette.text.secondary};
    ${({ isCustomer, theme, isInternal }) =>
        !isCustomer &&
        `
        background-color: ${isInternal ? `alpha(${theme}.palette.warning.light, 0.55)` : theme.palette.primary.light};
    `};
    ${({ isCustomer, theme, isSelected }) =>
        isCustomer &&
        `
        background-color: ${isSelected ? theme.palette.grey[300] : theme.palette.grey[100]};
        ${isSelected && 'cursor: pointer;'};
    `};
    transition: background-color 1s ease-out;
    & .MuiListItemText-primary {
        color: ${({ isCustomer, theme }) =>
            isCustomer ? theme.palette.warning.light : theme.palette.getContrastText(theme.palette.primary.light)};
        font-weight: bold;
    }
    & .MuiListItemText-secondary {
        overflow-wrap: anywhere;
        line-height: 1.25;
    }
    & blockquote {
        margin-block: 0;
        margin-inline: 0;
    }
    & img {
        max-height: 100%;
        max-width: 100%;
    }
    & pre {
        white-space: break-spaces;
    }
`;

export const CommentContainer = styled(ListItem, {
    shouldForwardProp: (prop) => prop !== 'isCustomer',
})<{ isCustomer: boolean }>`
    padding: ${({ theme }) => theme.spacing(0)};
    ${({ isCustomer }) => !isCustomer && 'justify-content: right;'};
`;
