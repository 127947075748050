import { Link, styled, Tooltip } from '@mui/material';
import { IArgumentUrl, IArgumentUrls } from '@tymely/atoms';

import { ArgumentFieldProps } from './Layout';

const StyledLink = styled(Link)(({ theme }) => ({
    width: theme.spacing(28),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}));

export const UrlArgument = (props: ArgumentFieldProps<IArgumentUrl | IArgumentUrls>) => (
    <>
        {[props.argument.value]
            .flat()
            .filter(Boolean)
            .map((value) => (
                <Tooltip key={value} title={props.argument.title} enterDelay={1000} followCursor>
                    <StyledLink href={value || ''} target="_blank" variant="body2">
                        {value}
                    </StyledLink>
                </Tooltip>
            ))}
    </>
);
