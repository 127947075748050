import React from 'react';
import { ITemplateVariable, TemplateError } from '@tymely/api';
import { useArgumentsQuery, useRenderTemplateQuery, useSetAlert, useTicketOfflineQuery } from '@tymely/services';
import { Option } from '@tymely/components';
import { Nullable } from '@global/types';
import { useDebounce } from '@tymely/utils';
import { isCategorical } from '@tymely/atoms';

type UseCreateTemplateEditorArgs = {
    templateId?: string;
    template?: string;
    diff?: Diff.Change[];
    sanitize?: boolean;
    error?: TemplateError;
    onChange?: (template: string, varNames: string[]) => void;
};

const useCreateTemplateEditor = ({ template, sanitize, error, onChange, ...rest }: UseCreateTemplateEditorArgs) => {
    const [variables, setVariables] = React.useState<Record<string, ITemplateVariable>>({});
    const [comment, setComment] = React.useState<Nullable<Option<number>[]>>([]);
    const [ticketId, setTicketId] = React.useState<number>();
    const setAlert = useSetAlert();

    React.useEffect(() => {
        const unloadCallback = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = '';
            return '';
        };

        window.addEventListener('beforeunload', unloadCallback);
        return () => window.removeEventListener('beforeunload', unloadCallback);
    }, []);

    const ticketOfflineQuery = useTicketOfflineQuery(ticketId, {
        enabled: !!ticketId,
    });

    const argumentsQuery = useArgumentsQuery({
        commentId: comment ? comment[0]?.value : undefined,
        argsVersion: undefined,
        onError: (err) => setAlert(err.message, 'error'),
    });

    const argsVariables = React.useMemo(() => {
        if (!argumentsQuery.data) {
            return variables;
        }
        return argumentsQuery.data.reduce(
            (vars, arg) => {
                vars[arg.name] = {
                    ...arg,
                    ...vars[arg.name],
                    categories: isCategorical(arg) ? arg.categories : undefined,
                    value: vars[arg.name]?.value || arg.value,
                };
                return vars;
            },
            { ...variables },
        );
    }, [variables, argumentsQuery.data]);

    const templateToRender = useDebounce(template, 300) || '';
    const renderTemplateQuery = useRenderTemplateQuery(templateToRender, argsVariables, sanitize, {
        retry: false,
        enabled: Boolean(templateToRender) && !argumentsQuery.isLoading && !ticketOfflineQuery.isLoading,
    });

    const templateErrors = React.useMemo(() => {
        if (renderTemplateQuery.error instanceof TemplateError) {
            return renderTemplateQuery.error.detail;
        }
        if (error) {
            return error.detail;
        }
        return [];
    }, [error, renderTemplateQuery.error]);

    const onCodeChange = (code: string) => {
        onChange?.(code, Object.keys(argsVariables));
    };

    React.useEffect(() => {
        if (template) {
            // Trigger onChange when variables fetched (after template changed)
            onCodeChange(template);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [argsVariables, template]);

    return {
        ticketOfflineQuery,
        argumentsQuery,
        renderTemplateQuery,
        ticketId,
        templateErrors,
        setTicketId,
        variables,
        argsVariables,
        comment,
        setComment,
        setVariables,
        onCodeChange,
        template,
        ...rest,
    };
};

type TemplateEditorContext = ReturnType<typeof useCreateTemplateEditor>;

const editorContext = React.createContext<TemplateEditorContext | undefined>(undefined);

export type TemplateEditorProviderProps = React.PropsWithChildren<UseCreateTemplateEditorArgs>;

const TemplateEditorProvider = ({ children, ...rest }: TemplateEditorProviderProps) => {
    const value = useCreateTemplateEditor(rest);
    return <editorContext.Provider value={value}>{children}</editorContext.Provider>;
};

export const useTemplateEditor = () => {
    const context = React.useContext(editorContext);
    if (!context) {
        throw Error('`useTemplateEditor` must be used only inside `TemplateEditorProvider`');
    }
    return context;
};

export default TemplateEditorProvider;
