import { matchSorter } from 'match-sorter';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { Autocomplete, Box, Skeleton, styled, SxProps, TextField, TextFieldVariants, useTheme } from '@mui/material';

type QueryBasedOption = { id: number; name: string };

type QueryBasedSelectorProps<T extends QueryBasedOption> = {
    label?: string;
    loading?: boolean;
    error?: boolean;
    data: T[];
    required?: boolean;
    selectObj: (obj?: T) => void;
    selectedObjId?: T['id'];
    getOptionLabel?: (obj: T) => string;
    filter?: (obj: T) => boolean;
    sort?: (obj1: T, obj2: T) => number;
    disabled?: boolean;
    minWidth?: string;
    sx?: SxProps;
    className?: string;
    color?: string;
    variant?: TextFieldVariants;
};

const _SelectorSkeleton = styled(Skeleton)`
    width: 100%;
    height: ${({ theme }) => theme.spacing(5)};
    border-radius: ${({ theme }) => theme.spacing(0.3)};
    margin-bottom: -8px;
`;

const _TextField = styled(TextField, { shouldForwardProp: (prop: string) => !['inputColor'].includes(prop) })<{
    inputColor?: string;
}>`
    ${({ inputColor }) =>
        inputColor &&
        `.MuiInputBase-input {
    color: ${inputColor};
    }`};
`;

export function QueryBasedSelector<T extends QueryBasedOption = QueryBasedOption>(props: QueryBasedSelectorProps<T>) {
    const theme = useTheme();
    const getOptionLabel = props.getOptionLabel ?? ((option) => option.name);

    const data = useMemo(
        () => (props.filter ? props.data?.filter(props.filter) : (props.data ?? [])),
        [props.data, props.filter],
    );

    const selectedObj = useMemo(() => {
        return data?.find((org) => org.id === props.selectedObjId) ?? null;
    }, [data, props.selectedObjId]);

    const onChange = useCallback(
        (_: SyntheticEvent, newValue: T | null) => {
            if (newValue || !props.required) {
                props.selectObj(newValue || undefined);
            }
        },
        [props.selectObj],
    );

    return (
        <Box
            sx={{
                width: theme.spacing(30),
                height: theme.spacing(4),
                ...props.sx,
            }}
        >
            {props.loading && <_SelectorSkeleton variant="rectangular" animation="wave" height="100%" />}
            {!props.loading && (
                <Autocomplete
                    fullWidth
                    autoHighlight
                    disablePortal
                    size="small"
                    disabled={props.disabled ?? props.error}
                    value={selectedObj}
                    options={data}
                    filterOptions={(options, state) =>
                        matchSorter(options, state.inputValue, {
                            keys: [getOptionLabel],
                        })
                    }
                    renderInput={(params) => {
                        return (
                            <_TextField
                                {...params}
                                variant={props.variant ?? 'standard'}
                                color="secondary"
                                placeholder={props.error ? 'Error' : props.label}
                                inputProps={params.inputProps}
                                inputColor={props.color}
                            />
                        );
                    }}
                    getOptionLabel={getOptionLabel}
                    onChange={onChange}
                />
            )}
        </Box>
    );
}
