import axios from 'axios';

export const createFeedbackReport = (send_to: string[]) =>
    axios.post('reports/agents-feedback?async=true', { send_to });

export interface GetReportCsvDownloadUrlResponse {
    url: string;
}

export const fetchReportCsvDownloadUrl = async (reportId: string) => {
    const response = await axios.get<GetReportCsvDownloadUrlResponse>(`report/${reportId}/csv-download-url`);
    return response.data.url;
};

export const fetchReportFullState = async <T>(reportId: string) => (await axios.get<T>(`report/${reportId}`)).data;

export const updateReportState = async <T>(reportId: string, newState: T) => {
    return await axios.put<T>(`report/${reportId}/state`, newState);
};

export const signS3Url = async (url: string) => (await axios.post('s3/sign-url', { url })).data;
