import { Box, BoxProps, ButtonBase, styled, Typography } from '@mui/material';
import { IComment } from '@tymely/atoms';
import { useFindIntent, useTicketOrganization } from '@tymely/services';
import React from 'react';
import { Avatar } from '@tymely/components/Avatar';
import DeselectIcon from '@mui/icons-material/Deselect';
import TyIcon from '@tymely/components/Icons/Ty';
import CopyToClipboard from 'react-copy-to-clipboard';

import CommentBubble from './CommentBubble';
import { formatBubbleDate } from './utils';
import { DatetimeTypo } from './styled';
import DemoCommentBubble from './DemoComment';

const _DatetimeTypoContainer = styled(Box)`
    display: flex;
    align-items: flex-start;
    padding: ${({ theme }) => theme.spacing(0, 2)};
`;

const _DatetimeTypo = styled(DatetimeTypo)`
    position: sticky;
    top: ${({ theme }) => theme.spacing(4)};
`;

const _Avatar = styled(Avatar)`
    margin-right: ${({ customer, theme }) => (customer ? theme.spacing(2.5) : 0)};
    margin-left: ${({ customer, theme }) => (customer ? 0 : theme.spacing(2.5))};
`;

const _Root = styled(Box, { shouldForwardProp: (prop: string) => !['isCustomer'].includes(prop) })<{
    isCustomer: boolean;
}>`
    display: flex;
    flex-direction: ${({ isCustomer }) => (isCustomer ? 'row' : 'row-reverse')};
    align-self: ${({ isCustomer }) => (isCustomer ? 'flex-start' : 'flex-end')};
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    &:first-of-type {
        margin-top: ${({ theme }) => theme.spacing(2)};
    }
`;

const _HighlightedCommentContainer = styled(Box, {
    shouldForwardProp: (prop: string) => !['isCustomer'].includes(prop),
})<{
    isCustomer: boolean;
}>`
    display: flex;
    flex-direction: ${({ isCustomer }) => (isCustomer ? 'row' : 'row-reverse')};
`;

const CommentContainer = styled(Box, {
    shouldForwardProp: (prop: string) => !['isCustomer'].includes(prop),
})<{
    isCustomer: boolean;
}>`
    display: flex;
    width: 100%;
    flex-direction: ${({ isCustomer }) => (isCustomer ? 'row' : 'row-reverse')};
    position: relative;
`;

const _DeselectIconContainer = styled(Box)`
    position: absolute;
    left: -50px;
    top: 50px;
    cursor: pointer;
`;

type CommentProps = Omit<BoxProps, 'onSelect'> & {
    comment: IComment;
    highlightText?: string;
    selected?: boolean;
    focusedOn?: boolean;
    history?: boolean;
    title?: string;
    onSelect?: (commentId: IComment['id']) => void;
    onDeselect?: () => void;
    onUntag?: (comment: IComment) => void;
    preciseTimestamp?: boolean;
};

const _CommentBubble = styled(CommentBubble)`
    max-width: 80%;
`;

const _Details = styled(Box)`
    flex: 1;
`;

const Comment = ({
    comment,
    selected,
    focusedOn,
    onUntag,
    preciseTimestamp,
    highlightText,
    title,
    history,
    onSelect,
    onDeselect,
    ...rest
}: CommentProps) => {
    const intent = useFindIntent(comment.selected_intent_id);
    const { config } = useTicketOrganization();
    const needHighlight = Boolean(comment.additional_data?.['highlights']);

    return (
        <_Root
            {...rest}
            id={String(comment.id)}
            isCustomer={comment.is_customer}
            onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                comment.is_customer && !selected && onSelect?.(comment.id);
            }}
        >
            <_Avatar
                size="large"
                username={comment.is_customer ? comment.from_name : <TyIcon />}
                customer={comment.is_customer}
            />
            <_Details>
                <Box
                    sx={{
                        mb: 0.5,
                        gap: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: comment.is_customer ? 'row' : 'row-reverse',
                    }}
                >
                    {comment.from_name && (
                        <Typography
                            variant="subtitle2"
                            color="secondary"
                            sx={{
                                textAlign: comment.is_customer ? 'left' : 'right',
                            }}
                        >
                            {comment.is_automated_response ? 'AUTOMATED' : comment.from_name}
                        </Typography>
                    )}
                    <ButtonBase centerRipple sx={{ p: 0 }}>
                        <CopyToClipboard text={String(comment.id)}>
                            <Typography
                                variant="subtitle2"
                                color="secondary"
                                sx={{
                                    opacity: 0.66,
                                    cursor: 'pointer',
                                }}
                            >
                                #{comment.id}
                            </Typography>
                        </CopyToClipboard>
                    </ButtonBase>
                </Box>
                {needHighlight && (
                    <React.Suspense>
                        <_HighlightedCommentContainer isCustomer={comment.is_customer}>
                            <DemoCommentBubble
                                comment={comment}
                                intent={intent}
                                selected={selected}
                                focusedOn={focusedOn}
                                onUntag={onUntag}
                            />
                            <_DatetimeTypoContainer>
                                <DatetimeTypo>
                                    {formatBubbleDate(comment.inquiry_date, false, preciseTimestamp)}
                                </DatetimeTypo>
                            </_DatetimeTypoContainer>
                        </_HighlightedCommentContainer>
                    </React.Suspense>
                )}
                {!needHighlight && (
                    <CommentContainer isCustomer={comment.is_customer}>
                        <_CommentBubble
                            comment={comment}
                            highlightText={highlightText}
                            intent={intent}
                            title={title}
                            selected={selected}
                            live={config.chat?.enabled}
                            focusedOn={focusedOn}
                            withDate={history}
                            onUntag={onUntag}
                        />
                        <_DatetimeTypoContainer>
                            <_DatetimeTypo>
                                {formatBubbleDate(comment.inquiry_date, false, preciseTimestamp)}
                            </_DatetimeTypo>
                        </_DatetimeTypoContainer>
                        {focusedOn && (
                            <_DeselectIconContainer onClick={() => onDeselect?.()}>
                                <DeselectIcon />
                            </_DeselectIconContainer>
                        )}
                    </CommentContainer>
                )}
            </_Details>
        </_Root>
    );
};

export default Comment;
