import React, { useEffect, useMemo, useState, ReactElement, ReactNode } from 'react';
import { Box, styled } from '@mui/material';
import { EditorProps, EditorState, convertToRaw, Modifier } from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import { sanitizeHtml } from '@tymely/utils';
import Editor from '@draft-js-plugins/editor';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createInlineToolbarPlugin, { Separator } from '@draft-js-plugins/inline-toolbar';
import createImagePlugin from '@draft-js-plugins/image';
import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
    DraftJsStyleButtonProps,
} from '@draft-js-plugins/buttons';
import draftToHtml from 'draftjs-to-html';
import FormatClearIcon from '@mui/icons-material/FormatClear';

import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import '@draft-js-plugins/anchor/lib/plugin.css';
import '@draft-js-plugins/image/lib/plugin.css';

const INLINE_STYLES = ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH', 'CODE'];

const ClearButton = (props: DraftJsStyleButtonProps) => {
    const { theme, buttonProps = {} } = props;

    const clearStyle = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();

        const editorState = props.getEditorState();
        const contentState = editorState.getCurrentContent();
        const contentWithoutStyles = Modifier.setBlockType(
            INLINE_STYLES.reduce(
                (newContentState, style) =>
                    Modifier.removeInlineStyle(newContentState, editorState.getSelection(), style),
                contentState,
            ),
            editorState.getSelection(),
            'unstyled',
        );

        const newEditorState = EditorState.push(editorState, contentWithoutStyles, 'change-inline-style');

        props.setEditorState(newEditorState);
    };

    const preventBubblingUp = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();
    };

    return (
        <div className={theme.buttonWrapper} onMouseDown={preventBubblingUp}>
            <button
                {...buttonProps}
                className={theme.button}
                onClick={clearStyle}
                type="button"
                aria-label={'Clear formatting'}
            >
                <FormatClearIcon />
            </button>
        </div>
    );
};

const ToolbarWrapper = styled('div')`
    > * {
        transform: translate(-20px) scale(1) !important;
    }
`;

const Link = ({
    children,
    className,
    entityKey,
    getEditorState,
    target,
}: {
    className?: string;
    target?: string;
    children: ReactNode;
    entityKey?: string;
    getEditorState?(): EditorState;
}): ReactElement => {
    const entity = getEditorState!().getCurrentContent().getEntity(entityKey!);
    const entityData = entity ? entity.getData() : undefined;
    const href = (entityData && entityData.url) || undefined;

    return (
        <a
            className={className}
            title={href}
            href={href}
            target={target}
            rel="noopener noreferrer"
            onClick={(e) => {
                if (e.metaKey) {
                    window.open(href, '_blank')?.focus();
                }
            }}
        >
            {children}
        </a>
    );
};

const Image = (props: any) => {
    const [isFullSize, setIsFullSize] = useState(false);
    const { src } = props.contentState.getEntity(props.block.getEntityAt(0)).getData();

    const style = isFullSize
        ? {
              cursor: 'zoom-out',
          }
        : {
              maxWidth: '400px',
              maxHeight: '400px',
              cursor: 'zoom-in',
          };

    return <img onClick={() => setIsFullSize(!isFullSize)} src={src} style={style} />;
};

function childOf(c: Node | null, p: Node): boolean {
    while ((c = c && c.parentNode) && c !== p);
    return !!c;
}

function htmlToEditorState(html: string) {
    const htmlPrep = html.replace(/\n/g, '<br>');

    const processedNodes: Node[] = [];
    const contentState = convertFromHTML({
        htmlToBlock: (nodeName, node) => {
            if (nodeName === 'div' || nodeName === 'img') {
                return {
                    type: 'atomic',
                    data: {
                        html: node.outerHTML,
                    },
                };
            }
            return undefined;
        },
        htmlToEntity: (nodeName, node, createEntity) => {
            if (processedNodes.find((_node) => childOf(node, _node))) {
                return undefined;
            }
            if (nodeName === 'div') {
                processedNodes.push(node);
                return createEntity('HTML', 'IMMUTABLE', { html: node.outerHTML });
            }
            if (nodeName === 'img') {
                return createEntity('IMAGE', 'IMMUTABLE', { src: node.src });
            }
            if (nodeName === 'a') {
                return createEntity('LINK', 'MUTABLE', { url: node.getAttribute('href') });
            }
            return undefined;
        },
    })(htmlPrep);
    return EditorState.createWithContent(contentState);
}

const RichTextEditor = styled(
    (
        props: {
            value: string;
            onChange?: (value: string) => void;
        } & Omit<EditorProps, 'editorState' | 'onChange'>,
    ) => {
        const { value, onChange, onCopy, onCut, ...rest } = props;
        const [html, setHtml] = useState(props.value);

        const [editorState, setEditorState] = useState(htmlToEditorState(props.value));
        useEffect(() => {
            if (props.value === html) return;
            setEditorState(htmlToEditorState(props.value));
        }, [props.value]);

        const [plugins, InlineToolbar, LinkButton] = useMemo(() => {
            const imagePlugin = createImagePlugin({
                imageComponent: Image,
            });
            const linkPlugin = createLinkPlugin({
                Link: Link as any,
            });
            const inlineToolbarPlugin = createInlineToolbarPlugin();
            return [
                [imagePlugin, inlineToolbarPlugin, linkPlugin],
                inlineToolbarPlugin.InlineToolbar,
                linkPlugin.LinkButton,
            ];
        }, []);

        return (
            <Box {...rest}>
                <Editor
                    editorState={editorState}
                    onChange={(editorState: EditorState) => {
                        setEditorState(editorState);
                        const draftHtml = draftToHtml(
                            convertToRaw(editorState.getCurrentContent()),
                            undefined,
                            undefined,
                            (entity) => {
                                if (entity.type === 'HTML') return entity.data.html;
                            },
                        );
                        const html = sanitizeHtml(
                            draftHtml.replace(/<br>/g, '\n').replace(/<\/p>/g, '').replace(/<p>/g, ''),
                        ).replace(/^\s+|\s+$/g, '');
                        setHtml(html);
                        if (props.onChange) {
                            props.onChange(html);
                        }
                    }}
                    plugins={plugins}
                    blockRendererFn={(contentBlock) => {
                        const html = contentBlock.getData().get('html');
                        if (html) {
                            return {
                                component: () => <div dangerouslySetInnerHTML={{ __html: html }} />,
                                editable: false,
                            };
                        }
                        return undefined;
                    }}
                    {...rest}
                />

                {props.onChange && (
                    <ToolbarWrapper>
                        <InlineToolbar>
                            {(props) => (
                                <>
                                    <BoldButton {...props} />
                                    <ItalicButton {...props} />
                                    <UnderlineButton {...props} />
                                    <LinkButton {...(props as any)} />
                                    <Separator />
                                    <UnorderedListButton {...props} />
                                    <OrderedListButton {...props} />
                                    <BlockquoteButton {...props} />
                                    <ClearButton {...props} />
                                </>
                            )}
                        </InlineToolbar>
                    </ToolbarWrapper>
                )}
            </Box>
        );
    },
)`
    .public-DraftEditor-content {
        figure {
            margin: 0;
        }
    }
`;

export default RichTextEditor;
