import { memo, useEffect } from 'react';
import { AppMode, useAppMode, usePersistentEvent, useTicket, useTimeHandler, useUser } from '@tymely/services';

import { Body } from './TicketLayout/Main';

export * from './Arguments';

export const Ticket = memo(() => {
    const ticket = useTicket();
    const timer = useTimeHandler();
    const user = useUser();
    const { appMode } = useAppMode();

    useEffect(() => {
        if (ticket) {
            timer.reset();
        }
    }, [ticket.id]);

    if (!ticket) {
        throw new Error('Ticket not loaded');
    }

    usePersistentEvent(
        `ticket/${ticket?.id}`,
        {
            event: 'TICKET_BEING_PROCESSED',
            agent: user?.username,
            startedAt: new Date(),
        },
        [user],
        appMode == AppMode.Online,
    );

    return <Body />;
});

Ticket.displayName = 'Ticket';
