import Popper, { PopperProps } from '@mui/base/Popper';
import React from 'react';

export const styledPopper = (style: React.CSSProperties) => {
    const _Popper = (props: PopperProps) => (
        <Popper
            {...props}
            style={{
                ...props.style,
                ...style,
            }}
            placement="bottom-start"
        />
    );
    return _Popper;
};
