import { Paper, styled } from '@mui/material';

type DataGridContainerProps = {
    multiselect?: boolean;
    loading?: boolean;
    rowCount: number;
    width: number;
    rowHeight: number;
    headerRowHeight: number;
};

const DataGridContainer = styled(Paper, {
    shouldForwardProp: (prop: string) =>
        !['loading', 'rowCount', 'width', 'multiselect', 'rowHeight', 'headerRowHeight'].includes(prop),
})<DataGridContainerProps>`
    height: ${({ loading, rowCount, rowHeight, headerRowHeight }) =>
        loading ? `${rowHeight * rowCount + headerRowHeight + 2}px` : '100%'};
    max-width: 100%;
    overflow-x: auto;
    .rdg {
        background: none;
        border: none;
        height: inherit;
        transition: grid-template-rows 0.3s ease-in-out;
        --rdg-row-hover-background-color: ${({ theme }) => theme.palette.grey.A200};
        --rdg-row-selected-background-color: ${({ theme, multiselect }) =>
            multiselect ? 'transparent' : theme.palette.grey[300]};
        --rdg-row-selected-hover-background-color: ${({ theme, multiselect }) =>
            multiselect ? 'transparent' : theme.palette.grey[400]};
        --rdg-checkbox-focus-color: ${({ theme }) => theme.palette.grey[400]};
        --rdg-border-color: ${({ theme }) => theme.palette.grey[300]};
    }
    .rdg-cell {
        outline: none;
        display: flex;
        padding: ${({ theme }) => theme.spacing(0, 1.5)};
    }
    [role='columnheader'] {
        padding-left: 0;
        padding-right: 0;
    }
    [role='columnheader'],
    .centered {
        align-items: center;
        justify-content: center;
    }
    [role='gridcell'] {
        align-items: center;
        padding-inline: ${({ theme }) => `var(--rdg-cell-padding-inline, ${theme.spacing(1)})`};
        border-block-end: 1px solid var(--rdg-border-color);
    }
    .noBorder {
        border-right: 0;
    }
    .reorder {
        padding: 0;
        z-index: 1;
        overflow: visible;
    }
    [role='gridcell']:not(.withBorder) {
        border-right: 0;
    }
    .disabledRow {
        color: ${({ theme }) => theme.palette.text.disabled};
    }
    .rdg-summary-row .rdg-cell {
        border-top-width: 1px;
        border-color: ${({ theme }) => theme.palette.grey[300]};
        height: ${({ theme }) => theme.spacing(5)};
    }
    .select {
        overflow: visible;
    }
`;

export default DataGridContainer;
