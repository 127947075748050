const EyeIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
            <circle
                cx="5.13624"
                cy="5.36036"
                r="1.75245"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 5.36349C1 3.07873 2.85216 1.22656 5.13692 1.22656C7.42168 1.22656 9.27385 3.07873 9.27385 5.36349"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default EyeIcon;
