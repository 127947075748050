import { Tooltip } from '@mui/material';
import { IArgumentImageUrl, IArgumentImageUrls } from '@tymely/atoms';
import { Image } from '@tymely/components';

import { ArgumentFieldProps } from './Layout';

export const ImageArgument = (props: ArgumentFieldProps<IArgumentImageUrl | IArgumentImageUrls>) => (
    <>
        {[props.argument.value]
            .flat()
            .filter(Boolean)
            .map((value) => (
                <Tooltip key={value} title={props.argument.description} enterDelay={1000} followCursor>
                    <Image src={value} alt={value} />
                </Tooltip>
            ))}
    </>
);
