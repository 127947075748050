import axios from 'axios';
import { Nullable } from '@global/types';
import { IArgument, ICategoryItem, IArgumentCategories, IArgumentDynamicCategories, SpecialValue } from '@tymely/atoms';

export interface IResponseTemplate {
    id: number;
    response_txt: string;
    personalized: boolean;
}

export interface ITemplateVariable {
    value: IArgument['value'];
    dtype: string;
    arg_type: IArgument['arg_type'];
    is_list: boolean;
    special_value: Nullable<Omit<SpecialValue, 'none'>>;
    categories?: Nullable<IArgumentCategories<string | ICategoryItem>>;
    dynamic_categories?: Nullable<IArgumentDynamicCategories>;
    group_by: Nullable<string>;
}

export const templateErrorTypes = ['unknown', 'syntax', 'missing_argument'];
export type TemplateErrorType = (typeof templateErrorTypes)[number];

export interface ITemplateError {
    type: TemplateErrorType;
    description: string;
    location?: {
        line?: number;
        char?: number;
    };
}

export const fetchResponseTemplate = (templateId: number) =>
    axios.get<IResponseTemplate>(`response-template/${templateId}`).then((res) => res.data);

export const editResponseTemplate = (template: IResponseTemplate) =>
    axios.put<IResponseTemplate>(`response-template/${template.id}`, template).then((res) => res.data);

export const createResponseTemplate = (template: Omit<IResponseTemplate, 'id'>) =>
    axios.post<IResponseTemplate>('response-template', template).then((res) => res.data);

export const renderTemplate = (template: string, variables: Record<string, unknown>, sanitize?: boolean) =>
    axios.post<string>('template/render', { template, variables, sanitize }).then((resp) => resp.data);

export const getTemplateFreeVariables = (template: string) =>
    axios.post<string[]>('template/free-variables', { template }).then((resp) => resp.data);
