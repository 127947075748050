import React from 'react';
import { Box, BoxProps, InputBaseComponentProps, MenuItem, MenuProps, Select, styled, useTheme } from '@mui/material';

const _IconRoot = styled(Box)`
    line-height: 0;
    top: calc(50% - 12px) !important;
`;

const Icon = (props: BoxProps) => {
    return (
        <_IconRoot {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#839AFF" />
                <path d="M16 10.3984L12 13.5984L8 10.3984" stroke="white" strokeWidth="1.2" strokeLinecap="round" />
            </svg>
        </_IconRoot>
    );
};

const _ValueRoot = styled(Box)`
    white-space: pre-wrap;
`;

const _Value = styled(Box)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`;

const INPUT_PROPS: InputBaseComponentProps = {
    sx: { p: 0, pr: 3, pl: 1, textAlign: 'left', '&.MuiSelect-select': { minHeight: 20 } },
};

const useMenuProps = () => {
    const theme = useTheme();
    return React.useMemo<Partial<MenuProps>>(
        () => ({
            elevation: 0,
            transformOrigin: { horizontal: 'center', vertical: 20 },
            sx: { zIndex: theme.zIndex.modal - 1 },
            PaperProps: {
                sx: {
                    backgroundColor: theme.palette.common.white,
                    borderRadius: `${theme.spacing(2)}px`,
                    width: 134,
                    boxShadow: '14px 9px 26px 0px rgba(140, 161, 255, 0.35)',
                },
            },
        }),
        [theme],
    );
};

const _Select = styled(Select)`
    font-size: ${({ theme }) => theme.typography.fontSmall};
    color: ${({ theme }) => theme.palette.common.white};
    &.MuiInput-input:focus {
        background-color: transparent;
    }
` as unknown as typeof Select;

const _MenuItem = styled(MenuItem)`
    height: ${({ theme }) => theme.spacing(3.5)};
    background-color: transparent !important;
`;

const _Option = styled(MenuItem)`
    font-size: ${({ theme }) => theme.typography.fontSmall};
    &:focus {
        background-color: transparent;
    }
`;

type BubbleSelectProps = { title: string; options: string[] };

const BubbleSelect = React.memo(({ title, options }: BubbleSelectProps) => {
    const [option, setOption] = React.useState<string>('');
    const [open, setOpen] = React.useState<boolean>(false);
    const menuProps = useMenuProps();

    return (
        <_Select
            size="small"
            fullWidth
            label={title}
            variant="standard"
            displayEmpty
            disableUnderline
            value={option}
            open={open}
            onClose={() => setOpen(false)}
            renderValue={(selected: string) => (
                <_ValueRoot>
                    {selected ? selected : title}
                    <_Value onClick={() => setOpen((open) => !open)}></_Value>
                </_ValueRoot>
            )}
            IconComponent={Icon}
            inputProps={INPUT_PROPS}
            MenuProps={menuProps}
            onChange={(e) => setOption(e.target.value)}
        >
            <_MenuItem value="" />
            {options.map((option) => (
                <_Option key={option} value={option}>
                    {option}
                </_Option>
            ))}
        </_Select>
    );
});

BubbleSelect.displayName = 'BubbleSelect';

export default BubbleSelect;
