import { Box, styled } from '@mui/material';
import { useEffect, useState } from 'react';

const Root = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: 'auto',
});

const Svg = styled('svg')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transform: 'rotateY(-180deg) rotateZ(-90deg)',
    overflow: 'visible',
});

interface CountdownTimerProps {
    id: string;
    msInterval?: number;
    seconds: number;
    size: number;
    strokeBgColor: string;
    strokeColor?: string;
    strokeWidth: number;
    onComplete?: () => void;
}
export const CountdownTimer = ({
    id,
    msInterval = 15,
    seconds,
    size,
    strokeBgColor,
    strokeColor,
    strokeWidth,
    onComplete,
}: CountdownTimerProps) => {
    const milliseconds = seconds * 1000;
    const radius = size / 2;
    const circumference = size * Math.PI;

    const [countdown, setCountdown] = useState(milliseconds);
    const [isPlaying, setIsPlaying] = useState(false);

    const startTimer = () => {
        setIsPlaying(true);

        const interval = setInterval(() => {
            setCountdown((countdown) => {
                if (countdown === 0) {
                    clearInterval(interval);
                    setCountdown(() => milliseconds);
                    setIsPlaying(!isPlaying);
                    onComplete?.();

                    return milliseconds;
                }

                return countdown - msInterval;
            });
        }, msInterval);
    };

    useEffect(() => startTimer(), []);

    const strokeDashoffset = () => circumference - (countdown / milliseconds) * circumference;

    const countdownSizeStyles = {
        height: size,
        width: size,
    };

    const textStyles = {
        color: strokeBgColor,
        fontSize: size * 0.4,
    };

    return (
        <Root className="countdown-timer" style={countdownSizeStyles} id={`${id}-countdown-timer-root`}>
            <p id={`${id}-countdown-timer-text`} style={textStyles}>
                {((countdown + 1000) / 1000).toFixed()}
            </p>
            <Svg>
                <circle
                    cx={radius}
                    cy={radius}
                    r={radius}
                    fill="none"
                    stroke={strokeBgColor}
                    strokeWidth={strokeWidth}
                />
            </Svg>
            <Svg>
                <circle
                    strokeDasharray={circumference}
                    strokeDashoffset={isPlaying ? strokeDashoffset() : 0}
                    r={radius}
                    cx={radius}
                    cy={radius}
                    fill="none"
                    strokeLinecap="round"
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                />
            </Svg>
        </Root>
    );
};

// <div style={{
//     display: 'flex',
//     justifyContent: 'space-around',
//     flexWrap: 'wrap'
// }}>
//     <CountdownTimer
//         seconds={15}
//         size={60}
//         strokeBgColor="black"
//         strokeColor="lemonchiffon"
//         strokeWidth={2}
//     />
// </div>
