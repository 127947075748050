import { getTicketEvents, postTicketCrumb } from '@tymely/api';
import {
    ICrumbAdditionalData,
    IDineshTicketOperations,
    ITicket,
    ITicketTrailEvent,
    ITicketTrailStatus,
} from '@tymely/atoms';
import { getSeconds } from '@tymely/utils';
import { useInfiniteQuery } from 'react-query';

import { useTicket, useTicketSessionId } from './ticket.services';
import { useUser } from './auth.services';
import { useHandlingTimer, useTimeHandler } from './utils';
import { useSelectedComment } from './comment.services';

export const useCreateTicketCrumb = () => {
    const ticket = useTicket();
    const user = useUser();
    const ticketSessionId = useTicketSessionId();
    const handlingTimer = useHandlingTimer();
    const selectedComment = useSelectedComment();

    return (
        operation: IDineshTicketOperations,
        additionalData: ICrumbAdditionalData = {},
        status: ITicketTrailStatus = ITicketTrailStatus.SUCCESS,
    ) => {
        if (!ticket || !user) return;

        if (user?.username) {
            additionalData['username'] = user.username;
        }

        if (ticketSessionId) {
            additionalData['ticket_session_id'] = ticketSessionId;
        }

        const crumbElapsed = handlingTimer?.getTotalActiveTime();
        if (crumbElapsed) {
            additionalData['elapsed_until_crumb'] = crumbElapsed;
        }

        postTicketCrumb({
            operation: operation,
            ticket_id: ticket.id,
            comment_id: selectedComment?.id,
            origin_ticket_id: ticket.original_id_from_client,
            organization_id: ticket.organization_id,
            status: status,
            additional_data: { ...additionalData },
        });
    };
};

export const useCreateHandlingDurationsCrumb = () => {
    const createTicketCrumb = useCreateTicketCrumb();
    const handlingTimer = useTimeHandler();
    return (operation: IDineshTicketOperations, additionalData: ICrumbAdditionalData = {}) =>
        createTicketCrumb(operation, {
            ...additionalData,
            handling_duration: getSeconds(handlingTimer.getElapsedTime()),
            idle_duration: getSeconds(handlingTimer.getIdleTime()),
            active_duration: getSeconds(handlingTimer.getActiveTime()),
        });
};

export const useTicketEventsQuery = (ticketId: ITicket['id'], enabled?: boolean) => {
    return useInfiniteQuery<ITicketTrailEvent[]>(
        ['ticketEvents', ticketId],
        ({ pageParam }) => getTicketEvents(ticketId, pageParam),
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.length === 0) return undefined;
                return pages.reduce((total, page) => total + page.length, 0);
            },
            enabled,
        },
    );
};
