import axios from 'axios';
import { JSONSchema7 } from 'json-schema';
import { parse } from 'csv-parse/browser/esm/sync';
import {
    ILlmExperimentCreate,
    ILlmExperiment,
    ILlmReportRecord,
    ILlmSummaryReport,
    ILlmExperimentRunParams,
    ILlmExperimentUpdate,
} from '@tymely/atoms';

export const getLlmExperimentTrails = (id: ILlmExperiment['id']) =>
    axios.get<ILlmExperiment>(`llm-experiment/${id}/trails`).then((resp) => resp.data);

export const getLlmExperiment = (id: ILlmExperiment['id']) =>
    axios.get<ILlmExperiment>(`llm-experiment/${id}`).then((resp) => resp.data);

const parseReportCsv = (report: string) => {
    return parse(report, {
        cast: (value, context) => {
            switch (context.column) {
                case 'arg_value':
                    return value === 'None' ? null : value;
                case 'arg_id':
                case 'comment_id':
                case 'ticket_id':
                case 'experiment_arg_md_id':
                case 'llm_trail_id':
                case 'optimize_target_arg_md_id':
                    return parseInt(value);
                case 'predicted_confidence':
                    return parseFloat(value);
                case 'predicted_correctly':
                    return value === 'True';
            }
            return value;
        },
        columns: true,
        skip_empty_lines: true,
        record_delimiter: '\n',
    }) as ILlmReportRecord[];
};

export const getLlmExperimentReport = (report_link: string) =>
    axios
        .get(report_link, {
            headers: {
                Authorization: null,
            },
        })
        .then((resp) => parseReportCsv(resp.data));

const parseSummaryReportCsv = (report: string) => {
    return parse(report, {
        cast: true,
        columns: true,
        skip_empty_lines: true,
        record_delimiter: '\n',
    }) as ILlmSummaryReport[];
};

export const getLlmExperimentSummaryReport = (summary_report_link: string) =>
    axios
        .get(summary_report_link, {
            headers: {
                Authorization: null,
            },
        })
        .then((resp) => parseSummaryReportCsv(resp.data)[0]);

export const createLlmExperiment = (llmExperiment: ILlmExperimentCreate) =>
    axios.post<ILlmExperiment>('llm-experiment', llmExperiment).then((resp) => resp.data);

export const createLlmExperimentReport = (llmExperimentId: ILlmExperiment['id']) =>
    axios.get(`llm-experiment/${llmExperimentId}/create-report`);

export const editLlmExperiment = (llmExperiment: ILlmExperimentUpdate) =>
    axios.put<ILlmExperiment>(`llm-experiment/${llmExperiment.id}`, llmExperiment).then((resp) => resp.data);

export const runOfflineLlmExperiment = (llmExperimentId: ILlmExperiment['id'], params: ILlmExperimentRunParams = {}) =>
    axios.put(`llm-experiment/${llmExperimentId}/run-offline`, params);

export const searchLlmExperiments = (
    experiment_type: ILlmExperiment['experiment_type'],
    optimization_target_name: string,
) =>
    axios
        .get<ILlmExperiment[]>('llm-experiments/search', {
            params: {
                experiment_type,
                optimization_target_name,
            },
        })
        .then((resp) => resp.data);

export const fetchLlmExperimentSchema = () => axios.get<JSONSchema7>('llm-experiment/schema').then((res) => res.data);
