import { IArgument, IArgumentCategory, IArgumentDisplayRegulator, IArgumentString, isCategorical } from '@tymely/atoms';

export const getGroupArg = (argument: IArgument, args: IArgument[]) => {
    if (isCategorical(argument)) {
        return args.find((arg) => arg.name === argument.group_by) as IArgumentCategory;
    }
    return;
};

export const getDisplayRegulatorArg = (argument: IArgument, args: IArgument[]) => {
    if (isCategorical(argument)) {
        return args.find((arg) => arg.md_id === argument.regulator_md_id) as IArgumentDisplayRegulator;
    }
    return;
};

export const getSearchArg = (argument: IArgument, args: IArgument[]) => {
    if (isCategorical(argument)) {
        return args.find((arg) => arg.md_id === argument.search_md_id) as IArgumentString;
    }
    return;
};

export const isHighConfidenceArgument = (arg: IArgument) => arg.high_confidence && !arg.is_edited;
