import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';

const ONE_HOUR_IN_MILLIS = 60000;
export const locale = enUS.code;

export const longDateFormat = (dateStr: string) => {
    try {
        const date = new Date(dateStr);
        return format(date, 'EEE, d MMM yyyy', { locale: enUS });
    } catch (_error) {
        return 'Invalid date';
    }
};

export const parseISO = (dateStr: string) => {
    const isoFormat = /(00|Z)$/.test(dateStr) ? dateStr : `${dateStr}Z`;
    return new Date(isoFormat);
};

export const formatISO = (dateStr: string) => {
    try {
        const date = new Date(dateStr);
        return format(date, 'dd-MM-yyyy', { locale: enUS });
    } catch (_error) {
        return 'Invalid date';
    }
};

export const shortTimeFormat = (dateStr: string) => {
    try {
        const date = new Date(dateStr);
        const offset = `UTC+${-date.getTimezoneOffset() / 60}`;
        const time = format(date, 'HH:mm', { locale: enUS });
        return `${time} ${offset}`;
    } catch (_error) {
        return 'Invalid date';
    }
};

export const offsetTimeZone = (date: Date, subtract = true) => {
    const offsetDate = new Date();
    offsetDate.setTime(date.getTime() + (subtract ? 1 : -1) * date.getTimezoneOffset() * ONE_HOUR_IN_MILLIS);
    return offsetDate;
};

export const getSeconds = (millisecondsDuration: number) => Math.round(millisecondsDuration / 1000);
