import { IOrganization, LlmModelMetadata } from '@tymely/atoms';
import axios from 'axios';

export interface IPrompt {
    id: number;
    name: string;
    description: string;
    prompt?: string;
    deleted_at?: string;
    created_date: string;
    system_prompt?: string;
    additional_data: Record<string, unknown>;
    organization_id?: IOrganization['id'];
    llm_model_metadata_id: LlmModelMetadata['id'];
}

export type IPromptCreate = Omit<IPrompt, 'id' | 'created_date' | 'llm_model_metadata_id'> & {
    llm_model_metadata_id?: number;
};

export const fetchLlmModelMetadatas = () =>
    axios.get<{ results: LlmModelMetadata[] }>('/llm-model-metadatas/list').then((res) => res.data.results);

export const fetchPrompts = (organizationId?: IOrganization['id']) =>
    axios
        .get<IPrompt[]>('/prompt-template/list/by-org', {
            params: {
                limit: 2000,
                include_deleted: true,
                organization_id: organizationId,
            },
        })
        .then((res) => res.data);

export const createPrompt = (prompt: IPromptCreate) =>
    axios.post<IPrompt>('prompt-template', prompt).then((res) => res.data);

export const deletePrompt = (prompt: IPrompt) =>
    axios.delete<void>(`prompt-template/${prompt.id}`).then((res) => res.data);

export const updatePrompt = ({ id, created_date, ...rest }: IPrompt) =>
    axios.put<void>(`prompt-template/${id}`, rest).then(() => ({ id, created_date, ...rest }));

export const setLive = (prompt: IPrompt) =>
    axios.put<IPrompt>(`prompt-template/${prompt.id}/set-live`).then(() => ({ ...prompt, deleted_at: undefined }));

export const getPrompt = (name: string) =>
    axios.get<IPrompt>('prompt-template', { params: { name } }).then((res) => res.data);
