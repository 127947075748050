import { forwardRef, useCallback, useState } from 'react';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { TicketReportCategory } from '@tymely/atoms';
import { BaseModal, type ModalApi } from '@tymely/components/Modals';
import { useLogTicketProcess, useSetAlert, useTicketNav, useTicketReport } from '@tymely/services';

type ReportModalProps = {
    open?: boolean;
    onClose?: () => void;
};

export const ReportModal = forwardRef<ModalApi, ReportModalProps>((props: ReportModalProps, ref) => {
    const [reason, setReason] = useState('');
    const [category, setCategory] = useState<TicketReportCategory>();
    const [reporting, setReporting] = useState(false);
    const { reportCategories, report } = useTicketReport();
    const setAlert = useSetAlert();
    const { logProcessed } = useLogTicketProcess();
    const { handleNext } = useTicketNav();

    const onReport = useCallback(() => {
        setReporting(true);
        logProcessed('reported');
        handleNext(
            report(category!, reason)
                .catch(() => {
                    setAlert('Failed to report');
                })
                .finally(() => {
                    setReporting(false);
                    props.onClose?.();
                }),
        );
    }, [category, report, reason, handleNext, logProcessed]);

    const menuElems = reportCategories.map((cat) => {
        const str = cat.toLowerCase().replace(/_/g, ' ');
        return (
            <MenuItem key={cat} value={cat}>
                {str[0].toUpperCase() + str.slice(1)}
            </MenuItem>
        );
    });

    return (
        <BaseModal
            ref={ref}
            open={props.open || false}
            title="Report ticket"
            loading={reporting}
            disabled={!category}
            okLabel="Report"
            onClose={props.onClose}
            onOk={onReport}
        >
            <Box display="flex" flexDirection="column">
                <Typography
                    sx={{
                        mb: 2,
                        '& p': {
                            m: 0,
                        },
                        '& ul': {
                            mt: 0.5,
                        },
                    }}
                >
                    <p>Are you sure this should be reported? Did you:</p>
                    <ul>
                        <li>Check the “hidden arguments” section to find issues?</li>
                        <li>Check if the template can be easily personalized to fit?</li>
                        <li>Reset / recalculate the ticket (in case of a technical issue)</li>
                    </ul>
                </Typography>

                <TextField
                    select
                    label="Select report reason category *"
                    SelectProps={{
                        displayEmpty: true,
                        renderValue: (value) => {
                            return (
                                <Typography textTransform="capitalize" color={value ? 'text.primary' : 'text.disabled'}>
                                    {value ? (value as string).toLowerCase().replace('_', ' ') : 'Select category'}
                                </Typography>
                            );
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    value={category || ''}
                    onChange={(e) => setCategory(e.target.value as TicketReportCategory)}
                >
                    {menuElems}
                </TextField>
                <TextField
                    id="note-text-field"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    fullWidth
                    label="Report reason description (optional)"
                    InputLabelProps={{ shrink: true }}
                    sx={{ mt: 2 }}
                />
            </Box>
        </BaseModal>
    );
});

ReportModal.displayName = 'ReportModal';
