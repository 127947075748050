import React from 'react';
import { styled, BoxProps, useTheme } from '@mui/material';
import { CommentHighlight, Bubble } from '@tymely/components/Comment';
import { IComment, IPolicySet } from '@tymely/atoms';

import CommentBubble from './CommentBubble';

const _CommentBubble = styled(CommentBubble)`
    overflow: visible;
    padding-left: ${({ theme }) => theme.spacing(10)};
`;

type DemoCommentBubbleProps = BoxProps & {
    comment: IComment;
    intent?: IPolicySet;
    withDate?: boolean;
    selected?: boolean;
    focusedOn?: boolean;
    onUntag?: (comment: IComment) => void;
};

const useHtmlProps = (htmlRef: React.RefObject<HTMLDivElement>, needHighlight: boolean) => {
    const theme = useTheme();

    const props = React.useMemo(
        () => ({
            ref: htmlRef,
            sx: {
                paddingLeft: theme.spacing(2),
                margin: theme.spacing(5, 0),
                marginTop: theme.spacing(9),
                width: 590,
                minHeight: 150,
                '& .highlight': needHighlight
                    ? { color: theme.palette.common.white, backgroundColor: 'rgba(92,122,255,0.76)' }
                    : {},
                '& p': { mt: 1, mb: 1, lineHeight: 1.75 },
            },
        }),
        [theme],
    );

    return props;
};

const DemoCommentBubble = ({ onUntag, comment, ...rest }: DemoCommentBubbleProps) => {
    const highlights = comment.additional_data?.['highlights'] as CommentHighlight[];
    const needHighlight = Boolean(highlights && comment.is_customer && comment.selected_intent_id);

    const htmlRef = React.useRef<HTMLDivElement>(null);
    const htmlProps = useHtmlProps(htmlRef, needHighlight);
    const [bubbles, setBubbles] = React.useState<JSX.Element[]>([]);

    React.useEffect(() => {
        if (!needHighlight) {
            return;
        }

        const queryH = (h: CommentHighlight) =>
            htmlRef.current?.querySelector(`#${CSS.escape(h.token.toLowerCase().replace(/\s/g, '-'))}`);
        const bubbles = highlights
            .map((h) => [h, queryH(h) as HTMLElement] as const)
            .filter(([h]) => Boolean(h.bubble));
        const width = htmlRef.current?.offsetWidth ?? 0;
        setBubbles(
            bubbles
                .map(([h, elem]) => {
                    if (!elem || !h.bubble) return null;

                    if (h.bubble.placement === 'top')
                        return <Bubble key={h.token} highlight={h} anchorEl={elem} scrollIntoView />;

                    const left =
                        h.bubble.placement === 'left' ? elem.offsetLeft : width - elem.offsetLeft - elem.offsetWidth;
                    const offset: [number, number] = [h.bubble.offset[0], left + h.bubble.offset[1]];
                    return (
                        <Bubble
                            key={h.token}
                            highlight={{ ...h, bubble: h.bubble && { ...h.bubble, offset } }}
                            anchorEl={elem}
                        />
                    );
                })
                .filter(Boolean),
        );
    }, [htmlRef.current, highlights, needHighlight]);

    return (
        <_CommentBubble
            {...rest}
            selected={false}
            comment={{ ...comment, body_html: undefined }}
            htmlProps={htmlProps}
            showInfo
            onUntag={onUntag}
        >
            {bubbles}
        </_CommentBubble>
    );
};

export default DemoCommentBubble;
