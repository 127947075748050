import sanitizeHtml_, { defaults, Tag } from 'sanitize-html';

import { CommentHighlight } from './types';

export const linkRegexp = new RegExp('https?://([^"\']*\\.(?:png|jpg|jpeg|svg))', 'i');
const zdAttRegexp = new RegExp('https://\\w+\\.zdusercontent\\.com/attachment/\\S+', 'i');

export const sanitizeHtml = (html: string, onAttachment?: (url: string) => void) => {
    let aTag: Tag | null = null;
    return sanitizeHtml_(html, {
        ...defaults,
        allowedAttributes: {
            ...defaults.allowedAttributes,
            '*': ['style', 'id', 'class'],
        },
        textFilter(text) {
            if (!aTag || !aTag.attribs['href']) {
                return text;
            }
            const isHttpLink = /^http/.test(aTag.attribs['href']);
            const href = aTag.attribs['href'].replace('mailto:', '');
            const linkHtml = `<span style="color:rgba(0, 0, 238)">${href}</span>`;
            const title = text.trim() && text !== href ? text.trim() : '';
            const link = isHttpLink ? linkHtml : href;
            aTag = null;
            return title ? `${title} (${link})` : link;
        },
        transformTags: {
            img: (tagName, attribs) => {
                const src = attribs['src'];
                if (linkRegexp.test(src) || zdAttRegexp.test(src)) {
                    onAttachment?.(src);
                }
                return {
                    tagName,
                    attribs: {},
                };
            },
            a: (tagName, attribs) => {
                if (attribs['href']?.endsWith('.mp4')) {
                    return {
                        tagName,
                        attribs,
                    };
                }

                aTag = { tagName, attribs };
                return {
                    tagName: 'span',
                    attribs: {},
                };
            },
        },
    });
};

export const highlightHtml = (highlights: CommentHighlight[], html: string) => {
    for (const hi of highlights) {
        const id = hi.token.toLowerCase().replace(/\s/g, '-');
        const flags = `im${hi.global ? 'g' : ''}`;
        const escapedToken = hi.token.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(`\\b(${escapedToken})\\b`, flags);
        const highlightMark = `<span id="${id}" class="${hi.class}">${hi.replace ?? hi.token}</span>`;

        if (hi.textMode) {
            const htmlEl = document.createElement('div');
            htmlEl.innerHTML = html;
            Array.from(htmlEl.getElementsByTagName('*')).forEach((element) => {
                element.childNodes.forEach((node) => {
                    if (node.nodeType === Node.TEXT_NODE) {
                        const text = node.nodeValue || '';
                        const replacedText = text.replace(regex, highlightMark);
                        if (replacedText !== text) {
                            const replacementEl = document.createElement('span');
                            replacementEl.innerHTML = replacedText;
                            element.replaceChild(replacementEl, node);
                        }
                    }
                });
            });
            html = htmlEl.innerHTML;
        } else {
            html = html.replace(regex, highlightMark);
        }
    }
    return html;
};
