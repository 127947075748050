import axios from 'axios';
import { JSONSchema7 } from 'json-schema';
import { IActionMetadata, IArgumentMetadata } from '@tymely/atoms';

export const listActionsMetadata = async () =>
    await axios
        .get<{ results: IActionMetadata[] }>('action-metadatas/list', { params: { limit: 1000 } })
        .then((res) => res.data?.results);

export const editActionMetadata = (metadata: IActionMetadata) =>
    axios.put<IActionMetadata>(`action-metadata/${metadata.id}`, metadata).then((resp) => resp.data);

export const createActionMetadata = (metadata: Omit<IActionMetadata, 'id'>) =>
    axios.post<IActionMetadata>('action-metadata', metadata).then((resp) => resp.data);

export const getArgsInActions = () =>
    axios.get<Record<IActionMetadata['id'], IArgumentMetadata['name'][]>>('args-in-actions').then((resp) => resp.data);

export const getExecutorSchema = (metadataId: IActionMetadata['id']) =>
    axios.get<JSONSchema7>(`action-metadata/${metadataId}/executor-schema`).then((resp) => resp.data);
