import React from 'react';
import { useUser } from '@tymely/services';
import { Allotment } from 'allotment';
import { Button, styled } from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';
import { useNavigate } from 'react-router-dom';
import { useHistoryStack } from '@tymely/utils';

import TemplateCodeEditor from './TemplateCodeEditor';
import TemplateRichText from './TemplateRichText';
import TemplateSideBar from './TemplateSideBar';
import TemplateEditorProvider, { TemplateEditorProviderProps } from './TemplateEditorProvider';
import TemplateViewers from './TemplateViewers';
import UpdatedTemplateDialog from './UpdatedTemplateDialog';

import 'allotment/dist/style.css';

const _BackButton = styled(Button)`
    border-radius: 0;
    .MuiButton-startIcon {
        transform: rotate(-180deg);
    }
`;

const BackButton = () => {
    const navigations = useHistoryStack();
    const navigate = useNavigate();

    if (navigations.length <= 0) {
        return null;
    }

    return (
        <_BackButton fullWidth variant="contained" startIcon={<ForwardIcon />} onClick={() => navigate(-1)}>
            Back
        </_BackButton>
    );
};

type TemplateEditorProps = TemplateEditorProviderProps & {
    withBackButton?: boolean;
};

const TemplateEditor = ({ withBackButton, children, ...rest }: TemplateEditorProps) => {
    const user = useUser();

    if (!user?.isAdmin) {
        return <div>Access denied</div>;
    }

    return (
        <TemplateEditorProvider {...rest}>
            <Allotment>
                <Allotment.Pane preferredSize="25%" minSize={300}>
                    <TemplateSideBar>{withBackButton && <BackButton />}</TemplateSideBar>
                </Allotment.Pane>
                <Allotment.Pane preferredSize="75%">
                    <Allotment vertical>
                        <Allotment.Pane minSize={300}>
                            <TemplateCodeEditor>{children}</TemplateCodeEditor>
                            <TemplateViewers />
                        </Allotment.Pane>
                        <Allotment.Pane snap={true}>
                            <TemplateRichText />
                        </Allotment.Pane>
                    </Allotment>
                </Allotment.Pane>
            </Allotment>
            <UpdatedTemplateDialog />
        </TemplateEditorProvider>
    );
};

export default TemplateEditor;
