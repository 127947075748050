import { Box, BoxProps, CircularProgress, IconButton, styled, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNextRounded';
import DownloadIcon from '@mui/icons-material/DownloadRounded';
import React from 'react';
import { Document, Page } from 'react-pdf';

type PDFPreviewProps = BoxProps & { url: string };

const _Root = styled(Box)`
    position: relative;
    &:hover {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        .toolbar {
            display: flex;
            align-items: center;
        }
    }
`;

const _Toolbar = styled(Box)`
    position: absolute;
    bottom: 8px;
    right: 8px;
    z-index: 1;
    display: none;
`;

const _Document = styled(Document)`
    z-index: 0;
`;

const PDFPreview = ({ url, ...rest }: PDFPreviewProps) => {
    const [page, setPage] = React.useState(1);
    const [numPages, setNumPages] = React.useState(3);
    return (
        <_Root>
            <_Toolbar className="toolbar" {...rest}>
                {numPages > 1 && (
                    <>
                        <IconButton onClick={() => setPage(page - 1)} size="small" disabled={page === 1}>
                            <NavigateBeforeIcon />
                        </IconButton>
                        <Typography variant="body2" component="span" sx={{ mx: 1 }}>
                            {page} of {numPages}
                        </Typography>
                        <IconButton onClick={() => setPage(page + 1)} size="small" disabled={page === numPages}>
                            <NavigateNextIcon />
                        </IconButton>
                    </>
                )}
                <IconButton
                    onClick={() => {
                        const link = document.createElement('a');
                        link.href = url;
                        link.click();
                    }}
                    size="small"
                >
                    <DownloadIcon />
                </IconButton>
            </_Toolbar>
            <_Document
                file={url}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                className="document"
                loading={<CircularProgress />}
            >
                <Page width={240} pageNumber={1} renderAnnotationLayer={false} renderTextLayer={false} />
            </_Document>
        </_Root>
    );
};

export default PDFPreview;
