import { Tooltip } from '@mui/material';
import { IArgumentVideoUrl, IArgumentVideoUrls } from '@tymely/atoms';
import { VideoPlayer } from '@tymely/components';

import { ArgumentFieldProps } from './Layout';

export const VideoArgument = (props: ArgumentFieldProps<IArgumentVideoUrl | IArgumentVideoUrls>) => (
    <>
        {[props.argument.value]
            .flat()
            .filter(Boolean)
            .map((value) => (
                <Tooltip key={value} title={props.argument.description} enterDelay={1000} followCursor>
                    <VideoPlayer url={value} />
                </Tooltip>
            ))}
    </>
);
