import { fetchDecisionLlmClusters, fetchIntents, postNewIntent, putIntent } from '@tymely/api';
import { IPolicySet } from '@tymely/atoms';
import { INTENTS_CUSTOM_CATEGORY } from '@tymely/config';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useSetAlert } from './alerts.services';

const INTENTS_QUERY_KEY = 'intents';
const DECISION_LLM_CLUSTERS_QUERY_KEY = 'decisionLlmClusters';

export const useIntentsQuery = (onSuccess?: (intents?: IPolicySet[]) => void) =>
    useQuery<IPolicySet[]>(INTENTS_QUERY_KEY, fetchIntents, {
        onSuccess: onSuccess,
        staleTime: Infinity,
    });

export const useCreateIntentMutation = (onSuccess?: (intent: IPolicySet) => void) => {
    const setAlert = useSetAlert();
    const queryClient = useQueryClient();

    return useMutation(INTENTS_QUERY_KEY, (name: string) => postNewIntent(name, INTENTS_CUSTOM_CATEGORY, true), {
        onSuccess: async (data) => {
            await queryClient.invalidateQueries(INTENTS_QUERY_KEY);
            return onSuccess?.(data);
        },
        onError: (_, variables) => {
            setAlert(`Failed creating intent "${variables}"`, 'error');
        },
    });
};

export const useUpdateIntentMutation = (onSuccess?: (intent: IPolicySet) => void) => {
    return useMutation(
        'updateIntent',
        (update: { id: IPolicySet['id']; name: string; category: string }) => putIntent(update),
        { onSuccess },
    );
};

export const useIntentExists = () => {
    const intents = useIntentsQuery();

    return useCallback(
        (intentName: string) =>
            (intents.data ?? []).some(({ name }) => name.trim().toLowerCase() === intentName.trim().toLowerCase()),
        [intents.data],
    );
};

export const useDecisionLlmClustersQuery = (organizationId: number, policySetId: number) =>
    useQuery<[string, string][]>([DECISION_LLM_CLUSTERS_QUERY_KEY, organizationId, policySetId], () =>
        fetchDecisionLlmClusters(organizationId, policySetId).then((data) => data.results),
    );
