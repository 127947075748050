import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineOppositeContent } from '@mui/lab';
import { Typography } from '@mui/material';

interface TimelineListProps {
    id: string;
    items: string[];
}

export const TimelineList = ({ items, id }: TimelineListProps) => {
    return (
        <Timeline id={`${id}-timeline-list`} sx={{ m: 0, p: 0 }}>
            {Object.entries(items).map(([key, item]) => (
                <TimelineItem key={`timeline-item-${key}`} sx={{ minHeight: '1em' }}>
                    <TimelineOppositeContent sx={{ display: 'none' }}></TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot variant="outlined" color="primary" />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="subtitle2">{item}</Typography>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};
