import axios from 'axios';
import { JSONSchema7 } from 'json-schema';
import { IOrganization, IOrganizationCreate, IOrganizationUpdate, TicketHistoryInfo } from '@tymely/atoms';

export const fetchOrganization = (orgId: IOrganization['id']) =>
    axios.get<IOrganization>(`organization/${orgId}`).then((res) => res.data);

export const createOrganization = (org: IOrganizationCreate) =>
    axios.post<IOrganization>('organization', org).then((resp) => resp.data);

export const editOrganization = (org: IOrganizationUpdate) =>
    axios.put<IOrganization>(`organization/${org.id}`, org).then((resp) => resp.data);

export const fetchOrganizations = (liveOnly?: boolean) =>
    axios
        .get<{ results: IOrganization[] }>('organizations/list', {
            params: { limit: 1000, live_only: liveOnly },
        })
        .then((res) => res.data?.results);

export const fetchUserTicketHistory = (ticket_id: number, orgId: number, userId: string, run_async: boolean) =>
    axios
        .get<TicketHistoryInfo>(`organization/${orgId}/customer/${userId}/ticket_history`, {
            params: { ticket_id, async: run_async },
        })
        .then((res) => res.data);

export const hasPolicy = (orgPolicySetId: number, policySetId: number) =>
    axios
        .get<{ is_implemented: boolean }>('wf-policy/is-implemented', {
            params: { org_policy_set_id: orgPolicySetId, policy_set_id: policySetId },
        })
        .then((res) => res.data.is_implemented);

export const fetchOrganizationSchema = () => axios.get<JSONSchema7>('organization/schema').then((res) => res.data);
