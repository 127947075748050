import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { useLlmModelMetadatasQuery } from '@tymely/services';

type LlmModelDropdownProps = Omit<TextFieldProps, 'children'>;

const LlmModelDropdown = ({ ...props }: LlmModelDropdownProps) => {
    const metadatasQuery = useLlmModelMetadatasQuery();

    return metadatasQuery.data ? (
        <TextField
            aria-label="model-input"
            size="small"
            select
            label="Model"
            margin="dense"
            {...props}
            sx={{
                minWidth: 160,
                textAlign: 'left',
                ...props.sx,
            }}
        >
            {metadatasQuery.data?.map((item) => (
                <MenuItem value={item.id} key={item.id} selected={item.id === props.value}>
                    {item.title || item.llm_model_id}
                </MenuItem>
            ))}
        </TextField>
    ) : (
        <TextField disabled size="small" sx={{ minWidth: 160 }} />
    );
};

export default LlmModelDropdown;
