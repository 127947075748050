import { memo, ComponentProps } from 'react';
import { Backdrop, Box, Fade, Modal, styled } from '@mui/material';

import { VideoPlayer } from '../VideoPlayer';

const Wrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.25),
    boxShadow: theme.shadows[14],
    [theme.breakpoints.up('xs')]: {
        minWidth: theme.spacing(60),
    },
}));

interface VideoModalProps {
    open: boolean;
    onClose?: () => void;
    labelledBy?: string;
    describedBy?: string;
    url: ComponentProps<typeof VideoPlayer>['url'];
}

export const VideoModal = memo(({ open, onClose, labelledBy, describedBy, url }: VideoModalProps) => {
    return (
        <Modal
            aria-labelledby={labelledBy}
            aria-describedby={describedBy}
            open={open}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
        >
            <Fade in={open}>
                <Wrapper>
                    <VideoPlayer url={url} />
                </Wrapper>
            </Fade>
        </Modal>
    );
});

VideoModal.displayName = 'VideoModal';
