import React from 'react';
import { Box, Card, styled, Typography } from '@mui/material';

import VariableList from './VariableList';
import { useTemplateEditor } from './TemplateEditorProvider';
import CommentSelect from './CommentSelect';
import TicketSearch from './TicketSearch';

const _Card = styled(Card)`
    height: 100%;
    padding: ${({ theme }) => theme.spacing(2)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;

const _FormContainer = styled(Box)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const _VariablesLabel = styled(Typography)`
    margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const _Inputs = styled(Box)`
    display: flex;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

type TemplateSideBarProps = {
    children?: React.ReactNode;
};

const TemplateSideBar = ({ children }: TemplateSideBarProps) => {
    const { ticketId } = useTemplateEditor();
    return (
        <_Card>
            <_FormContainer>
                <_VariablesLabel variant="h5">Variables</_VariablesLabel>
                <_Inputs>
                    <TicketSearch />
                    {!!ticketId && <CommentSelect />}
                </_Inputs>
                <VariableList />
            </_FormContainer>
            {children}
        </_Card>
    );
};

export default TemplateSideBar;
