const ClientIcon = () => (
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.7752 25.262H3.21727C1.98901 25.262 0.994141 24.2671 0.994141 23.0389V7.48094C0.994141 6.25269 1.98901 5.25781 3.21727 5.25781H18.7765C20.0034 5.25781 20.9983 6.25269 20.9983 7.48094V23.0402C20.9983 24.2671 20.0034 25.262 18.7752 25.262Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.3545 10.8126C14.6561 12.1142 14.6561 14.2254 13.3545 15.5283C12.0529 16.8299 9.94175 16.8299 8.63881 15.5283C7.33587 14.2267 7.33721 12.1156 8.63881 10.8126C9.94042 9.5097 12.0515 9.51104 13.3545 10.8126"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.9986 21.8442C16.8239 21.4054 16.5532 21.0107 16.2064 20.6893V20.6893C15.621 20.1451 14.8541 19.8438 14.054 19.8438C12.7204 19.8438 9.27432 19.8438 7.94071 19.8438C7.14054 19.8438 6.37505 20.1465 5.78826 20.6893V20.6893C5.44152 21.0107 5.1708 21.4054 4.99609 21.8442"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.99609 5.25865V3.48094C4.99609 2.25269 5.99097 1.25781 7.21922 1.25781H22.7785C24.0054 1.25781 25.0003 2.25269 25.0003 3.48094V19.0402C25.0003 20.2671 24.0054 21.262 22.7771 21.262H20.9994"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ClientIcon;
