import React from 'react';
import { Card, styled } from '@mui/material';
import { Loader } from '@tymely/components';
import { useArgumentsMetadataQuery } from '@tymely/services';

import { useTemplateEditor } from './TemplateEditorProvider';
import TemplateViewers from './TemplateViewers';

import('../../JinjaCodeEditor');

const JinjaCodeEditor = React.lazy(() => import('../../JinjaCodeEditor'));

const _Card = styled(Card, { shouldForwardProp: (prop: string) => !['hasError'].includes(prop) })<{
    hasError?: boolean;
}>`
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 2px solid transparent;
    overflow-y: auto;
    position: relative;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    ${({ theme, hasError }) => hasError && `border-color: ${theme.palette.error.light};`};
`;

const _TemplateViewers = styled(TemplateViewers)`
    position: absolute;
    top: ${({ theme }) => theme.spacing(2)};
    right: ${({ theme }) => theme.spacing(2)};
`;

type TemplateCodeEditorProps = { children: React.ReactNode };

const TemplateCodeEditor = ({ children }: TemplateCodeEditorProps) => {
    const { templateErrors, template, diff, variables, onCodeChange } = useTemplateEditor();

    const argumentsMetadataQuery = useArgumentsMetadataQuery();
    const varNames = React.useMemo(
        () => (argumentsMetadataQuery.data?.map((item) => item.name) || []).concat(Object.keys(variables)),
        [argumentsMetadataQuery.data, variables],
    );

    return (
        <_Card hasError={!!templateErrors?.length}>
            <React.Suspense fallback={<Loader />}>
                <JinjaCodeEditor
                    value={template}
                    diff={diff}
                    height="100%"
                    varNames={varNames}
                    errors={templateErrors}
                    onChange={onCodeChange}
                />
                {children}
            </React.Suspense>
            <_TemplateViewers />
        </_Card>
    );
};

export default TemplateCodeEditor;
