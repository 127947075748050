import { Typography, styled, Tooltip } from '@mui/material';
import { IArgumentList } from '@tymely/atoms';

import { ArgumentFieldProps } from './Layout';

const Style = styled(Typography)(({ theme }) => ({
    width: theme.spacing(28),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}));

export const ListArgument = (props: ArgumentFieldProps<IArgumentList>) => (
    <Tooltip title={props.argument.value || ''} enterDelay={1000} followCursor>
        <Style id={`${props.argument.extractor_cls_name.toLowerCase()}-list`} variant="body2">
            {`${props.argument.title}: ${props.argument.value || ''}`}
        </Style>
    </Tooltip>
);
