const SaveIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2778 6.66671H9.71069C9.42308 6.66671 9.15356 6.5229 8.99425 6.28351L7.78353 4.46743C7.62336 4.22718 7.35469 4.08337 7.06708 4.08337H3.22222C2.27069 4.08337 1.5 4.85407 1.5 5.8056V15.2778C1.5 16.2293 2.27069 17 3.22222 17H15.2778C16.2293 17 17 16.2293 17 15.2778V8.38893C17 7.4374 16.2293 6.66671 15.2778 6.66671Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.81999 13.125L7.52832 11.8334"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.9726 10.9746L8.81982 13.1274"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.22168 4.08333V3.22222C3.22168 2.27069 3.99237 1.5 4.9439 1.5H13.555C14.5065 1.5 15.2772 2.27069 15.2772 3.22222V6.66667"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SaveIcon;
