import React from 'react';
import { Fab, FabProps, styled } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const _Fab = styled(Fab)<{ disabled?: boolean }>`
    position: absolute;
    justify-content: center;
    left: ${({ theme }) => theme.spacing(2)};
    opacity: ${({ disabled }) => (disabled ? 0 : 1)};
    transition: opacity 150ms ease-in-out;
`;

const ScrollButton = (props: FabProps) => <_Fab size="small" color="secondary" {...props} />;

const _UpScrollButton = styled(ScrollButton)`
    top: ${({ theme }) => theme.spacing(2)};
`;

export const UpScrollButton = (props: FabProps) => {
    return (
        <_UpScrollButton {...props}>
            <KeyboardArrowUpIcon />
        </_UpScrollButton>
    );
};

const _BottomScrollButton = styled(ScrollButton)`
    bottom: ${({ theme }) => theme.spacing(20)};
    z-index: 0;
`;

export const BottomScrollButton = (props: FabProps) => {
    return (
        <_BottomScrollButton {...props}>
            <KeyboardArrowDownIcon />
        </_BottomScrollButton>
    );
};

export default ScrollButton;
