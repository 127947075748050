import { memo, useCallback, useMemo } from 'react';
import { FormControl, InputLabel } from '@mui/material';
import { Nullable } from '@global/types';
import { IArgumentBoolean } from '@tymely/atoms';
import { Option } from '@tymely/components';

import { ArgumentFieldProps, StyledMultiSelect } from './Layout';

export const BooleanArgument = memo((props: ArgumentFieldProps<IArgumentBoolean>) => {
    const onChange = useCallback(
        (selected: Nullable<Option<boolean>[]>) => {
            const value = selected?.[0]?.value ?? null;
            if (props.argument.value === value) return;
            props.onChange?.([{ ...props.argument, value, special_value: null }]);
        },
        [props.argument, props.onChange],
    );

    const options = useMemo<Option<boolean>[]>(
        () => [
            {
                name: 'Yes',
                value: true,
            },
            {
                name: 'No',
                value: false,
            },
        ],
        [],
    );

    const selectedOption = useMemo(
        () => options.filter((opt) => opt.value === props.argument.value),
        [options, props.argument.value],
    );

    return (
        <FormControl size="small" fullWidth>
            {props.withLabel && <InputLabel>{props.argument.name}</InputLabel>}
            <StyledMultiSelect
                value={selectedOption}
                options={options}
                loading={props.loading}
                searchable={false}
                disabled={props.disabled}
                onOptsSelect={onChange}
                edited={props.argument.is_edited}
                specialValue={props.argument.special_value}
                neitherable={props.argument.neitherable}
                label={props.withLabel ? props.argument.name : ''}
            />
        </FormControl>
    );
});

BooleanArgument.displayName = 'BooleanArgument';
