import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';

interface keymapGroup {
    title: string;
    keys: IKeymapEntry[];
}

interface IKeymapEntry {
    keys: string;
    description: string;
}

const keymapGroups: keymapGroup[] = [
    {
        title: 'Global',
        keys: [
            {
                keys: 'Ctrl + Alt + H',
                description: 'Show this help dialog',
            },
            {
                keys: 'Ctrl + Alt + T',
                description: 'Go to ticket search',
            },
        ],
    },
    {
        title: 'Intents page',
        keys: [
            {
                keys: 'Ctrl + Alt + I',
                description: 'Go to intent search',
            },
        ],
    },
    {
        title: 'Online (and training) mode',
        keys: [
            {
                keys: 'Ctrl + Alt + C',
                description: 'Go to response text box',
            },
            {
                keys: 'Ctrl + Alt + O',
                description: 'Reanalyze',
            },
            {
                keys: 'Ctrl + Alt + G',
                description: 'Show system arguments',
            },
        ],
    },
    {
        title: 'Policy editor',
        keys: [
            {
                keys: 'Ctrl + Alt + Z',
                description: 'Undo',
            },
            {
                keys: 'Ctrl + Alt + Y',
                description: 'Redo',
            },
        ],
    },
];

export const KeymapHelpCard = (props: { open: boolean; onClose: () => void }) => {
    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth="md" fullWidth>
            <DialogTitle variant="h5">Keymap</DialogTitle>
            <DialogContent dividers>
                <Grid container item xs={12} spacing={4}>
                    {keymapGroups.map((group, idx) => (
                        <Grid item container spacing={2} key={`keymap-category-${idx}`}>
                            <Grid item xs={12}>
                                <Typography variant="h6">{group.title}</Typography>
                            </Grid>
                            {group.keys.map(({ keys, description }, idx) => (
                                <Fragment key={`keymap-item-${idx}`}>
                                    <Grid item xs={6} sx={{ fontWeight: 'bold' }}>
                                        {keys}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {description}
                                    </Grid>
                                </Fragment>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
