import capitalize from 'lodash/capitalize';
import { ChangeEvent, useCallback, useLayoutEffect, useState, ElementType } from 'react';
import { InputBaseComponentProps } from '@mui/material';
import {
    IArgumentInteger,
    IStringValueArgument,
    IArgumentVideoUrls,
    IArgumentImageUrls,
    IArgumentUrls,
    IArgumentStrings,
    IArgumentEmailStrings,
} from '@tymely/atoms';
import { typedMemo } from '@global/types';

import { ArgumentFieldProps, StyledOutlinedInput } from './Layout';

export const InputFieldArgument = typedMemo(
    <
        T extends
            | IStringValueArgument
            | IArgumentInteger
            | IArgumentVideoUrls
            | IArgumentImageUrls
            | IArgumentUrls
            | IArgumentStrings
            | IArgumentEmailStrings,
    >(
        props: ArgumentFieldProps<T> & {
            inputComponent?: ElementType<InputBaseComponentProps>;
        },
    ) => {
        const value = typeof props.argument.value === 'string' ? props.argument.value.trim() : props.argument.value;
        const [state, setState] = useState(
            ['int', 'float'].includes(props.argument.dtype) ? value : value && String(value),
        );
        useLayoutEffect(() => {
            setState(value);
        }, [value]);

        const handleChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                let state: any = e.target.value;
                if (
                    props.argument.dtype === 'list[Image]' ||
                    props.argument.dtype === 'list[VideoUrl]' ||
                    props.argument.dtype === 'list[Url]' ||
                    props.argument.dtype === 'list[str]' ||
                    props.argument.dtype === 'list[EmailStr]'
                ) {
                    state = String(state)
                        .split(',')
                        .map((v) => v.trim());
                } else if (['int', 'float'].includes(props.argument.dtype)) {
                    const value = e.target.value;
                    const numericValue = Number(value);
                    if (!isNaN(numericValue)) {
                        state = value === '' ? null : numericValue;
                    }
                }
                setState(state);
                props.onChange?.([{ ...props.argument, value: state }] as T[], true);
            },
            [props.argument],
        );

        return (
            <StyledOutlinedInput
                id={`${props.argument.extractor_cls_name.toLowerCase()}-input`}
                value={String(state ?? '')}
                specialValue={props.argument.special_value}
                edited={props.argument.is_edited}
                disabled={props.loading}
                placeholder={capitalize(props.argument.special_value ?? '')}
                inputProps={{
                    sx: {
                        fontStyle: state === null && props.argument.special_value ? 'italic' : 'normal',
                    },
                }}
                InputProps={{ inputComponent: props.inputComponent }}
                label={props.withLabel && props.argument.name}
                onChange={props.disabled ? undefined : handleChange}
                onFocus={() => {
                    if (props.setHighlightText && state) {
                        props.setHighlightText(state.toString());
                    }
                }}
                onBlur={() => {
                    props.onChange?.([{ ...props.argument, value: state }] as T[], false);
                    props.setHighlightText?.('');
                }}
            />
        );
    },
);
