const ApprovedIcon = () => {
    return (
        <svg fill="none" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m16 0c-8.8366 0-16 7.1634-16 16 0 8.8366 7.1634 16 16 16 8.8366 0 16-7.1634 16-16 0-8.8366-7.1634-16-16-16zm7.655 12.756c0.4173-0.3617 0.4624-0.9933 0.1006-1.4106-0.3617-0.4173-0.9933-0.4624-1.4106-0.1006l-8.5869 7.4433-4.118-3.4306c-0.42433-0.3535-1.0549-0.2961-1.4084 0.1282-0.35351 0.4244-0.29609 1.0549 0.12824 1.4084l4.7709 3.9746c0.3765 0.3137 0.9248 0.3083 1.2951-0.0127l9.2291-8z"
                clipRule="evenodd"
                fill="#65E2AE"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default ApprovedIcon;
