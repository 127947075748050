import { atom } from 'recoil';

import { IComment } from './comment.atoms';
import { IArgument } from './argument.atoms';
import { IOrganization } from './organization.atoms';

export interface IQueueInfo {
    org_id: number;
    queue_name: string;
    ticket_count: number;
}

export interface IAppVersion {
    dinesh_version: string;
}

export interface INote {
    text: string;
    username?: string;
    email?: string;
    datetime?: string;
}

export interface IReportNote {
    category: TicketReportCategory;
    description: string;
    username: string | undefined;
    intent_ids: number[] | undefined;
    escalate: boolean;
}

export interface ITicket {
    arguments?: IArgument[];
    assignee_name: string;
    comments: IComment[];
    id: number;
    inquiry_date: string;
    organization_id: number;
    organization: IOrganization;
    original_id_from_client: string;
    origin_customer_id: string;
    owner: unknown;
    subject: string;
    is_tagged: boolean;
    notes?: {
        note?: string;
        notes?: INote[];
        report?: IReportNote;
    };
    origin_status: 'NEW' | 'OPEN' | 'CLOSED' | 'SOLVED';
    live?: boolean;
    consumption_channel?: string;
}

export const ticketAtom = atom<ITicket>({
    key: 'ticket',
    default: undefined,
});

export interface TicketHistoryInfo {
    tickets: ITicket[];
    unarchived: {
        tickets: {
            origin_id: string;
            inquiry_date: string;
        }[];
        origin_url: string;
    };
}

export type TicketReportCategory = 'INAPPROPRIATE_RESPONSE' | 'TECHNICAL_ISSUE' | 'NEEDS_TO_BE_MERGED' | 'OTHER';
