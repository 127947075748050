import React from 'react';

import { useTemplateEditor } from '../components/TemplateEditorProvider';

const useGetTemplateChannel = () => {
    const { templateId } = useTemplateEditor();
    return React.useMemo(() => {
        let channel = `template/${templateId}`;
        if (Number.isNaN(templateId) || !templateId) {
            channel = '';
        }
        return channel;
    }, [templateId]);
};

export default useGetTemplateChannel;
