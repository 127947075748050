import { memo, useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, ChipProps, styled, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ICrumbAdditionalData, IDineshTicketOperations, IPolicySet, IPolicySetGroup } from '@tymely/atoms';
import { useCreateTicketCrumb } from '@tymely/services';
import { INTENTS_TOP_INFERRED_CATEGORY } from '@tymely/config';
import { locale } from '@tymely/utils';

const Root = styled(Box)({
    flex: 1,
    overflowY: 'auto',
});

const IntentCategory = styled(Typography, {
    shouldForwardProp: (prop) => !['disabled'].includes(String(prop)),
})<{ disabled?: boolean }>(({ disabled, theme }) => ({
    color: disabled ? theme.palette.grey[600] : theme.palette.info.dark,
    textTransform: 'capitalize',
}));

const StyledChip = styled(Chip, {
    shouldForwardProp: (prop) => !['isSelected', 'intentId'].includes(String(prop)),
})<{ isSelected: boolean }>(({ isSelected, theme }) => ({
    height: 'initial',
    minHeight: theme.spacing(4),
    '& .MuiChip-label': {
        padding: theme.spacing(0.75, 1.25),
        textOverflow: 'initial',
        whiteSpace: 'break-spaces',
    },
    ...(isSelected
        ? {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.grey[600],
          }
        : {
              '&.Mui-disabled': {
                  color: theme.palette.grey[400],
                  backgroundColor: theme.palette.grey[200],
                  opacity: 1,
              },
          }),
}));

const Intent = memo(
    (
        props: Omit<ChipProps, 'onClick'> & {
            intent: IPolicySet;
            isSelected: boolean;
            onClick?: (intent: IPolicySet) => void;
        },
    ) => {
        const onClick = useCallback(() => props.onClick?.(props.intent), [props.intent, props.onClick]);
        return (
            <StyledChip
                {...props}
                label={props.intent.name}
                title={title(props.intent.weight)}
                onClick={onClick}
            ></StyledChip>
        );
    },
);

Intent.displayName = 'Intent';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    boxShadow: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-of-type': {
        borderTop: 0,
    },
    '&.Mui-expanded': {
        marginTop: 0,
    },
    '&:before': {
        opacity: 0,
    },
}));

const StyledSummary = styled(AccordionSummary)(({ theme }) => ({
    '&.Mui-expanded': {
        minHeight: theme.spacing(6),
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: theme.spacing(1.5, 0),
    },
}));

const StyledDetails = styled(AccordionDetails)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
}));

const title = (weight?: number) =>
    weight
        ? `Weight: ${weight.toLocaleString(locale, {
              maximumFractionDigits: 4,
              minimumFractionDigits: 1,
          })}`
        : undefined;

type IntentsListProps = {
    disabled?: boolean;
    intentsGrouped?: IPolicySetGroup[];
    expanded: boolean;
    selectedIds: IPolicySet['id'][];
    onIntentClick?: (intent: IPolicySet) => void;
};

export const IntentsList = memo(
    ({ disabled, selectedIds, intentsGrouped, expanded, onIntentClick }: IntentsListProps) => {
        const [expandMap, setExpandedMap] = useState<Record<string, boolean>>({});
        const createTicketCrumb = useCreateTicketCrumb();

        const isSelected = useCallback((intentId: number) => selectedIds.includes(intentId), [selectedIds]);

        useEffect(() => {
            const map = (intentsGrouped || []).reduce<Record<string, boolean>>((accum, group) => {
                accum[group.category] = Boolean(expanded);
                return accum;
            }, {});
            map[INTENTS_TOP_INFERRED_CATEGORY.toLowerCase()] = true;
            setExpandedMap(map);
        }, [intentsGrouped, expanded]);

        const onChange = useCallback(
            (category: string) => {
                if (!expandMap[category]) {
                    createTicketCrumb(IDineshTicketOperations.USER_EXPANDED_INTENTS_CATEGORY, {
                        intents_group: category,
                    } as ICrumbAdditionalData);
                }
                setExpandedMap({
                    ...expandMap,
                    [category]: !expandMap[category],
                });
            },
            [expandMap],
        );

        return (
            <Root aria-label="intents" sx={{ overflowY: 'auto' }}>
                {intentsGrouped?.map((intentsGroup) => (
                    <StyledAccordion
                        key={intentsGroup.category + expandMap[intentsGroup.category]}
                        expanded={expandMap[intentsGroup.category]}
                        onChange={() => onChange(intentsGroup.category)}
                    >
                        <StyledSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${intentsGroup.category}-content`}
                            id={`${intentsGroup.category}-header`}
                        >
                            <IntentCategory
                                /* eslint-disable no-useless-escape */
                                id={`${intentsGroup.category
                                    .replace(/[^\w\s']|_/g, '')
                                    .replace(/\s+/g, '')
                                    .toLowerCase()}`}
                                disabled={disabled}
                                variant="subtitle2"
                            >
                                {intentsGroup.category}
                            </IntentCategory>
                        </StyledSummary>
                        <StyledDetails>
                            {intentsGroup.intents.map((intent) => (
                                <Intent
                                    key={intent.id}
                                    color={isSelected(intent.id) ? 'primary' : 'default'}
                                    disabled={disabled || isSelected(intent.id)}
                                    isSelected={isSelected(intent.id)}
                                    intent={intent}
                                    onClick={onIntentClick}
                                />
                            ))}
                        </StyledDetails>
                    </StyledAccordion>
                ))}
            </Root>
        );
    },
);

IntentsList.displayName = 'IntentsList';
