import React, { FunctionComponent, useCallback, useMemo, useState, useEffect, memo, ReactNode } from 'react';
import {
    Grid,
    styled,
    Box,
    Typography,
    BoxProps,
    Button,
    GridProps,
    Chip,
    LinearProgressProps,
    LinearProgress,
    useTheme,
    Divider,
    IconButton,
    Select,
    SelectChangeEvent,
    MenuItem,
    FormControl,
    InputLabel,
    Switch,
    FormControlLabel,
} from '@mui/material';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import { BaseModal, CopyToClipboardButton } from '@tymely/components';
import NextIcon from '@tymely/components/Icons/Next';
import { IDineshTicketOperations, ITicket } from '@tymely/atoms';
import {
    useOrganizationsQuery,
    useTicket,
    useAppMode,
    AppMode,
    useSelectedComment,
    useLogTicketProcess,
    useCreateTicketCrumb,
    useHistoricAnalysis,
    useIsSecondTouchTicket,
    useNotes,
    useTicketReset,
    useTicketActions,
    useTicketOrganization,
    useAppVersionQuery,
    useAgentResponse,
    useArgumentVersionsQuery,
    useTicketNav,
} from '@tymely/services';
import { afterTicketHandling } from '@tymely/api';
import { version } from '@tymely/config';
import { LoadingButton } from '@mui/lab';
import { findLast } from 'lodash';
import { useConcurrentAccess } from '@tymely/components/ConcurrentAccess';

import ArgumentSections from '../Arguments/Sections';
import { IntentsSection, DemoIntentsSection } from './IntentsSection';
import Conversation from './Conversation';
import { ChatAlertButton } from './ChatAlertButton';
import { ReviewSpeedometer } from './ReviewSpeedometer';

const Column = styled((props) => <Grid item {...props} />)(({ theme }) => ({
    padding: theme.spacing(1),
})) as FunctionComponent<GridProps>;

const LayoutRoot = styled(Box)(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    maxHeight: 100%;
    height: 100%;
    overflow: hidden;
    background: ${theme.palette.background.main};
`,
);

const LayoutGrid = styled(Grid)`
    height: 100%;
    overflow: hidden;
    padding: 0;
`;

const OrgChip = styled(Chip)`
    font-weight: 500;
    font-size: 14px;
    margin-right: 16px;
`;

const MILLIS_IN_MINUTE = 60000;

const Progress = styled(({ prColor, ...props }: LinearProgressProps & { prColor: string }) => (
    <LinearProgress {...props} />
))(
    ({ theme, prColor }) => `
    background-color: ${theme.palette.common.white};
    .MuiLinearProgress-bar {
        background-color: ${prColor};
        border-radius: ${theme.shape.borderRadius}px;
    };
    width: ${theme.spacing(10)};
    height: ${theme.spacing(1)};
    border-radius: ${theme.shape.borderRadius}px;
`,
);

export const TimeProgress = memo((props: { value: number; max: number; colors?: string[] }) => {
    const theme = useTheme();
    const chartProps = useMemo(() => {
        const maxValue = props.max * MILLIS_IN_MINUTE;
        const colors = props.colors || [
            theme.palette.success.main,
            theme.palette.warning.main,
            theme.palette.error.main,
        ];
        return {
            colors,
            maxValue,
            colorStep: maxValue / (colors.length - 1),
        };
    }, [props]);

    const [elapsedTimeMs, setPassedMs] = useState(Date.now() - props.value);
    useEffect(() => {
        const interval = setInterval(() => {
            setPassedMs(Date.now() - props.value);
        }, 1000);
        return () => clearInterval(interval);
    }, [chartProps]);
    const progressColor =
        chartProps.colors[Math.min(Math.floor(elapsedTimeMs / chartProps.colorStep), chartProps.colors.length - 1)];
    const progress = Math.min(100, (elapsedTimeMs / chartProps.maxValue) * 100);
    const minutesPassed = Math.floor(elapsedTimeMs / MILLIS_IN_MINUTE);

    return (
        <Box display="flex" alignItems="center">
            <Progress value={progress} variant="determinate" prColor={progressColor} />
            <Typography color={progressColor} fontWeight={600} variant="caption" ml={1}>
                {minutesPassed}&nbsp;
            </Typography>
            <Typography color="secondary" variant="caption">
                min ago
            </Typography>
        </Box>
    );
});

TimeProgress.displayName = 'TimeProgress';

const TicketSourceChip = (props: { orgName: string; channelName?: string }) => {
    let label = props.orgName;
    if (props.channelName && props.channelName !== 'main') {
        label = `${label} ${props.channelName}`;
    }

    return <OrgChip label={label} variant="filled" color="primary" />;
};

export const BlockedByAnotherViewerModal = ({ agent }: { agent: string }) => {
    const { handleNext } = useTicketNav();
    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <BaseModal
            open={true}
            title="Blocked by another agent"
            closable={false}
            maxWidth="sm"
            loading={isLoading}
            onOk={() => {
                setIsLoading(true);
                handleNext();
            }}
            okLabel="Move to the next ticket"
        >
            <Typography>
                The ticket is being handled by another agent <b>{agent}</b>.
            </Typography>
        </BaseModal>
    );
};

export const TicketHeader = styled(
    (
        props: BoxProps & {
            ticket: ITicket;
            onNext?: (() => void) | false;
            onReset?: (() => void) | false;
            onArgsVersion?: (argsVersion: string) => void;
            toolbarItems?: ReactNode[];
            blockParallelHandling?: boolean;
        },
    ) => {
        const theme = useTheme();
        const { appMode, isOnline, switchMode, shiftEnd, isChat } = useAppMode();
        const isSecondTouch = useIsSecondTouchTicket(props.ticket);
        const { wasEscalated } = useNotes();
        const { data: orgs } = useOrganizationsQuery();
        const currentOrg = useMemo(
            () => orgs?.find((org) => org.id === props.ticket.organization_id),
            [orgs, props.ticket],
        );
        const { isSubmitting } = useAgentResponse();
        const onNext = useCallback(() => {
            if (props.onNext) {
                props.onNext?.();
            }
        }, [shiftEnd, switchMode, props.onNext]);
        const appVersion = useAppVersionQuery();
        const latestDineshVersion = appVersion.data?.dinesh_version;

        const [isResetting, setIsResetting] = useState(false);
        const onReset = useCallback(async () => {
            if (!props.onReset) return;
            try {
                setIsResetting(true);
                return await props.onReset();
            } finally {
                setIsResetting(false);
            }
        }, [props.onReset]);

        const { config: orgConfig } = useTicketOrganization();

        const lastCustomerComment = useMemo(
            () => findLast(props.ticket.comments, (comment) => comment.is_customer),
            [props.ticket.comments],
        );
        const { onNext: onNextProp, onArgsVersion, toolbarItems, blockParallelHandling, ...rest } = props;

        const [argsVersion, setArgsVersion] = useState('');
        const argumentVersionsQuery = useArgumentVersionsQuery();

        const taggingAgent = useMemo(
            () => props.ticket?.comments.find((c) => c.selected_intent_id)?.agent_username,
            [props.ticket],
        );

        const { nextEnabled } = useTicketActions();

        const { lock } = useConcurrentAccess(appMode === AppMode.Online && `ticket/${props.ticket.id}`, {
            claimLock: true,
        });

        return (
            <>
                {lock?.user.username && <BlockedByAnotherViewerModal agent={lock.user.username} />}
                <Box display="flex" flexDirection="column" {...rest} overflow="auto" gap={1}>
                    {props.children}
                    <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
                        <Typography
                            textTransform="capitalize"
                            fontSize="fontLarge"
                            fontWeight={500}
                            variant="h5"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            sx={{ [theme.breakpoints.down('lg')]: { maxWidth: '30%' } }}
                        >
                            {props.ticket.subject || `Ticket #${props.ticket.id}`}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            {currentOrg && (
                                <TicketSourceChip
                                    orgName={currentOrg.name}
                                    channelName={props.ticket.consumption_channel}
                                />
                            )}
                            <Box
                                display="flex"
                                alignItems="center"
                                sx={{ userSelect: 'none', [theme.breakpoints.down('lg')]: { flexWrap: 'wrap' } }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    color="secondary"
                                    fontSize="fontSmall"
                                    fontWeight={500}
                                    whiteSpace="nowrap"
                                >
                                    Int. #{props.ticket.id}{' '}
                                    <CopyToClipboardButton text={String(props.ticket.id)} size="small" />
                                </Typography>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{
                                        mr: 1,
                                        ml: 1,
                                        borderWidth: 1,
                                        [theme.breakpoints.down('lg')]: { display: 'none' },
                                    }}
                                />
                                <Typography
                                    variant="subtitle1"
                                    color="secondary"
                                    fontSize="fontSmall"
                                    fontWeight={500}
                                    whiteSpace="nowrap"
                                >
                                    Ext. {props.ticket.original_id_from_client}{' '}
                                    <CopyToClipboardButton text={props.ticket.original_id_from_client} size="small" />
                                </Typography>

                                {taggingAgent && (
                                    <>
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{
                                                mr: 1,
                                                ml: 1,
                                                borderWidth: 1,
                                                [theme.breakpoints.down('lg')]: { display: 'none' },
                                            }}
                                        />
                                        <Typography
                                            id="tagging-agent-caption"
                                            variant="subtitle1"
                                            color="secondary"
                                            fontSize="fontSmall"
                                            fontWeight={500}
                                            whiteSpace="nowrap"
                                        >
                                            {`Labeled: ${taggingAgent}`}
                                        </Typography>
                                    </>
                                )}

                                {appMode === AppMode.Analyst && props.onArgsVersion && (
                                    <FormControl sx={{ ml: 2, fontSize: 'inherit', minWidth: 120 }} size="small">
                                        <InputLabel id="version-select-label">Version</InputLabel>
                                        <Select
                                            labelId="version-select-label"
                                            label="Version"
                                            disabled={argumentVersionsQuery.isFetching}
                                            value={argsVersion}
                                            sx={{ fontSize: 'inherit' }}
                                            size="small"
                                            onChange={(event: SelectChangeEvent) => {
                                                const argsVersion = event.target.value;
                                                setArgsVersion(argsVersion);
                                                props.onArgsVersion?.(argsVersion);
                                            }}
                                        >
                                            <MenuItem value={''}>No version</MenuItem>
                                            {argumentVersionsQuery.data?.map((v) => (
                                                <MenuItem key={v} value={v}>
                                                    {v}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}

                                {toolbarItems?.map((item, at) => (
                                    <React.Fragment key={at}>
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{
                                                mr: 1,
                                                ml: 1,
                                                borderWidth: 1,
                                                [theme.breakpoints.down('lg')]: { display: 'none' },
                                            }}
                                        />
                                        {item}
                                    </React.Fragment>
                                ))}

                                {version && latestDineshVersion && version !== latestDineshVersion && (
                                    <>
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{
                                                mr: 1,
                                                ml: 1,
                                                borderWidth: 1,
                                                [theme.breakpoints.down('lg')]: { display: 'none' },
                                            }}
                                        />

                                        <Box>
                                            <Typography
                                                id="org-name-caption"
                                                variant="caption"
                                                sx={{
                                                    color: 'green',
                                                    fontWeight: 500,
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                New version available
                                            </Typography>

                                            <IconButton
                                                id="intent-add-icon-btn"
                                                onClick={() => window.location.reload()}
                                                size="small"
                                                sx={{
                                                    color: 'green',
                                                }}
                                                title="Reload the page"
                                            >
                                                <CachedRoundedIcon />
                                            </IconButton>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Box>

                        <Box display="flex" flexWrap="wrap" gap={2} alignItems="center">
                            {!isChat && isOnline && <ChatAlertButton />}
                            {nextEnabled && (props.onNext || shiftEnd) && (
                                <Button variant="contained" color="secondary" onClick={onNext}>
                                    <Box mr={1}>Next</Box>
                                    <NextIcon />
                                </Button>
                            )}
                            {props.onReset && (
                                <LoadingButton
                                    onClick={onReset}
                                    loading={isResetting}
                                    variant="contained"
                                    color="warning"
                                >
                                    Reset
                                </LoadingButton>
                            )}
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center" gap={2}>
                            {orgConfig.chat?.enabled && (
                                <Chip
                                    label="Conversation"
                                    color="error"
                                    size="small"
                                    sx={{
                                        fontSize: 'fontSmall',
                                        textTransform: 'uppercase',
                                    }}
                                    variant="filled"
                                />
                            )}
                            {props.ticket.origin_status === 'CLOSED' && (
                                <Chip
                                    label="Closed"
                                    color="error"
                                    size="small"
                                    sx={{
                                        fontSize: 'fontSmall',
                                        textTransform: 'uppercase',
                                    }}
                                    variant="filled"
                                />
                            )}
                            {wasEscalated && (
                                <Chip
                                    label="Escalated"
                                    color="error"
                                    size="small"
                                    sx={{
                                        fontSize: 'fontSmall',
                                        textTransform: 'uppercase',
                                    }}
                                    variant="filled"
                                />
                            )}
                            {isSecondTouch && (
                                <Chip
                                    label="Second Touch"
                                    color="warning"
                                    size="small"
                                    sx={{
                                        fontSize: 'fontSmall',
                                        textTransform: 'uppercase',
                                    }}
                                    variant="filled"
                                />
                            )}
                            {!(props.ticket?.live ?? false) && (
                                <Chip
                                    label="TRAINING"
                                    color="primary"
                                    size="small"
                                    sx={{
                                        mr: 1,
                                        textTransform: 'uppercase',
                                    }}
                                    variant="filled"
                                />
                            )}
                            {isOnline && (props.ticket?.live ?? false) && (
                                <TimeProgress
                                    value={new Date(
                                        lastCustomerComment?.inquiry_date
                                            ? lastCustomerComment?.inquiry_date
                                            : props.ticket.inquiry_date,
                                    ).valueOf()}
                                    max={orgConfig.chat?.enabled ? 1 : 10}
                                />
                            )}
                        </Box>
                        {appMode === AppMode.Online && <ReviewSpeedometer />}
                    </Box>
                </Box>
                {isSubmitting && <LinearProgress />}
            </>
        );
    },
)(
    ({ theme }) => `
                            position: relative;
                            width: 100%;
                            padding: ${theme.spacing(3)};
                            border-bottom: 1px solid ${theme.palette.grey[400]};
                            `,
);

const CenterColumn = styled(Column)(
    () => `
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            overflow: hidden;
                            padding: 0;
                            `,
);

const RightColumn = styled(Column)(
    () => `
                            padding: 0;
                            height: 100%;
                            `,
);

export const Body = () => {
    const { config } = useTicketOrganization();
    const chatConfig = config.chat;
    const collapseLastArgUpdate = config.ingestion?.collapse_last_arg_updated;

    const ticket = useTicket();

    const selectedComment = useSelectedComment();
    const { appMode, shiftEnd } = useAppMode();
    const { logProcessed } = useLogTicketProcess();
    const createTicketCrumb = useCreateTicketCrumb();
    const { submitEnabled, saveEnabled, reviewEnabled } = useTicketActions();
    const { handleNext } = useTicketNav();
    const { switchMode } = useAppMode();

    const theme = useTheme();
    const [argsVersion, setArgsVersion] = useState('');
    const onNext = useCallback(() => {
        logProcessed('skipped');
        const waitFor = selectedComment
            ? new Promise((resolve) => {
                  try {
                      afterTicketHandling(selectedComment.id);
                  } finally {
                      setTimeout(resolve, 100);
                  }
              })
            : undefined;

        if (shiftEnd) {
            switchMode(AppMode.Training);
        } else {
            handleNext(waitFor);
        }
    }, [logProcessed, handleNext]);

    const resetTicket = useTicketReset();
    const onReset = useCallback(
        () => Promise.all([createTicketCrumb(IDineshTicketOperations.USER_RESET_TICKET), resetTicket()]),
        [createTicketCrumb, resetTicket],
    );

    const showArgs = selectedComment?.selected_intent_id && appMode !== AppMode.Labeling;

    const historicAnalysis = useHistoricAnalysis();
    const highlights = useMemo(() => {
        if (historicAnalysis) {
            return historicAnalysis.additional_data.highlight_spans;
        } else {
            return selectedComment?.additional_data?.highlight_spans || [];
        }
    }, [selectedComment, historicAnalysis]);

    const argsLastUpdate = useMemo(
        () =>
            collapseLastArgUpdate ? (selectedComment ? new Date(selectedComment.inquiry_date) : new Date()) : undefined,
        [collapseLastArgUpdate, selectedComment?.inquiry_date],
    );

    const [highlightText, setHighlightText] = useState<string>();
    const [eventsMode, setEventsMode] = useState(false);

    return (
        <LayoutRoot>
            <TicketHeader
                ticket={ticket}
                onNext={onNext}
                onReset={onReset}
                flexShrink={0}
                onArgsVersion={setArgsVersion}
                blockParallelHandling={true}
                toolbarItems={[
                    <FormControlLabel
                        key="event-mode"
                        labelPlacement="start"
                        control={<Switch size="small" checked={eventsMode} onChange={(_, on) => setEventsMode(on)} />}
                        label={<Typography variant="caption">Event mode</Typography>}
                        sx={{ m: 0 }}
                    />,
                ]}
            />
            <LayoutGrid container flexShrink={1}>
                <CenterColumn xs={8}>
                    <Conversation
                        highlights={highlights}
                        highlightText={highlightText}
                        canReview={reviewEnabled && !chatConfig?.enabled}
                        canSubmit={submitEnabled}
                        canSave={saveEnabled}
                        eventsMode={eventsMode}
                    />
                </CenterColumn>
                <RightColumn xs={4} position="relative">
                    <Box sx={{ height: '100%', background: theme.palette.background.paper }}>
                        {showArgs ? (
                            <ArgumentSections
                                argsLastUpdate={argsLastUpdate}
                                argsVersion={argsVersion}
                                setHighlightText={setHighlightText}
                            />
                        ) : (
                            <IntentsSection />
                        )}
                    </Box>
                </RightColumn>
            </LayoutGrid>
        </LayoutRoot>
    );
};

export const DemoBody = (props: { onNext: () => void }) => {
    const ticket = useTicket();
    const selectedComment = useSelectedComment();
    return (
        <LayoutGrid container>
            <CenterColumn xs={8.5}>
                <TicketHeader ticket={ticket} />
                <Conversation highlights={selectedComment?.additional_data?.['highlight_spans'] || []} />
            </CenterColumn>
            <RightColumn xs={3.5}>
                <DemoIntentsSection onNext={props.onNext} />
            </RightColumn>
        </LayoutGrid>
    );
};
