import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isThisYear from 'date-fns/isThisYear';
import enUS from 'date-fns/locale/en-US';
import sanitizeHtml_, { defaults, Tag } from 'sanitize-html';
import { IComment, ITicketTrailEvent } from '@tymely/atoms';

export const formatBubbleDate = (dateStr: string, withDate?: boolean, precise?: boolean) => {
    try {
        const date = new Date(dateStr);
        const offset = `UTC+${-date.getTimezoneOffset() / 60}`;
        return `${format(date, `${withDate ? 'MMM d,' : ''} HH:mm:ss${precise ? '.SSS' : ''}`, {
            locale: enUS,
        })} ${offset}`;
    } catch (_error) {
        return null;
    }
};

export const formatGroupDate = (date: Date) => {
    try {
        if (isToday(date)) return 'Today';
        return `${format(date, `MMM d${isThisYear(date) ? '' : ', yyyy'}`, { locale: enUS })}`;
    } catch (_error) {
        return null;
    }
};

export const sanitizeHtml = (html: string) => {
    let aTag: Tag | null = null;
    return sanitizeHtml_(html, {
        ...defaults,
        allowedAttributes: {
            ...defaults.allowedAttributes,
            '*': ['style', 'id', 'class'],
        },
        textFilter(text) {
            if (!aTag || !aTag.attribs['href']) {
                return text;
            }
            const isHttpLink = /^http/.test(aTag.attribs['href']);
            const href = aTag.attribs['href'].replace('mailto:', '');
            const linkHtml = `<span style="color:rgba(0, 0, 238)">${href}</span>`;
            const title = text.trim() && text !== href ? text.trim() : '';
            const link = isHttpLink ? linkHtml : href;
            aTag = null;
            return title ? `${title} (${link})` : link;
        },
        transformTags: {
            a: (tagName, attribs) => {
                if (attribs['href']?.endsWith('.mp4')) {
                    return {
                        tagName,
                        attribs,
                    };
                }

                aTag = { tagName, attribs };
                return {
                    tagName: 'span',
                    attribs: {},
                };
            },
        },
    });
};

export const isTicketTrailEvent = (item: unknown): item is ITicketTrailEvent => {
    return (item as ITicketTrailEvent).operation !== undefined;
};

export const isComment = (item: unknown): item is IComment => {
    return (item as IComment).body_html !== undefined;
};

export const getItemDate = (item: IComment | ITicketTrailEvent) => {
    return new Date('inquiry_date' in item ? item.inquiry_date : `${item.created_date}Z`);
};

export const linkRegexp = new RegExp('(https|s3)?://([^"\']*\\.(?:png|jpg|jpeg|svg|pdf|txt|mov|mp4))', 'i');
